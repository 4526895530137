/* Import NPM or YARN MODULE */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from "./Router";

import OnBoarding from "./modal/OnBoarding"
import Upgrade from "./modal/Upgrade"
import InstallGuide from "./modal/InstallGuide"
import WhoIsContent from "./modal/WhoIsContent"

import { 
    WixDesignSystemProvider,
    Notification
} from '@wix/design-system';

import "@wix/design-system/styles.global.css";

/* CREATE RENDER APP */
class App extends React.Component {

    state = {
        onboarding: this.props.onboarding ? this.props.onboarding : false,
        installguide: false,
        whoisPage: false,
        updatedConfig: false,
        showToast: false,
        messageToast: null,
        errorToast: false,
        upgrade: this.props.upgrade ? this.props.upgrade : false,
        upgrade_url: this.props.upgrade_url ? this.props.upgrade_url : false,
        config: this.props.configData ? this.props.configData : '',
    }

    toggleActive = (field) => {
        this.setState({ [field]: !this.state[field] });
    }

    toggleToast = (message, error) => {
        this.setState(({ showToast }) => ({ showToast: !showToast }));
        this.setState({ messageToast: message });
        this.setState({ errorToast: error });
    };

    contentOverlay = (field, value) => {
        this.setState({ [field]: value });
    }

    /*  Update Config
        La méthode est utilisée dans chaque Enfant de ConfigForm
    */
    handleUpdateConfig = (value) => {
        this.setState({ showToast: false });
        this.setState({ updatedConfig: value });
    };

    handleChildChange = (value) => {
        this.setState({
            config: value,
        })
    };

    render() {
        const {
            showToast,
            messageToast,
            errorToast,
            config,
        } = this.state

        const toogleNotification = (showToast &&
            <Notification
                autoHideTimeout={5000}
                show
                theme={errorToast ? 'error' : 'success'}
                type="sticky"
                >
                <Notification.TextLabel>
                    {messageToast}
                </Notification.TextLabel>
                <Notification.CloseButton />
            </Notification>
        )

        return (
            <BrowserRouter>
                <WixDesignSystemProvider>
                    <Router {...this.props}
                        configData={config}
                        contentOverlay={this.contentOverlay}
                        handleUpdateConfig={this.handleUpdateConfig}
                        updatedConfig={this.state.updatedConfig}
                        toggleToast={this.toggleToast}
                        childChange={this.handleChildChange}
                        showToast={this.state.showToast}
                        config_no_coliship={this.props.config === null ? true : this.props.config.generate_label_shopify}
                        ref={instance => { this.childRouter = instance; }}
                    />
                    <OnBoarding 
                        {...this.props}
                        contentOverlay={this.contentOverlay}
                        onboarding={this.state.onboarding}
                    />
                    <InstallGuide {...this.props}
                        contentOverlay={this.contentOverlay}
                        installguide={this.state.installguide}
                    />
                    <WhoIsContent {...this.props}
                        contentOverlay={this.contentOverlay}
                        whoisPage={this.state.whoisPage}
                    />
                    <Upgrade {...this.props}
                        contentOverlay={this.contentOverlay}
                        upgrade={this.state.upgrade}
                        upgrade_url={this.state.upgrade_url}
                    />
                    {toogleNotification}
                </WixDesignSystemProvider>
            </BrowserRouter>
        );
    };
}

export default App