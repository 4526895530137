import React from 'react';

import { 
    TextButton,
    Layout,
    Box,
    Divider,
    Image,
    Cell
} from '@wix/design-system';

import { FavoriteFilledSmall } from '@wix/wix-ui-icons-common';

import Logo from "../../assets/images/logo.png";

class Footer extends React.Component {
    render() {
        return(
            <Layout justifyItems="center" gap="0px">
                <br/>
                <Cell>
                    <Box width="100%" direction="horizontal" gap="SP2" align="center" verticalAlign="middle">
                        <TextButton onClick={(e) => this.props.contentOverlay('onboarding', true)}>{I18n.t('menu.welcome')}</TextButton>
                        <Divider direction="vertical" skin="standard" />
                        <TextButton onClick={(e) => this.props.contentOverlay('installguide', true)}>{I18n.t('menu.guide')}</TextButton>
                        <Divider direction="vertical" skin="standard" />
                        <TextButton onClick={(e) => this.props.contentOverlay('whoisPage', true)}>{I18n.t('menu.quisommes')}</TextButton>
                        <Divider direction="vertical" skin="standard" />
                        <TextButton as="a" target="_blank" href="https://www.wix.com/app-market/add-review/7a26ed27-5449-4960-ae3f-7687d320e027"><FavoriteFilledSmall />&nbsp;{I18n.t('menu.review')}&nbsp;<FavoriteFilledSmall /></TextButton>
                        <Image src={Logo} width="60" transparent />
                    </Box>
                </Cell>
                <br/>
            </Layout>
        )
    }
}

export default Footer
       