import React from "react";
import PropTypes from "prop-types";
import {
  Cell,
  Card,
  Layout,
  MarketingLayout,
  SectionHelper,
  Image,
  Box,
  Text,
  Divider
} from '@wix/design-system';

import LogoHappy from "../../../assets/images/logo.png";
import Aide from "../../../assets/images/bulle_aide.png";
import LogoColissimo from "../../../assets/images/colissimo_logo.png";

class Contact extends React.Component {

  render() {
    return (
      <div>
      <Layout cols={12}>
        <Cell span={4}>
          <Card>
            <MarketingLayout
              title={<Box><Image src={LogoColissimo} width="20px" transparent/>&nbsp;&nbsp;{I18n.t('support.slide4.bloc1.title')}</Box>}
              description={I18n.t('support.slide4.bloc1.text1')}
              size="tiny"
            />
          </Card>
        </Cell>
        <Cell span={8}>
          <Card>
            <Card.Content>
              <Cell>
                <SectionHelper fullWidth appearance="standard">
                  <Text>
                    {I18n.t('support.slide4.bloc1.text2')}
                  </Text>
                </SectionHelper>
                <Text>
                  <p><b>{I18n.t('support.slide4.bloc1.text3')} 02 41 74 20 88</b></p><br/>
                  <p>
                    {I18n.t('support.slide4.bloc1.text4')}
                    <ul>
                      <li>{I18n.t('support.slide4.bloc1.li1')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li2')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li3')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li4')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li6')}</li>
                    </ul>
                  </p>
                </Text>
              </Cell>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
      <Divider />
      <Layout cols={12}>
        <Cell span={4}>
          <Card>
            <MarketingLayout
              title={<Box><Image src={LogoHappy} width="120px" transparent />&nbsp;&nbsp;{I18n.t('support.slide4.bloc2.title')}</Box>}
              description={I18n.t('support.slide4.bloc2.text1')}
              size="tiny"
            />
          </Card>
        </Cell>
        <Cell span={8}>
          <Card>
            <Card.Content>
              <Cell>
                <SectionHelper fullWidth appearance="standard">
                  <Text>
                    {I18n.t('support.slide4.bloc2.text2')}
                  </Text>
                </SectionHelper>
                <Text>
                  <p><b>{I18n.t('support.slide4.bloc2.text3')}</b></p>
                  <br/>
                  <div><img src={Aide} /></div>
                  <br/>
                  <p>
                    {I18n.t('support.slide4.bloc2.text4')}
                    <ul>
                      <li>{I18n.t('support.slide4.bloc1.li1')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li2')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li3')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li4')}</li>
                      <li>{I18n.t('support.slide4.bloc1.li6')}</li>
                    </ul>
                  </p>
                </Text>
              </Cell>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
      </div>
    );
  }
}

Contact.propTypes = {
  renderToast: PropTypes.func,
  toogleToast: PropTypes.func,
};

export default Contact