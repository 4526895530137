import React from 'react';
import { 
  Modal,
  CustomModalLayout,
  Box,
  Text,
  Image,
  Divider,
  Heading,
  Button
} from '@wix/design-system';

import OnBoarding1 from "../../../assets/images/onboardingwix1.png";
import OnBoarding2 from "../../../assets/images/onboardingwix2.png";
import OnBoarding3 from "../../../assets/images/onboardingwix3.png";
import OnBoarding4 from "../../../assets/images/onboardingwix4.png";

class OnBoarding extends React.Component {

    render() {
        return(
            <Modal
              isOpen={this.props.onboarding}
              onRequestClose={() => this.props.contentOverlay('onboarding', false)}
              shouldCloseOnOverlayClick
              screen="desktop"
            >
              <CustomModalLayout
                primaryButtonText={<Button as="a" size="small" href="/my-account">{I18n.t('onboarding.config_button')}</Button>}
                secondaryButtonText={I18n.t('onboarding.use_app')}
                secondaryButtonOnClick={() => this.props.contentOverlay('onboarding', false)}
                onCloseButtonClick={() => this.props.contentOverlay('onboarding', false)}
                title={I18n.t('onboarding.titleSlide')}
                content={
                  <Box direction="vertical">
                    <Box marginBottom="36px">
                      <Image src={OnBoarding1} width="100%" transparent />
                    </Box>
                    <Box marginBottom="36px">
                      <Heading size="medium">{I18n.t('onboarding.welcome')}</Heading>
                    </Box>
                    <Box marginBottom="36px">
                      <Text>
                        {I18n.t('onboarding.welcome2')}
                      </Text>
                    </Box>
                    <Divider />
                    <Box marginBottom="36px">
                      <Image src={OnBoarding2} width="100%" transparent />
                    </Box>
                    <Box marginBottom="36px">
                      <Heading size="medium">{I18n.t('onboarding.save_time')}</Heading>
                    </Box>
                    <Box marginBottom="36px">
                      <Text>
                        {I18n.t('onboarding.save_time2')}
                      </Text>
                    </Box>
                    <Divider />
                    <Box marginBottom="36px">
                      <Image src={OnBoarding3} width="100%" transparent />
                    </Box>
                    <Box marginBottom="36px">
                      <Heading size="medium">{I18n.t('onboarding.features')}</Heading>
                    </Box>
                    <Box marginBottom="36px">
                      <Text>
                        {I18n.t('onboarding.features2')}
                      </Text>
                    </Box>
                    <Divider />
                    <Box marginBottom="36px">
                      <Image src={OnBoarding4} width="100%" transparent />
                    </Box>
                    <Box marginBottom="36px">
                      <Heading size="medium">{I18n.t('onboarding.helpbox')}</Heading>
                    </Box>
                    <Box marginBottom="36px">
                      <Text>
                        {I18n.t('onboarding.helpbox2')}
                      </Text>
                    </Box>
                  </Box>
                }
              />
            </Modal>
        )
    }
}

export default OnBoarding