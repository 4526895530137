import React from "react";
import {
  Card,
  Button,
  Modal,
  MarketingLayout,
  FormField,
  Input,
  Image,
  Layout,
  Cell,
  Heading,
  Loader,
  Notification,
  Dropdown,
  ColorInput,
  Divider
} from '@wix/design-system';

import MapExample from "../../../assets/images/map_exemple.png";

import { passCsrfToken } from '../../util/helpers'
import axios from 'axios'

class FrontOffice extends React.Component {
  state = {
    config: this.props.configData,
    loading: false,
    testWebservice: false,
    showNotification: false,
    loadingSaveProcess: false,
  };

  componentDidMount = () => {
    passCsrfToken(document, axios);
    $('#widget_font_text h1').css("font-family", this.state.config.widget_font);
  }

  /* Pour le changement de chaque champ de formulaire */
  handleChange = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, [e.target.name]: e.target.value },
    })
    this.props.handleUpdateConfig(true);
  };

  handleChangeFont = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, ['widget_font']: e.id },
    })
    $('#widget_font_text h1').css("font-family", e.id);
  }

  handleChangeColor = (field, value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, [field]: value },
    })
  }

  /* Requête de test API */
  apiTestingService = () => {
    this.setState({loading: true});
    axios({
    url: '/apitest-authentication',
    method: 'POST',
    data: {
        account_login: this.state.config.account_login,
        account_password: this.state.config.account_password,
        instance_id: this.props.shop.instance_id
    },
    })
    .then(result => {
      if (result.data.success == true) {
        this.setState({loading: false});
        this.setState({testWebservice: 'success'});
        this.setState({showNotification: true});
      } else {
        this.setState({testWebservice: 'error'});
        this.setState({showNotification: true});
        this.setState({loading: false});
      }
    })
    .catch(error => {
      console.log(error);
      this.setState({testWebservice: 'error'});
      this.setState({showNotification: true});
      this.setState({loading: false});
    });
  };

  /* Submit FORM */
  handleSubmit = () => {
    this.setState({showNotification: false});
    this.setState({ loadingSaveProcess: true });

    axios({
    url: this.state.config.id ? '/configs/' + this.state.config.id : '/configs',
    method: this.state.config.id ? 'patch' : 'post',
    data: {
      config: this.state.config,
      instance_id: this.props.shop.instance_id
    },
    }).then(result => {
      if (result.data.success == true) {
        var data = result.data;
        data.config.local_offer = Object.keys(JSON.parse(data.config.local_offer));
        this.setState({ config: data.config });
        this.props.childChange(data.config);
        this.setState({loadingSaveProcess: false});
        //We update general props
        this.props.handleUpdateConfig(false);
        this.props.toggleToast(I18n.t('configs.save_success'), false);
      } else {
        this.setState({loadingSaveProcess: false});
        this.props.toggleToast(I18n.t('configs.save_error'), true);
      }
    }).catch(error => {
      console.log(error);
      this.setState({loadingSaveProcess: false});
      this.props.toggleToast(I18n.t('configs.save_error'), true);
    });
  };

  render() {
    const {
      config,
      loading,
      showNotification,
      testWebservice,
      loadingSaveProcess
    } = this.state;

    return (
      <div>
        <Modal
          screen="desktop"
          isOpen={loadingSaveProcess}
        >
          <Loader size="large" />
        </Modal>
        {/* URL WIDGET */}
        <Layout cols={12}>
          <Cell span={4}>
            <Card>
              <MarketingLayout
                title={I18n.t('configs.front_end.title_endpoint')}
                description={I18n.t('configs.front_end.help_text_endpoint')}
                size="tiny"
              />
            </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <FormField
                    label={I18n.t('configs.front_end.title_endpoint')}
                  >
                    <Input
                      value={config.widget_endpoint}
                      type="text"
                      name="widget_endpoint"
                      readOnly
                    />
                  </FormField>
                  <br/>
                  <Button size="small" with="200px" as="button" onClick={() => {this.apiTestingService()}}>
                    {loading == true && 
                      <Loader size="tiny" />
                    }
                    {loading == false && 
                      I18n.t('configs.my_account.test_api')
                    }
                  </Button>
                  <br/><br/>
                    {testWebservice == 'success' &&
                      <div>
                        <Notification autoHideTimeout="2500" theme="success" show={showNotification}>
                          <Notification.TextLabel>{I18n.t('configs.my_account.test_api_success')}</Notification.TextLabel>
                          <Notification.CloseButton />
                        </Notification><br/>
                      </div>
                    }
                    {testWebservice == 'error' &&
                      <div>
                        <Notification autoHideTimeout="2500" theme="error" show={showNotification}>
                          <Notification.TextLabel>{I18n.t('configs.my_account.test_api_error')}</Notification.TextLabel>
                          <Notification.CloseButton />
                        </Notification><br/>
                      </div>
                    }
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* BOUTON PANIER */}
        <Layout cols={12}>
          <Cell span={4}>
            <Card>
              <MarketingLayout
                title={I18n.t('configs.front_end.checkout_button_color')}
                description={I18n.t('configs.front_end.help_checkout_button_color')}
                size="tiny"
              />
            </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <FormField label={I18n.t('configs.front_end.button_color')}>
                    <ColorInput
                      value={config.button_color}
                      onConfirm={(e) => this.handleChangeColor('button_color', e)}
                      popoverAppendTo="viewport"
                    />
                  </FormField>
                  <br/>
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* COULEUR AFFICHAGE LISTE */}
        <Layout cols={12}>
          <Cell span={4}>
            <Card>
              <MarketingLayout
                title={I18n.t('configs.front_end.title1')}
                description={I18n.t('configs.front_end.help_text_config1')}
                size="tiny"
              />
            </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <FormField label={I18n.t('configs.front_end.widget_color_1')}>
                    <ColorInput
                      value={config.widget_color_1}
                      onConfirm={(e) => this.handleChangeColor('widget_color_1', e)}
                      popoverAppendTo="viewport"
                    />
                  </FormField>
                  <br/>
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* COULEUR AFFICHAGE CHAMP ADRESSE */}
        <Layout cols={12}>
          <Cell span={4}>
            <Card>
              <MarketingLayout
                title={I18n.t('configs.front_end.title2')}
                description={I18n.t('configs.front_end.help_text_config2')}
                size="tiny"
              />
            </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <FormField label={I18n.t('configs.front_end.widget_color_2')}>
                    <ColorInput
                      value={config.widget_color_2}
                      onConfirm={(e) => this.handleChangeColor('widget_color_2', e)}
                      popoverAppendTo="viewport"
                    />
                  </FormField>
                  <br/>
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* POLICE DE CARACTERES */}
        <Layout cols={12}>
          <Cell span={4}>
            <Card>
              <MarketingLayout
                title={I18n.t('configs.front_end.title_font')}
                size="tiny"
              />
            </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <FormField label={I18n.t('configs.front_end.label_font')}>
                    <Dropdown
                      name="widget_font"
                      options={[
                        {id: 'Arial', value: 'Arial'},
                        {id: 'Arial Black', value: 'Arial Black'},
                        {id: 'Comic Sans MS', value: 'Comic Sans MS'},
                        {id: 'Courrier New', value: 'Courrier New'},
                        {id: 'Georgia', value: 'Georgia'},
                        {id: 'Impact', value: 'Impact'},
                        {id: 'Lucida Console', value: 'Lucida Console'},
                        {id: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode'},
                        {id: 'Tahoma', value: 'Tahoma'},
                        {id: 'Times New Roman', value: 'Times New Roman'},
                        {id: 'Trebuchet MS', value: 'Trebuchet MS'},
                        {id: 'Verdana', value: 'Verdana'},
                        {id: 'MS Sans Serif', value: 'MS Sans Serif'},
                        {id: 'MS Serif', value: 'MS Serif'},
                      ]}
                      onSelect={this.handleChangeFont}
                      selectedId={config.widget_font !== null && config.widget_font ? config.widget_font : 'Arial'}
                    />
                    <div id="widget_font_text">
                      <Heading size="extraLarge">{I18n.t('configs.front_end.example_font')}</Heading>
                    </div>
                  </FormField>
                  <br/>
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* EXEMPLE D'AFFICHAGE */}
        <Layout cols={12}>
          <Cell span={4}>
            <Card>
              <MarketingLayout
                title={I18n.t('configs.front_end.example_display')}
                size="tiny"
              />
            </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <Image src={MapExample} width="100%" transparent />
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
      </div>
    );
  }
}

export default FrontOffice