import React from "react";
import { Route, Routes } from 'react-router-dom';
import Orders from "./orders/OrderIndex"
import Coliship from "./coliship/ColishipIndex"
import Configs from "./configs/ConfigForm"
import DepositGlobal from "./deposit/DepositGlobal"
import TrackingGlobal from "./tracking/TrackingGlobal"
import Support from "./support/Support"
import Footer from "./Footer";

class Router extends React.Component {
  render() {
    return (
      <Routes>
        <Route exact path="/" element={<>
          {this.props.config_no_coliship ? (
            <Orders
                {...this.props}
                contentOverlay={this.props.contentOverlay}
                toggleToast={this.props.toggleToast}
                showToast={this.props.showToast}
            />) : (
              <Coliship
                {...this.props}
                contentOverlay={this.props.contentOverlay}
                toggleToast={this.props.toggleToast}
                showToast={this.props.showToast}
            />
            )}
            <Footer
                contentOverlay={this.props.contentOverlay}
            />
          </>
        }/>
        <Route exact path="/deposit" element={<>
            <DepositGlobal
                {...this.props} 
                contentOverlay={this.props.contentOverlay}
                toggleToast={this.props.toggleToast}
                showToast={this.props.showToast}
                ref={instance => { this.childDepositGlobal= instance; }}
                error={this.props.error}
            />
            <Footer
                contentOverlay={this.props.contentOverlay}
            />
          </>
        }/>
        <Route exact path="/tracking" element={<>
            <TrackingGlobal
                {...this.props} 
                contentOverlay={this.props.contentOverlay}
                toggleToast={this.props.toggleToast}
                showToast={this.props.showToast}
                ref={instance => { this.childDepositGlobal= instance; }}
                error={this.props.error}
            />
            <Footer
                contentOverlay={this.props.contentOverlay}
            />
          </>
        }/>
        <Route exact path="/support" element={<>
            <Support
                {...this.props} 
            />
            <Footer
                contentOverlay={this.props.contentOverlay}
            />
          </>
        }/>
        <Route exact path="/my-account" element={<>
            <Configs
                {...this.props}
                configData={this.props.configData}
                childChange={this.props.childChange}
                contentOverlay={this.props.contentOverlay}
                selectedKey={this.props.selectedKey}
                setSelectedKey={this.props.setSelectedKey}
                configStep={this.props.configStep}
                showToast={this.props.showToast}
                toggleToast={this.props.toggleToast}
            />
            <Footer
                contentOverlay={this.props.contentOverlay}
            />
          </>
        }/>
      </Routes>
    );
  }
}

export default Router