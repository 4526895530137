import React from "react";
import {
  Card,
  Button,
  TextButton,
  MarketingLayout,
  FormField,
  Input,
  Image,
  Layout,
  Box,
  Cell,
  SectionHelper,
  Text,
  Loader,
  Notification,
  Dropdown,
  ToggleSwitch,
  Collapse,
  Modal,
  Divider
} from '@wix/design-system';

import { passCsrfToken } from '../../util/helpers'
import axios from 'axios'

class MyAccount extends React.Component {

  state = {
    config: this.props.configData,
    selectedSameAddress: this.props.configData.same_address_return_sender,
    loading: false,
    testWebservice: false,
    showNotification: false,
    loadingSaveProcess: false,
  };

  componentDidMount = () => {
    passCsrfToken(document, axios);
  }

  /* Methode de vérification des champs de form */
  validateForm = () => {
    var hasErrors = false;

    if (this.state.config.account_login !== null && this.state.config.account_login.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.account_password !== null && this.state.config.account_password.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_company !== null && this.state.config.sender_company.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_lastname !== null && this.state.config.sender_lastname.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_firstname !== null && this.state.config.sender_firstname.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_address1 !== null && this.state.config.sender_address1.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_city !== null && this.state.config.sender_city.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_postcode !== null && this.state.config.sender_postcode.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_email !== null && this.state.config.sender_email.length < 1) {
      hasErrors = true;
    }

    if (this.state.config.sender_phone !== null && this.state.config.sender_phone.length < 1) {
      hasErrors = true;
    }

    if (!this.state.selectedSameAddress) {
      if (this.state.config.return_company !== null && this.state.config.return_company.length < 1) {
        hasErrors = true;
      }
  
      if (this.state.config.return_lastname !== null && this.state.config.return_lastname.length < 1) {
        hasErrors = true;
      }
  
      if (this.state.config.return_firstname !== null && this.state.config.return_firstname.length < 1) {
        hasErrors = true;
      }
  
      if (this.state.config.return_address1 !== null && this.state.config.return_address1.length < 1) {
        hasErrors = true;
      }
  
      if (this.state.config.return_city !== null && this.state.config.return_city.length < 1) {
        hasErrors = true;
      }
  
      if (this.state.config.return_postcode !== null && this.state.config.return_postcode.length < 1) {
        hasErrors = true;
      }
  
      if (this.state.config.return_email !== null && this.state.config.return_email.length < 1) {
        hasErrors = true;
      }
  
      if (this.state.config.return_phone !== null && this.state.config.return_phone.length < 1) {
        hasErrors = true;
      }
    }
    return !hasErrors;
  };

  validateEmail = () => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.config.sender_email);
  };

  validatePhone = () => {
    return new RegExp(/(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/g).test(this.state.config.sender_phone);
  };

  validateReturnEmail = () => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.config.return_email);
  };

  validateReturnPhone = () => {
    return new RegExp(/(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/g).test(this.state.config.return_phone);
  };
  /* Fin méthode de vérificiation */


  /* Pour le changement de chaque champ de formulaire */
  handleChange = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, [e.target.name]: e.target.value },
    })
  };
  
  /* Gestion des mises à jour de country */
  handleSelectChangeCountry = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, ['sender_iso_country']: e.id },
    })
  }

  handleSelectChangeReturnCountry = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, ['return_iso_country']: e.id },
    })
  }
  /* Fin gestion des country */

  /* Requête de test API */
  apiTesting = () => {
    this.setState({loading: true});
    axios({
    url: '/apitest',
      method: 'POST',
      data: {
        account_login: this.state.config.account_login,
        account_password: this.state.config.account_password,
        instance_id: this.props.shop.instance_id
      },
    })
    .then(result => {
      if (result.data.success == true) {
        this.setState({loading: false});
        this.setState({testWebservice: 'success'});
        this.setState({showNotification: true});
      } else {
        this.setState({testWebservice: 'error'});
        this.setState({showNotification: true});
        this.setState({loading: false});
      }
    })
    .catch(error => {
      console.log(error);
      this.setState({testWebservice: 'error'});
      this.setState({showNotification: true});
      this.setState({loading: false});
    });
  };

  /* Submit FORM */
  handleSubmit = () => {
    this.setState({showNotification: false});
    this.setState({ loadingSaveProcess: true });
    // Check if data is valid
    if (!this.validateForm()) {
      //return if not valid
      this.props.toggleToast(I18n.t('configs.my_account_error'), true);
      this.setState({ loadingSaveProcess: false });
      return;
    }

    if (!this.validateEmail()) {
      this.props.toggleToast(I18n.t('configs.my_account_error_email'), true);
      this.setState({ loadingSaveProcess: false });
      return;
    }

    if (!this.validatePhone()) {
      this.props.toggleToast(I18n.t('configs.my_account_error_phone'), true);
      this.setState({ loadingSaveProcess: false });
      return;
    }

    if (!this.state.selectedSameAddress) {
      if (!this.validateReturnEmail()) {
        this.props.toggleToast(I18n.t('configs.my_account_error_email'), true);
        this.setState({ loadingSaveProcess: false });
        return;
      }
  
      if (!this.validateReturnPhone()) {
        this.props.toggleToast(I18n.t('configs.my_account_error_phone'), true);
        this.setState({ loadingSaveProcess: false });
        return;
      }
    }

    axios({
    url: this.state.config.id ? '/configs/' + this.state.config.id : '/configs',
    method: this.state.config.id ? 'patch' : 'post',
    data: {
      config: this.state.config,
      instance_id: this.props.shop.instance_id
    },
    }).then(result => {
      if (result.data.success == true) {
        var data = result.data;
        data.config.local_offer = Object.keys(JSON.parse(data.config.local_offer));
        this.setState({ config: data.config });
        this.props.childChange(data.config);
        this.setState({loadingSaveProcess: false});
        //We update general props
        this.props.handleUpdateConfig(false);
        this.props.toggleToast(I18n.t('configs.save_success'), false);
      } else {
        this.setState({loadingSaveProcess: false});
        this.props.toggleToast(I18n.t('configs.save_error'), true);
      }
    }).catch(error => {
      console.log(error);
      this.setState({loadingSaveProcess: false});
      this.props.toggleToast(I18n.t('configs.save_error'), true);
    });
  };

  render() {
    const {
      config,
      loading,
      selectedSameAddress,
      showNotification,
      testWebservice,
      loadingSaveProcess
    } = this.state;

    const descriptionSection = (
      <Card>
        <MarketingLayout
          title={I18n.t('menu.settings')}
          description={`${I18n.t('configs.view_video')} ${I18n.t('configs.step1_video')}`}
          actions={<TextButton as="a" href="https://www.loom.com/share/734323161cf74190b0aecca1340cf1ef?sid=884da7b8-0f61-4cf6-9613-de71fa266910" target="_blank">{I18n.t('configs.view_video_button')}</TextButton>}
          size="tiny"
          image={
            <Box width="100%">
              <Image src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/video_ecran_113.svg?v=1652256024" width="70%" height="70%" transparent />
            </Box>
          }
        />
      </Card>
    );

    return (
      <div>
        <Modal
          screen="desktop"
          isOpen={loadingSaveProcess}
        >
          <Loader size="large" />
        </Modal>
        {/* FOMRULAIRE CONNEXION */}
        <Layout cols={12}>
          <Cell span={4}>
            {descriptionSection}
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <FormField
                  label={I18n.t('configs.my_account.account_login')}
                  infoContent={I18n.t('configs.my_account.account_login_help')}
                  required
                >
                  <Input
                    type="number"
                    status={config.account_login !== null && config.account_login.length > 0 ? '' : 'error'}
                    name="account_login"
                    statusMessage={config.account_login !== null && config.account_login.length > 0 ? '' : I18n.t('configs.my_account.account_login_error')}
                    tooltipPlacement="top-end"
                    value={config.account_login}
                    onChange={this.handleChange}
                  />
                </FormField>
                <br/>
                <FormField
                  label={I18n.t('configs.my_account.account_password')}
                  infoContent={I18n.t('configs.my_account.account_password_help')}
                  required
                >
                  <Input
                    value={config.account_password}
                    type="password"
                    name="account_password"
                    tooltipPlacement="top-end"
                    status={config.account_password !== null && config.account_password.length > 0 ? '' : 'error'}
                    statusMessage={config.account_password !== null && config.account_password.length > 0 ? '' : I18n.t('configs.my_account.account_password_error')}
                    onChange={this.handleChange}
                  />
                </FormField>
                <br/>
                <Button size="small" with="200px" as="button" onClick={() => {this.apiTesting()}}>
                  {loading == true && 
                    <Loader size="tiny" />
                  }
                  {loading == false && 
                    I18n.t('configs.my_account.test_api')
                  }
                </Button>
                <br/><br/>
                  {testWebservice == 'success' &&
                    <div>
                      <Notification autoHideTimeout="2500" theme="success" show={showNotification}>
                        <Notification.TextLabel>{I18n.t('configs.my_account.test_api_success')}</Notification.TextLabel>
                        <Notification.CloseButton />
                      </Notification><br/>
                    </div>
                  }
                  {testWebservice == 'error' &&
                    <div>
                      <Notification autoHideTimeout="2500" theme="error" show={showNotification}>
                        <Notification.TextLabel>{I18n.t('configs.my_account.test_api_error')}</Notification.TextLabel>
                        <Notification.CloseButton />
                      </Notification><br/>
                    </div>
                  }
                  {testWebservice == 'error' &&
                    <SectionHelper fullWidth appearance="standard" title={I18n.t('configs.my_account.webservice_activation')}>
                      <Text listStyle="circle">
                        <ul>
                          <li>{I18n.t('configs.my_account.error_connection_step1')} <a href="https://www.colissimo.entreprise.laposte.fr/" target='_blank'>{I18n.t('configs.my_account.colissimo_box')}</a></li>
                          <li>{I18n.t('configs.my_account.error_connection_step2')}</li>
                          <li>{I18n.t('configs.my_account.error_connection_step3')}</li>
                        </ul>
                      </Text>
                    </SectionHelper>
                  }
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* FOMRULAIRE D'ADRESSE */}
        <Layout cols={12}>
          <Cell span={4}>
          <Card>
            <MarketingLayout
              title={I18n.t('configs.address.title')}
              description={I18n.t('configs.address.help_text')}
              size="tiny"
            />
          </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <FormField
                    label={I18n.t('configs.address.company')}
                    required
                  >
                    <Input
                      type="text"
                      status={config.sender_company !== null && config.sender_company.length > 0 ? '' : 'error'}
                      name="sender_company"
                      statusMessage={config.sender_company !== null && config.sender_company.length > 0 ? '' : I18n.t('configs.address.company_error')}
                      tooltipPlacement="top-end"
                      value={config.sender_company}
                      onChange={this.handleChange}
                      maxLength="35"
                      suffix={<Input.Affix>{config.sender_company !== null ? 35 - config.sender_company.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Layout>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('configs.address.lastname')}
                      required
                    >
                      <Input
                        type="text"
                        status={config.sender_lastname !== null && config.sender_lastname.length > 0 ? '' : 'error'}
                        name="sender_lastname"
                        statusMessage={config.sender_lastname !== null && config.sender_lastname.length > 0 ? '' : I18n.t('configs.address.lastname_error')}
                        tooltipPlacement="top-end"
                        value={config.sender_lastname}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.sender_lastname !== null ? 35 - config.sender_lastname.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('configs.address.firstname')}
                      required
                    >
                      <Input
                        type="text"
                        status={config.sender_firstname !== null && config.sender_firstname.length > 0 ? '' : 'error'}
                        name="sender_firstname"
                        statusMessage={config.sender_firstname !== null && config.sender_firstname.length > 0 ? '' : I18n.t('configs.address.firstname_error')}
                        tooltipPlacement="top-end"
                        value={config.sender_firstname}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.sender_firstname !== null ? 35 - config.sender_firstname.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                </Layout>
                <br/>
                <Cell>
                  <FormField
                    label={I18n.t('configs.address.address1')}
                    required
                  >
                    <Input
                      type="text"
                      status={config.sender_address1 !== null && config.sender_address1.length > 0 ? '' : 'error'}
                      name="sender_address1"
                      statusMessage={config.sender_address1 !== null && config.sender_address1.length > 0 ? '' : I18n.t('configs.address.address1_error')}
                      tooltipPlacement="top-end"
                      value={config.sender_address1}
                      onChange={this.handleChange}
                      maxLength="35"
                      suffix={<Input.Affix>{config.sender_address1 !== null ? 35 - config.sender_address1.length +`/35`: `35/35`}</Input.Affix>}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Cell>
                  <FormField
                    label={I18n.t('configs.address.address2')}
                  >
                    <Input
                      type="text"
                      name="sender_address2"
                      tooltipPlacement="top-end"
                      value={config.sender_address2}
                      onChange={this.handleChange}
                      maxLength="35"
                      suffix={<Input.Affix>{config.sender_address2 !== null ? 35 - config.sender_address2.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Cell>
                  <FormField
                    label={I18n.t('configs.address.address3')}
                  >
                    <Input
                      type="text"
                      name="sender_address3"
                      tooltipPlacement="top-end"
                      value={config.sender_address3}
                      onChange={this.handleChange}
                      maxLength="35"
                      suffix={<Input.Affix>{config.sender_address3 !== null ? 35 - config.sender_address3.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Cell>
                  <FormField
                    label={I18n.t('configs.address.address4')}
                  >
                    <Input
                      type="text"
                      name="sender_address4"
                      tooltipPlacement="top-end"
                      value={config.sender_address4}
                      onChange={this.handleChange}
                      maxLength="35"
                      suffix={<Input.Affix>{config.sender_address4 !== null ? 35 - config.sender_address4.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Layout>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('configs.address.postcode')}
                      required
                    >
                      <Input
                        type="number"
                        status={config.sender_postcode !== null && config.sender_postcode.length > 0 ? '' : 'error'}
                        name="sender_postcode"
                        statusMessage={config.sender_postcode !== null && config.sender_postcode.length > 0 ? '' : I18n.t('configs.address.postcode_error')}
                        tooltipPlacement="top-end"
                        value={config.sender_postcode}
                        onChange={this.handleChange}
                        maxLength="5"
                        suffix={<Input.Affix>{config.sender_postcode !== null ? 5 - config.sender_postcode.length +`/5` : `5/5`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('configs.address.city')}
                      required
                    >
                      <Input
                        type="text"
                        status={config.sender_city !== null && config.sender_city.length > 0 ? '' : 'error'}
                        name="sender_city"
                        statusMessage={config.sender_city !== null && config.sender_city.length > 0 ? '' : I18n.t('configs.address.city_error')}
                        tooltipPlacement="top-end"
                        value={config.sender_city}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.sender_city !== null ? 35 - config.sender_city.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                </Layout>
                <br/>
                <Cell>
                  <FormField
                    label={I18n.t('configs.address.country')}
                    required
                  >
                    <Dropdown
                      name="sender_iso_country"
                      options={[
                          {value: "France", id: "FR"},
                          {value: "Guadeloupe", id: "GP"},
                          {value: "Guyane", id: "GF"},
                          {value: "Ile de la Réunion", id: "RE"},
                          {value: "Martinique", id: "MQ"},
                          {value: "Mayotte", id: "YT"},
                          {value: "Saint Barthélémie", id: "BL"},
                          {value: "Saint Martin", id: "MF"},
                          {value: "Saint Pierre et Miquelon", id: "PM"},
                      ]}
                      onSelect={this.handleSelectChangeCountry}
                      selectedId={config.sender_iso_country !== null && config.sender_iso_country ? config.sender_iso_country : 'FR'}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Layout>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('configs.address.phone')}
                      required
                    >
                      <Input
                        type="text"
                        status={config.sender_phone !== null && config.sender_phone.length > 0 ? '' : 'error'}
                        name="sender_phone"
                        statusMessage={config.sender_phone !== null && config.sender_phone.length > 0 ? '' : I18n.t('configs.address.phone_error')}
                        tooltipPlacement="top-end"
                        value={config.sender_phone}
                        onChange={this.handleChange}
                      />
                      <Text size="tiny">{I18n.t('configs.address.phone_help')}</Text>
                    </FormField>
                  </Cell>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('configs.address.mail')}
                      required
                    >
                      <Input
                        type="email"
                        status={config.sender_email !== null && config.sender_email.length > 0 ? '' : 'error'}
                        name="sender_email"
                        statusMessage={config.sender_email !== null &&config.sender_email.length > 0 ? '' : I18n.t('configs.address.mail_error')}
                        tooltipPlacement="top-end"
                        value={config.sender_email}
                        onChange={this.handleChange}
                        maxLength="80"
                        suffix={<Input.Affix>{config.sender_email !== null ? 80 - config.sender_email.length +`/80` : `80/80`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                </Layout>
                <br/>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* FOMRULAIRE D'ADRESSE DE RETOUR */}
        <Layout cols={12}>
          <Cell span={4}>
          <Card>
            <MarketingLayout
              title={I18n.t('configs.address.title_return')}
              description={I18n.t('configs.address.help_text_return')}
              size="tiny"
            />
          </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '15px',
                      alignItems: 'center',
                    }}
                  >
                    <Box verticalAlign="middle">
                      <ToggleSwitch
                        checked={selectedSameAddress}
                        onChange={() => this.setState({ selectedSameAddress: !selectedSameAddress })}
                        size={'small'}
                      />
                      <Box paddingLeft="SP2"><Text>{I18n.t('configs.address.label_same_address_return_sender')}</Text></Box>
                    </Box>
                  </div>
                </Cell>
                <br/>
                <Collapse open={!selectedSameAddress}>
                  <Cell>
                    <FormField
                      label={I18n.t('configs.address.company')}
                      required
                    >
                      <Input
                        type="text"
                        status={config.return_company !== null && config.return_company.length > 0 ? '' : 'error'}
                        name="return_company"
                        statusMessage={config.return_company !== null && config.return_company.length > 0 ? '' : I18n.t('configs.address.company_error')}
                        tooltipPlacement="top-end"
                        value={config.return_company}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.return_company !== null ? 35 - config.return_company.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                    <br/>
                  </Cell>
                  <Layout>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('configs.address.lastname')}
                        required
                      >
                        <Input
                          type="text"
                          status={config.return_lastname !== null && config.return_lastname.length > 0 ? '' : 'error'}
                          name="return_lastname"
                          statusMessage={config.return_lastname !== null && config.return_lastname.length > 0 ? '' : I18n.t('configs.address.lastname_error')}
                          tooltipPlacement="top-end"
                          value={config.return_lastname}
                          onChange={this.handleChange}
                          maxLength="35"
                          suffix={<Input.Affix>{config.return_lastname !== null ? 35 - config.return_lastname.length +`/35` : `35/35`}</Input.Affix>}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('configs.address.firstname')}
                        required
                      >
                        <Input
                          type="text"
                          status={config.return_firstname !== null && config.return_firstname.length > 0 ? '' : 'error'}
                          name="return_firstname"
                          statusMessage={config.return_firstname !== null && config.return_firstname.length > 0 ? '' : I18n.t('configs.address.firstname_error')}
                          tooltipPlacement="top-end"
                          value={config.return_firstname}
                          onChange={this.handleChange}
                          maxLength="35"
                          suffix={<Input.Affix>{config.return_firstname !== null ? 35 - config.return_firstname.length +`/35` : `35/35`}</Input.Affix>}
                        />
                      </FormField>
                    </Cell>
                  </Layout>
                  <br/>
                  <Cell>
                    <FormField
                      label={I18n.t('configs.address.address1')}
                      required
                    >
                      <Input
                        type="text"
                        status={config.return_address1 !== null && config.return_address1.length > 0 ? '' : 'error'}
                        name="return_address1"
                        statusMessage={config.return_address1 !== null && config.return_address1.length > 0 ? '' : I18n.t('configs.address.address1_error')}
                        tooltipPlacement="top-end"
                        value={config.return_address1}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.return_address1 !== null ? 35 - config.return_address1.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                    <br/>
                  </Cell>
                  <Cell>
                    <FormField
                      label={I18n.t('configs.address.address2')}
                    >
                      <Input
                        type="text"
                        name="return_address2"
                        tooltipPlacement="top-end"
                        value={config.return_address2}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.return_address2 !== null ? 35 - config.return_address2.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                    <br/>
                  </Cell>
                  <Cell>
                    <FormField
                      label={I18n.t('configs.address.address3')}
                    >
                      <Input
                        type="text"
                        name="return_address3"
                        tooltipPlacement="top-end"
                        value={config.return_address3}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.return_address3 !== null ? 35 - config.return_address3.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                    <br/>
                  </Cell>
                  <Cell>
                    <FormField
                      label={I18n.t('configs.address.address4')}
                    >
                      <Input
                        type="text"
                        name="return_address4"
                        tooltipPlacement="top-end"
                        value={config.return_address4}
                        onChange={this.handleChange}
                        maxLength="35"
                        suffix={<Input.Affix>{config.return_address4 !== null ? 35 - config.return_address4.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                    <br/>
                  </Cell>
                  <Layout>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('configs.address.postcode')}
                        required
                      >
                        <Input
                          type="number"
                          status={config.return_postcode !== null && config.return_postcode.length > 0 ? '' : 'error'}
                          name="return_postcode"
                          statusMessage={config.return_postcode !== null && config.return_postcode.length > 0 ? '' : I18n.t('configs.address.postcode_error')}
                          tooltipPlacement="top-end"
                          value={config.return_postcode}
                          onChange={this.handleChange}
                          maxLength="5"
                          suffix={<Input.Affix>{config.return_postcode !== null ? 5 - config.return_postcode.length +`/5` : `5/5`}</Input.Affix>}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('configs.address.city')}
                        required
                      >
                        <Input
                          type="text"
                          status={config.return_city !== null && config.return_city.length > 0 ? '' : 'error'}
                          name="return_city"
                          statusMessage={config.return_city !== null && config.return_city.length > 0 ? '' : I18n.t('configs.address.city_error')}
                          tooltipPlacement="top-end"
                          value={config.return_city}
                          onChange={this.handleChange}
                          maxLength="35"
                          suffix={<Input.Affix>{config.return_city !== null ? 35 - config.return_city.length +`/35` : `35/35`}</Input.Affix>}
                        />
                      </FormField>
                    </Cell>
                  </Layout>
                  <br/>
                  <Cell>
                    <FormField
                      label={I18n.t('configs.address.country')}
                      required
                    >
                      <Dropdown
                        name="return_iso_country"
                        options={[
                            {value: "France", id: "FR"},
                            {value: "Guadeloupe", id: "GP"},
                            {value: "Guyane", id: "GF"},
                            {value: "Ile de la Réunion", id: "RE"},
                            {value: "Martinique", id: "MQ"},
                            {value: "Mayotte", id: "YT"},
                            {value: "Saint Barthélémie", id: "BL"},
                            {value: "Saint Martin", id: "MF"},
                            {value: "Saint Pierre et Miquelon", id: "PM"},
                        ]}
                        onSelect={this.handleSelectChangeReturnCountry}
                        selectedId={config.return_iso_country !== null && config.return_iso_country ? config.return_iso_country : 'FR'}
                      />
                    </FormField>
                    <br/>
                  </Cell>
                  <Layout>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('configs.address.phone')}
                        required
                      >
                        <Input
                          type="text"
                          status={config.return_phone !== null && config.return_phone.length > 0 ? '' : 'error'}
                          name="return_phone"
                          statusMessage={config.return_phone !== null && config.return_phone.length > 0 ? '' : I18n.t('configs.address.phone_error')}
                          tooltipPlacement="top-end"
                          value={config.return_phone}
                          onChange={this.handleChange}
                        />
                        <Text size="tiny">{I18n.t('configs.address.phone_help')}</Text>
                      </FormField>
                    </Cell>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('configs.address.mail')}
                        required
                      >
                        <Input
                          type="email"
                          status={config.return_email !== null && config.return_email.length > 0 ? '' : 'error'}
                          name="return_email"
                          statusMessage={config.return_email !== null && config.return_email.length > 0 ? '' : I18n.t('configs.address.mail_error')}
                          tooltipPlacement="top-end"
                          value={config.return_email}
                          onChange={this.handleChange}
                          maxLength="80"
                          suffix={<Input.Affix>{config.return_email !== null ? 80 - config.return_email.length +`/80` : `80/80`}</Input.Affix>}
                        />
                      </FormField>
                    </Cell>
                  </Layout>
                  <br/>
                </Collapse>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
      </div>      
    );
  }
}

export default MyAccount