import React, {useCallback, useState} from 'react';
import {
    Card,
    Layout,
    Cell,
    Dropzone,
    Box,
    AddItem,
    EmptyState,
    FileUpload,
    TextButton,
    Button,
    Text,
    Heading} from '@wix/design-system';
import axios from 'axios'
import { UploadExport } from '@wix/wix-ui-icons-common';

const ColishipImport = (props) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [orderError, setOrderError] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [totalLine, setTotalLine] = useState(false);

    const handleDropZoneDrop = useCallback((acceptedFiles) => setFiles(files => [...files, ...acceptedFiles]), []);

    const fileUpload = !files.length && <EmptyState
        title={I18n.t('coliship.upload_file')}
        subtitle={I18n.t('coliship.upload_file_help')}
        image="https://www.docs.wixdesignsystem.com/generic_add_item_illustration.svg"
    >
        <FileUpload>
        {({ openFileUploadDialog }) => (
            <TextButton
            onClick={openFileUploadDialog}
            prefixIcon={<UploadExport />}
            >
                {I18n.t('coliship.upload_button')}
            </TextButton>
        )}
        </FileUpload>
    </EmptyState>;

    const uploadedFiles = files.length > 0 && <EmptyState
            title={files.map((file, index) => file.name)}
            subtitle={files.map((file, index) => file.name + ' ' + file.size + 'bytes')}
            image="https://www.docs.wixdesignsystem.com/generic_add_item_illustration.svg"
        >
            <FileUpload>
                {({ openFileUploadDialog }) => (
                    <TextButton
                    onClick={openFileUploadDialog}
                    prefixIcon={<UploadExport />}
                    >
                        {I18n.t('coliship.upload_change')}
                    </TextButton>
                )}
            </FileUpload>
        </EmptyState>;

    const handleUpload = useCallback(
        () => {
            setTotalLine(false);
            setOrderError(false);
            setOrderSuccess(false);
            setLoading(!loading);
            var formData = new FormData();
            formData.append('instance_id', props.shop.instance_id)
            files.map((file, index) => {
                formData.append(`file${index}`, file);
            });

            axios.post('/upload-file-coliship', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(result => {
                console.log(result.data);
                if (result.data.success == true) {
                    setLoading(false);
                    setFiles([]);
                    setOrderError(result.data.order_error);
                    setOrderSuccess(result.data.order_success);
                    setTotalLine(result.data.total_line);
                    props.toggleToast(I18n.t('coliship.success_import_file'), false);
                } else {
                    setLoading(false);
                    setFiles([]);
                    props.toggleToast(I18n.t('coliship.error_import_file'), true);
                }
                props.toggleToast();
            }).catch(error => {
                setLoading(false);
                setFiles([]);
                props.toggleToast(I18n.t('coliship.error_import_file'), true);
            });
        }
    );

    function getError() {
        let contentValue = '';
        for(var key in Object.keys(orderError)){
            var errorContent = orderError[key];
            if (errorContent) {
                for (var keyContent in errorContent) {
                    var value = errorContent[keyContent]
                    contentValue += '<p>' + I18n.t('coliship._tabs.error_at_line') + (parseInt(key, 10) + 1) + ' ' + I18n.t('coliship._tabs.order_number') + '<b>' + keyContent  + '</b> ' + I18n.t('coliship._tabs.tracking_number') + '<b>' + value + '</b>';
                }
            }
            /* use key/value for intended purpose */
        }
        if (contentValue != '') {
            return (
                <Box padding="SP2" margin="SP2" backgroundColor="R20" color="D80">
                    <Heading size="medium">{I18n.t('coliship._tabs.title_error')}</Heading><br/><br/>
                    <Text size="small"><div dangerouslySetInnerHTML={{__html: contentValue}}></div></Text>
                </Box>
            );
        }
    }

    function getSuccess() {
        let contentSuccessValue = '';
        for(var key in Object.keys(orderSuccess)){
            var successContent = orderSuccess[key];
            if (successContent) {
                for (var keyContent in successContent) {
                    var value = successContent[keyContent]
                    contentSuccessValue = contentSuccessValue + '<p>' + I18n.t('coliship._tabs.line') + (parseInt(key, 10) + 1) + ' ' + I18n.t('coliship._tabs.order_number') + '<b>' + keyContent  + '</b> ' + I18n.t('coliship._tabs.tracking_number') + '<b>' + value + '</b>';
                }
            }
            /* use key/value for intended purpose */
        }
        if (contentSuccessValue != '') {
            return (
                <Box padding="SP2" margin="SP2" backgroundColor="G20" color="D80">
                    <Heading size="medium">{I18n.t('coliship._tabs.title_success')}</Heading><br/><br/>
                    <Text size="small"><div dangerouslySetInnerHTML={{__html: contentSuccessValue}}></div></Text>
                </Box>
            );
        }
    }

    return (
        <Card className="notroundedTop">
            <Card.Content>
                <Layout gap="24px">
                <Cell>
                    <Dropzone onDrop={handleDropZoneDrop}>
                    <Dropzone.Overlay>
                        <Box
                        direction="vertical"
                        height="100%"
                        boxSizing="border-box"
                        border="dashed 1px"
                        borderRadius="6px"
                        borderColor="B20"
                        >
                        <AddItem theme="filled" size="large">
                            {I18n.t('coliship.upload_file')}
                        </AddItem>
                        </Box>
                    </Dropzone.Overlay>
                    <Dropzone.Content>
                        <Box
                        direction="vertical"
                        border="dashed 1px"
                        boxSizing="border-box"
                        borderRadius="6px"
                        padding="42px"
                        borderColor="B20"
                        >
                            {fileUpload}
                            {uploadedFiles}
                        </Box>
                    </Dropzone.Content>
                    </Dropzone>
                </Cell>
                <Cell>
                    <Box width="100%" align="space-between">
                        <Button size="small" onClick={handleUpload}>{I18n.t('coliship._tabs.import_files_button')}</Button>
                    </Box>
                </Cell>
                {totalLine > 0 && 
                    <Cell>
                        <Box width="100%"></Box>
                            <Heading size="medium">{I18n.t('coliship._tabs.there_is')} {Object.keys(orderError).length} {I18n.t('coliship._tabs.errors')} {I18n.t('coliship._tabs.on_text')} {totalLine} {I18n.t('coliship._tabs.line_tex')}</Heading><br/>
                        {getSuccess()}  
                        {getError()}             
                    </Cell>
                }
                </Layout>
            </Card.Content>
        </Card>
    );
}

export default ColishipImport