import React from "react";
import {
  Page,
  Card,
  Button,
  Table,
  TableToolbar,
  Search,
  Box,
  TextButton,
  Badge,
  Text,
  Modal,
  Loader,
  Tooltip,
  Divider,
  Radio,
  SidePanel,
  CustomModalLayout,
  FormField,
  Cell,
  Layout,
  Checkbox,
  PopoverMenu,
  Image,
  TagList,
  Accordion,
  DatePicker,
  Pagination,
  IconButton
} from '@wix/design-system';

import { ContentFilterSmall, PrintSmall, MapSmall, MoreSmall, DeleteSmall } from '@wix/wix-ui-icons-common';

import { passCsrfToken } from '../../util/helpers'
import axios from 'axios'

class ByLabelWithDeposit extends React.Component {

  state = {
    labels: this.props.labels,
    page: this.props.page,
    page_limit: this.props.page_limit,
    total_page: this.props.total_page,
    count_labels: this.props.count_labels,
    selectedItems: [],
    searchValue: '',
    countries: [],
    services: [],
    queryValue: null,
    date: [],
    date_to: null,
    date_from: null,
    paymentStatus: [],
    printedStatus: [],
    loading: false,
    modalDeleteConfirm: false,
    modalDeleteBulkConfirm: false,
    delete_label_id: null,
  };

  componentDidMount = () => {
    passCsrfToken(document, axios);
    this.callRequest('/label-filters-with-note');
  }

  capitalize = (str) => {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  handleSortChange = (sortValue) => {
    this.setState({sortValue}, () => {
        this.callRequest('/label-filters-with-note');
    });
  };

  handleRemove = (key) => {
    if (key === 'date_to' || key === 'date_from') {
      this.setState({[key]: null}, () => {
        this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    } else {
      this.setState({[key]: []}, () => {
        this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }
  };
    
  handleChangePage = (value) => {
    this.setState({
      page: value,
    }, () => {
      this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
    });
  }

  handleClear = (type) => {
    if (type === 'date') {
      this.setState({
        [type]: [],
        date_to: null,
        date_from: null,
      }, () => {
        this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    } else {
      this.setState({
        [type]: [],
      }, () => {
        this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }
  }

  handleClearAll = () => {
    this.setState({loading: true});
    this.setState({
      paymentStatus: [],
      printedStatus: [],
      queryValue: null,
      date: [],
      date_to: null,
      date_from: null,
      countries: [],
      services: [],
    }, () => {
      this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
    });
  };

  handleSelectionChange = (selectedItems) => {
      this.setState({selectedItems});
  };

  toggleActive = (field) => {
    this.setState({ [field]: !this.state[field] });
  }

  toggleTag = (type, value) => {
    if (type === 'date' || type === 'printedStatus') {
      if (value != 'custom') {
        this.setState({ [type]: [value] }, () => 
          this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit)
        );
      } else {
        this.setState({ date_to: null });
        this.setState({ date_from: null });
        this.setState({ [type]: [value] });
      }
    } else {
      if (this.state[type].some((tag) => tag === value)) {
        this.setState({
          [type]: this.state[type].filter(item => item !== value)
        }, () => 
          this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit) 
        );
      } else {
        this.setState({
          [type]: [...this.state[type], value]
        }, () => 
          this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit) 
        );
      }
    }
  };

  handleChange = (field) => {
    const includeFilters = [
      'queryValue',
      'date',
      'date_from',
      'date_to'
    ];

    return (value) => {
      this.setState({ [field]: value }, () => {
        if (includeFilters.indexOf(field) !== -1) {       
          if (Array.isArray(value)) {
            if (value[0] == "custom") {
              return true;
            }
          }
          this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
        };
      });
    };
  };

  handleChangeQueryValue = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.callRequest('/label-filters-with-note?page=1&limit='+this.state.page_limit);
    });
  }

  handleOpenSheet = (label_id) => {
      this.getOrderRequest(label_id);
  };

  handleCloseSheet = () => {
    this.setState({sheetActive: false});
    this.setState({filterSide: false});
    this.props.handleSheetChange(false);
  };

  handleToggleSheet = (label_id) => {
    this.props.sheetActive ? this.handleCloseSheet() : this.handleOpenSheet(label_id);
  }

  disambiguateLabel = (key, value) => {
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: this.props.shop.time_zone
    };
    
    switch (key) {
      case 'paymentStatus':
        return value.map((val) => `${I18n.t(val)}`).join(', ');
      case 'printedStatus':
        switch (value[0]) {
          case true:
            return `${I18n.t('order_list.filters.printed')}`;
          case false:
            return `${I18n.t('order_list.filters.unprint')}`;
          default:
            return null;
        }
      case 'date':
        switch (value[0]) {
          case 'past_day':
            return `${I18n.t('order_list.filters.date_today')}`;
          case 'past_week':
            return `${I18n.t('order_list.filters.date_past_week')}`;
          case 'past_month':
            return `${I18n.t('order_list.filters.date_past_month')}`;
          case 'past_quarter':
            return `${I18n.t('order_list.filters.date_past_quarter')}`;
          case 'past_year':
            return `${I18n.t('order_list.filters.date_past_year')}`;
          default:
            return null;
        }
      case 'date_from':
        return `${I18n.t('order_list.filters.date_from')} ${new Intl.DateTimeFormat('default', options).format(new Date(value))}`;
      case 'date_to':
        return `${I18n.t('order_list.filters.date_to')} ${new Intl.DateTimeFormat('default', options).format(new Date(value))}`;
      case 'countries':
        return value.map((val) => `${val}`).join(', ');
      case 'services':
        return value.map((val) => `${val}`).join(', ');
      default:
        return value;
    }
  };
  
  /* Get order detail */
  getOrderRequest = (label_id) => {
    this.setState({loading: true});
    axios({
      method: 'post',
      url: '/get-order-by-label',
      data: {
        label_id: label_id,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.props.handleOrderChange(result.data);
      this.setState({loading: false});
      this.props.handleSheetChange(true);
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
    });
  }

  /* Filter order */
  callRequest = (url = '/label-filters-with-note') => {
    $('html, body').animate({scrollTop: 0}, 10);
    this.setState({loading: true});
    axios.get(url, {
      params: {
        queryValue: this.state.queryValue,
        date: this.state.date,
        date_from: this.state.date_from,
        date_to: this.state.date_to,
        services: this.state.services,
        printedStatus: this.state.printedStatus,
        countries: this.state.countries,
        paymentStatus: this.state.paymentStatus,
        instance_id: this.props.shop.instance_id
      },
    }).then(result => {
      this.setState({labels: result.data.labels});
      this.setState({page: result.data.page});
      this.setState({page_limit: result.data.page_limit});
      this.setState({total_page: result.data.total_page});
      this.setState({count_labels: result.data.count_labels});
      this.setState({loading: false});
    }).catch(error => {
        console.log(error);
        this.setState({loading: false});
    });
  }

  generateBulkDeposit = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    /* We generate label in mass */
    this.setState({loading: true});
    axios({
      method: 'post',
      url: '/generate-deposit',
      data: {
        label_ids: this.state.selectedItems,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.props.toggleToast(result.data.response_ws.message, false);
      } else {
        this.props.toggleToast(result.data.response_ws.message, true);
      }
      this.setState({selectedItems: []});
      this.callRequest('/label-filters-with-note?page='+this.state.page+'&limit='+this.state.page_limit);
    }).catch(error => {
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_generate_label'), true);
    });
  };

  printLabel = (id) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    axios({
      method: 'get',
      url: '/colissimo-label/'+id+'/'+this.props.shop.instance_id
    }).then(result => {
      if (result.data.printer_request) {
          $.get(result.data.printer_request);
      } else {
        this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
      }
    }).catch(error => {
      console.log(error);
      this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
    });
  };

  printBulkLabel = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    const items = (this.state.sortValue == 'DESC' ? this.state.selectedItems.sort().reverse() : this.state.selectedItems.sort());

    if (this.props.config.use_thermal_printer == true) {
      items.map((item, index) => {
        setTimeout(() => { 
          axios({
            method: 'get',
            url: '/deposit-print-bulk-label/'+item+'/'+this.props.shop.instance_id
          }).then(result => {
            if (result.data.printer_request) {
              $.get(result.data.printer_request);
            } else {
              this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
            }
          }).catch(error => {
            console.log(error);
            this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
          });
        }, 1000*index);
      });
    } else {
      window.open('/deposit-print-bulk-label/'+items+'/'+this.props.shop.instance_id, '_blank');
    }
  };

  reinitVars = () => {
    this.setState({modalDeleteConfirm: false});
    this.setState({modalDeleteBulkConfirm: false});
    this.setState({delete_label_id: null});
  }

  deleteLabelToDepositConfirm = (label_id) => {
    this.setState({delete_label_id: label_id});
    this.setState({modalDeleteConfirm: true});
  }

  deleteBulkLabelToDepositConfirm = () => {
    this.setState({modalDeleteBulkConfirm: true});
  }

  deleteLabelDepositBulk = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});
    this.setState({modalDeleteBulkConfirm: false});
    axios({
      method: 'post',
      url: '/delete-label-deposit',
      data: {
        label_ids: this.state.selectedItems,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.props.toggleToast(result.data.response_ws.message, false);
      } else {
        this.props.toggleToast(result.data.response_ws.message, true);
      }
      this.setState({selectedItems: []});
      this.callRequest('/label-filters-with-note');
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('deposit_list.error_delete_label'), true);
    });
  }

  actionDeleteLabelToDeposit = (label_id) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});
    this.setState({modalDeleteConfirm: false});
    axios({
      method: 'post',
      url: '/delete-label-by-id-to-deposit',
      data: {
        label_id: label_id,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.props.toggleToast(I18n.t('deposit_list.success_delete_label'), false);
      } else {
        this.props.toggleToast(I18n.t('deposit_list.error_delete_label'), true);
      }
      this.setState({selectedItems: []});
      this.callRequest('/label-filters-with-note');
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('deposit_list.error_delete_label'), true);
    });
  }

  displayModalDeleteLabelToDeposit = () => {
    return (
      <Modal
        isOpen={this.state.modalDeleteConfirm}
        onRequestClose={() => this.reinitVars()}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
            primaryButtonText={I18n.t('deposit_list.modal.button_confirm')}
            secondaryButtonText={I18n.t('deposit_list.modal.cancel_button')}
            onCloseButtonClick={() => this.reinitVars()}
            secondaryButtonOnClick={() => this.reinitVars()}
            primaryButtonOnClick={() => this.actionDeleteLabelToDeposit(this.state.delete_label_id)}
            title={I18n.t('deposit_list.modal.delete_label_to_desposit_tooltip')}
            content={
              <Box direction="vertical" marginBottom="SP2">
                <Text>{I18n.t('deposit_list.modal.delete_to_deposit_message')}</Text>
              </Box>
            }
        ></CustomModalLayout>
      </Modal>
    );
  }

  displayModalDeleteBulkLabelToDeposit = () => {
    return (
      <Modal
        isOpen={this.state.modalDeleteBulkConfirm}
        onRequestClose={() => this.reinitVars()}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
            primaryButtonText={I18n.t('deposit_list.modal.button_confirm')}
            secondaryButtonText={I18n.t('deposit_list.modal.cancel_button')}
            onCloseButtonClick={() => this.reinitVars()}
            secondaryButtonOnClick={() => this.reinitVars()}
            primaryButtonOnClick={() => this.deleteLabelDepositBulk()}
            title={I18n.t('deposit_list.modal.delete_label_to_deposit_bulk_title')}
            content={
              <Box direction="vertical" marginBottom="SP2">
                <Text>{I18n.t('deposit_list.modal.delete_to_deposit_bulk_message')}</Text>
              </Box>
            }
        ></CustomModalLayout>
      </Modal>
    );
  }

  renderColumnLabelNumber = (row) => {
    const {id, shipping_number, payment_status, created_at, commercial_name, country_code, province_code} = row;

    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
      timeZone: this.props.shop.time_zone
    };

    const cn23 = ((this.props.config !== null && this.props.config.sender_iso_country == 'FR' && this.props.iso_eu_countries_no_cn23.indexOf(country_code) === -1 && this.props.iso_fr.indexOf(country_code) === -1) || (this.props.config !== null && this.props.config.sender_iso_country != 'FR' && this.props.iso_dom_countries_no_cn23.indexOf(country_code) === -1) || (this.props.config !== null && this.props.config.sender_iso_country == 'FR' && country_code == 'ES' && this.props.province_code_cn23.indexOf(province_code) > -1 )) ? (
      <Box verticalAlign="middle">
        <Text size="tiny" skin="error"><MapSmall /></Text>&nbsp;<Text size="tiny" skin="error">{I18n.t('deposit_list.cn23_alert')}</Text>
      </Box>) : null;

    return (
      <div>
        <Box gap="SP1" verticalAlign="middle">
          <TextButton onClick={(e) => this.handleToggleSheet(id)}>{shipping_number}</TextButton>
          {payment_status == 'PARTIALLY_REFUNDED' && <Badge skin="general" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'PAID' && <Badge skin="success" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'FULLY_REFUNDED' && <Badge skin="neutral" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'PENDING' && <Badge skin="warning" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'NOT_PAID' && <Badge skin="danger" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'PARTIALLY_PAID' && <Badge skin="urgent" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'UNSPECIFIED_PAYMENT_STATUS' && <Badge skin="standard" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
        </Box>
        <Box>
          <Text size="tiny" skin="disabled">{new Intl.DateTimeFormat('default', options).format(new Date(created_at))}</Text>
        </Box>
        <Box>
          <Text size="tiny"><span style={{color:'#F49342'}}>{commercial_name !== null && commercial_name !== '' ? commercial_name : I18n.t('order_list.no_colissimo_order')}</span></Text>
        </Box>
        {cn23}
      </div>
    );
  }

  renderColumnOrderNumber = (row) => {
    const {id, wix_order_number, wix_order_created_at} = row;

    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
      timeZone: this.props.shop.time_zone
    };

    return (
      <div>
        <Box gap="SP1" verticalAlign="middle">
          <TextButton onClick={(e) => this.handleToggleSheet(id)}>#{wix_order_number}</TextButton>
        </Box>
        <Box>
          <Text size="tiny" skin="disabled">{new Intl.DateTimeFormat('default', options).format(new Date(wix_order_created_at))}</Text>
        </Box>
      </div>
    );
  }

  renderColumnCustomer = (row) => {
    const {full_name, email} = row;

    return (
      <div>
        <Box>
          <Text weight="bold" size="tiny">{full_name}</Text>
        </Box>
        <Box>
          <Text weight="thin" size="tiny">{email}</Text>
        </Box>
      </div>
    );
  }

  renderColumnFulfillment = (row) => {
    const {fulfillment_status, country_code, country} = row;

    return (
      <div>
        <Box align="center">
            {fulfillment_status == 'FULFILLED' && <Badge skin="neutral" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
            {fulfillment_status == 'NOT_FULFILLED' && <Badge skin="warning" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
            {fulfillment_status == 'CANCELED' && <Badge skin="danger" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
            {fulfillment_status == 'PARTIALLY_FULFILLED' && <Badge skin="urgent" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
        </Box>
        <Box align="center">
        {(typeof country != 'undefined' && country != null) ? (<Text size="tiny"><span style={{color:'#F49342'}}>{this.capitalize(country)}</span></Text>) : ('')}
        </Box>
        {(typeof country_code != 'undefined' && country_code != null) ? (<div style={{backgroundImage:`url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${country_code.toLowerCase()}.svg)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '1em'}}></div>) : (' ')}
      </div>
    );
  }

  renderColumnPrint = (row) => {
    const {print_at} = row;

    return (
      <div>
        <Box align="center">
            {print_at !== null && <Badge skin="success" size="tiny">
                {I18n.t('deposit_list.printed')}
            </Badge>}
            {print_at === null && <Badge skin="danger" size="tiny">
              {I18n.t('deposit_list.unprinted')}
            </Badge>}
        </Box>
      </div>
    );
  }

  renderColumnActions = (row) => {
    const {id} = row;

    return (
      <div>
        {( this.props.config.use_thermal_printer == true) && 
          <Button
            priority="secondary"
            size="small"
            prefixIcon={<PrintSmall />}
            onClick={(e) => this.printLabel(id)}
          >
            {I18n.t('order_list.print')}
          </Button>
        }
        {( this.props.config.use_thermal_printer == false) && 
          <Button
            priority="secondary"
            size="small"
            prefixIcon={<PrintSmall />}
            as="a" href={`/colissimo-label/${id}/${this.props.shop.instance_id}`} target="_blank"
          >
            {I18n.t('order_list.print')}
          </Button>
        }&nbsp;
        <PopoverMenu
            textSize="small"
            triggerElement={
              <Tooltip content={I18n.t('order_list.more_actions')}>
                <IconButton priority="secondary" size="small">
                  <MoreSmall />
                </IconButton>
              </Tooltip>
            }
          >
            <PopoverMenu.MenuItem
              text={I18n.t('deposit_list.delete_label_to_deposit')}
              skin="destructive"
              prefixIcon={<DeleteSmall />}
              onClick={(e) => this.deleteLabelToDepositConfirm(id)}
            />
          </PopoverMenu>
      </div>
    );
  }

  render() {
    const {
      labels,
      loading,
      filterSide,
      modalDeleteConfirm,
      modalDeleteBulkConfirm,
      queryValue
    } = this.state;

    const columns = [
      {
        title: I18n.t('order_list.label_number'),
        render: (row) => this.renderColumnLabelNumber(row),
        width: 260
      },
      {
        title: I18n.t('order_list.customer'),
        render: (row) => this.renderColumnCustomer(row),
        width: 180
      },
      {
        title: I18n.t('order_list.fulfillmment'),
        render: (row) => this.renderColumnFulfillment(row),
        width: 100,
        align: 'center'
      },
      {
        title: I18n.t('order_list.print_status_label'),
        render: (row) => this.renderColumnPrint(row),
        width: 80,
        align: 'center'
      },
      {
        title: I18n.t('order_list.order_number'),
        render: (row) => this.renderColumnOrderNumber(row),
        width: 100
      },
      {
        title: I18n.t('order_list.actions'),
        render: (row) => this.renderColumnActions(row),
        width: 220,
        align: 'center'
      }
    ];

    const includeFilters = [
      'queryValue',
      'paymentStatus',
      'printedStatus',
      'date',
      'date_from',
      'date_to',
      'countries',
      'services'
    ];

    const appliedFilters = Object.keys(this.state)
      .filter((key) => !isEmpty(this.state[key]) && includeFilters.indexOf(key) !== -1)
      .map((key) => {
        return {
        id: key,
        value: this.state[key],
        type: key,
        children: this.disambiguateLabel(key, this.state[key]),
        };
    });

    const tagExists = ({ type, value }) =>
      this.state[type].some((tag) => tag === value)

    const PrintedFilter = [
      {
        label: I18n.t('order_list.filters.printed'),
        value: true,
      },
      {
        label: I18n.t('order_list.filters.unprint'),
        value: false,
      },
    ];

    const dateFilter = [
      {label: I18n.t('order_list.filters.date_today'), value: 'past_day'},
      {label: I18n.t('order_list.filters.date_past_week'), value: 'past_week'},
      {label: I18n.t('order_list.filters.date_past_month'), value: 'past_month'},
      {label: I18n.t('order_list.filters.date_past_quarter'), value: 'past_quarter'},
      {label: I18n.t('order_list.filters.date_past_year'), value: 'past_year'},
      {label: I18n.t('order_list.filters.date_custom'), value: 'custom'},
    ];

    const paginationMarkup =
      this.state.total_page > 1 ? (
      <Pagination
        currentPage={this.state.page}
        totalPages={this.state.total_page}
        onChange={(e) => this.handleChangePage(e.page)}
      />
  ) : null;

    const emptyStateMarkup = !labels.length ? (
      <Card className="notroundedTop">
      <Table.EmptyState
        subtitle={<Box direction="vertical" paddingTop="SP2">
          <Box><br/></Box>
          <Box align="center"><Text weight="bold">{I18n.t('emptystate.depositwith.title')}</Text></Box>
          <Box align="center">{I18n.t('emptystate.depositwith.text1')}</Box>
          <Box align="center">{I18n.t('emptystate.depositwitht.text2')}</Box>
        </Box>}
        image={
          <Image
            height={226}
            width={226}
            src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/BordereauxDexp_226.svg?v=1671459728"
            transparent
          />
        }
      >
        <Button as="a" href="https://loom.com/share/folder/c13b118bcbe94b7f8dde5926bacfae92" target="_blank">{I18n.t('emptystate.link_text')}</Button>
      </Table.EmptyState></Card>
    ) : undefined;

    return (
      <div>
        <Modal
          screen="desktop"
          isOpen={loading}
        >
          <Loader size="large" />
        </Modal>
    
        <Table 
          data={labels}
          columns={columns}
          horizontalScroll
          stickyColumns={2}
          showSelection
          onSelectionChanged={this.handleSelectionChange}
          selectedIds={this.state.selectedItems}
        >
          <Page.Sticky>
            <Card className="notrounded">
              <TableToolbar>
                {this.state.selectedItems.length > 0 &&
                  <TableToolbar.ItemGroup position="start">
                    <TableToolbar.Item>
                      <Box gap="12px">
                        <Box gap="3px">
                          <Text size="small" weight="bold">
                            {this.state.selectedItems.length} {I18n.t('order_list.resources_items_orders_title')} {I18n.t('order_list.selected')}
                          </Text>
                          <Text size="small">{I18n.t('order_list.on_total')} {this.state.labels.length}</Text>
                        </Box>
                      </Box>
                    </TableToolbar.Item>
                    <TableToolbar.Item>
                      <Box height="18px">
                        <Divider direction="vertical" />
                      </Box>
                    </TableToolbar.Item>
                    <TableToolbar.Item layout="button">
                      <Button
                        skin="inverted"
                        size="small"
                        onClick={(e) => this.printBulkLabel()}
                        prefixIcon={<PrintSmall />}
                      >
                        {I18n.t('order_list.bulk_print_label')}
                      </Button>
                    </TableToolbar.Item>
                    <TableToolbar.Item layout="button">
                      <Button
                        skin="inverted"
                        size="small"
                        onClick={(e) => this.deleteBulkLabelToDepositConfirm()}
                        prefixIcon={<DeleteSmall />}
                      >
                        {I18n.t('deposit_list.bulk_delete_deposit')}
                      </Button>
                    </TableToolbar.Item>
                  </TableToolbar.ItemGroup>
                }
                {this.state.selectedItems.length <= 0 &&
                  <TableToolbar.ItemGroup position="start">
                    <TableToolbar.Item>
                      <TableToolbar.Label>{this.state.labels.length}{I18n.t('order_list.resources_items_orders_title')}</TableToolbar.Label>
                    </TableToolbar.Item>
                  </TableToolbar.ItemGroup>
                }
                <TableToolbar.ItemGroup position="end">
                  <TableToolbar.Item>
                    <Button
                      size="small"
                      priority="secondary"
                      prefixIcon={<ContentFilterSmall />}
                      onClick={(e) => this.toggleActive("filterSide")}
                    >
                      {I18n.t('order_list.filters.filters')} (5)
                    </Button>
                  </TableToolbar.Item>
                  <TableToolbar.Item>
                    <Search name="queryValue" size="small" debounceMs={250} value={queryValue} onChange={this.handleChangeQueryValue}/>
                  </TableToolbar.Item>
                </TableToolbar.ItemGroup>
              </TableToolbar>
              <Table.Titlebar />
              <Table.SubToolbar>
                <Box verticalAlign="middle" width="100%" align="space-between">
                  <Box gap="12px" verticalAlign="middle">
                    <Text size="small">{I18n.t('order_list.filters.filter_by')}</Text>
                    <TagList
                      tags={appliedFilters}
                      actionButton={{
                        label: I18n.t('order_list.filters.clear_all'),
                        onClick: () => this.handleClearAll(),
                      }}
                      onTagRemove={(e) => this.handleRemove(e)}
                    />
                  </Box>
                </Box>
              </Table.SubToolbar>
            </Card>
          </Page.Sticky>
          <Table.Content titleBarVisible={false}/>
          {emptyStateMarkup}
        </Table>
        <br/>
        <Layout justifyItems="center">
          <Cell>
            {paginationMarkup}
          </Cell>
        </Layout>
        <Box>
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: `${filterSide ? 0 : -440}px`,
              display: `${filterSide ? 'block' : 'none'}`,
              height: '100%',
              boxShadow:
                '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
              transition: 'right 0.4s ease 0s',
              zIndex: 2000,
            }}
          >
            <SidePanel title="Filter" onCloseButtonClick={() => this.handleCloseSheet()}>
              <SidePanel.Header title={I18n.t('order_list.filters.filters')} />
              <SidePanel.Content className="Nopadding">
              <Accordion
                skin="light"
                hideShadow
                size="small"
                items={[
                  {
                    title: I18n.t('order_list.filters.payment_status'),
                    children: (
                      <Box direction="vertical" gap="12px">
                        {this.props.payment_status.map((value, key) => (
                          <Checkbox
                            checked={tagExists({
                              type: 'paymentStatus',
                              value: value,
                            })}
                            onChange={(e) => this.toggleTag('paymentStatus', value)}
                          >
                            {I18n.t(value)}
                          </Checkbox>
                        ))}
                        <TextButton size="small" onClick={(e) => this.handleClear('paymentStatus')}>{I18n.t('order_list.filters.clear')}</TextButton>
                      </Box>
                    ),
                  },
                  {
                    title: I18n.t('order_list.filters.printedStatus'),
                    children: (
                      <Box direction="vertical" gap="12px">
                        {PrintedFilter.map((value, key) => (
                          <Radio
                            checked={tagExists({
                              type: 'printedStatus',
                              value: value.value,
                            })}
                            onChange={(e) => this.toggleTag('printedStatus', value.value)}
                            label={value.label}
                          />
                        ))}
                        <TextButton size="small" onClick={(e) => this.handleClear('printedStatus')}>{I18n.t('order_list.filters.clear')}</TextButton>
                      </Box>
                    ),
                  },
                  {
                    title: I18n.t('order_list.filters.countries'),
                    children: (
                      <Box direction="vertical" gap="12px">
                        {this.props.countries.map((value, key) => (
                          <Checkbox
                            checked={tagExists({
                              type: 'countries',
                              value: value,
                            })}
                            onChange={(e) => this.toggleTag('countries', value)}
                          >
                            {this.capitalize(value)}
                          </Checkbox>
                        ))}
                        <TextButton size="small" onClick={(e) => this.handleClear('countries')}>{I18n.t('order_list.filters.clear')}</TextButton>
                      </Box>
                    ),
                  },
                  {
                    title: I18n.t('order_list.filters.services'),
                    children: (
                      <Box direction="vertical" gap="12px">
                        {this.props.services.map((value, key) => (
                          <Checkbox
                            checked={tagExists({
                              type: 'services',
                              value: value,
                            })}
                            onChange={(e) => this.toggleTag('services', value)}
                          >
                            {value}
                          </Checkbox>
                        ))}
                        <TextButton size="small" onClick={(e) => this.handleClear('services')}>{I18n.t('order_list.filters.clear')}</TextButton>
                      </Box>
                    ),
                  },
                  {
                    title: I18n.t('order_list.filters.date'),
                    children: (
                      <Box direction="vertical" gap="12px">
                        {dateFilter.map((value, key) => (
                          <Radio
                            checked={tagExists({
                              type: 'date',
                              value: value.value,
                            })}
                            onChange={(e) => this.toggleTag('date', value.value)}
                            label={value.label}
                          />
                        ))}
                        {this.state.date[0] == 'custom' && 
                          <Box direction="vertical">
                            <FormField label={I18n.t('order_list.filters.date_from')}>
                              <DatePicker placeholderText="Date" size="medium" width="240px" value={this.state.date_from} onChange={this.handleChange('date_from')} />
                            </FormField>
                            <FormField label={I18n.t('order_list.filters.date_to')}>
                              <DatePicker placeholderText="Date" size="medium" width="240px" value={this.state.date_to} onChange={this.handleChange('date_to')} />
                            </FormField>
                          </Box>
                        }
                        <TextButton size="small" onClick={(e) => this.handleClear('date')}>{I18n.t('order_list.filters.clear')}</TextButton>
                      </Box>
                    ),
                  },
                ]}
              />
              </SidePanel.Content>
              <SidePanel.Footer>
                <Box gap="12px" paddingBottom="SP4">
                  <Text size="small">{appliedFilters.length} {I18n.t('order_list.filters.filters_applied')}</Text>
                  <TextButton size="small" onClick={(e) => this.handleClearAll()}>{I18n.t('order_list.filters.clear_all')}</TextButton>
                </Box>
                <Button priority="secondary" onClick={(e) => this.handleCloseSheet()} fullWidth>
                  {I18n.t('order_list.filters.close')}
                </Button>
              </SidePanel.Footer>
            </SidePanel>
          </div>
        </Box>
        {modalDeleteConfirm ? this.displayModalDeleteLabelToDeposit() : null}
        {modalDeleteBulkConfirm ? this.displayModalDeleteBulkLabelToDeposit() : null}
      </div>
    );
  }
}

function isEmpty(value) {
  if (Array.isArray(value)) {
      if (value[0] == "custom") {
          return true;
      }
      return value.length === 0;
  } else {
      /* Add specific Hack for custom value */
      return value === '' || value == null;
  }
}
export default ByLabelWithDeposit