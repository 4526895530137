import React from "react";
import { 
  Page,
  Card,
  Button,
  Table,
  TableToolbar,
  Search,
  Box,
  Tooltip,
  TextButton,
  Badge,
  Text,
  NumberInput,
  Modal,
  Loader,
  Heading,
  Divider,
  Radio,
  SidePanel,
  CustomModalLayout,
  FormField,
  Input,
  Cell,
  Layout,
  TableListHeader,
  Checkbox,
  PopoverMenu,
  Tabs,
  Image,
  TagList,
  Accordion,
  DatePicker,
  Slider,
  Pagination
} from '@wix/design-system';

import { ContentFilterSmall, StatusWarningFilled, ShippingSmall, RedoSmall, PrintSmall, ChevronDownLargeSmall, DocumentSmall, MapSmall, ChevronDownSmall, DownloadImportSmall } from '@wix/wix-ui-icons-common';

import dpd from "../../../assets/images/dpd.png";
import austria from "../../../assets/images/austria.png";
import italy from "../../../assets/images/italy.png";
import germany from "../../../assets/images/germany.png";
import luxembourg from "../../../assets/images/luxembourg.png";
import LogoColissimo from "../../../assets/images/Colissimo_Logo_H.png";

import { passCsrfToken } from '../../util/helpers'
import axios from 'axios'

class OrderIndex extends React.Component {
  
  componentDidMount = () => {
    passCsrfToken(document, axios);
    this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
  }

  state = {
    orders: this.props.orders,
    page: this.props.page,
    page_limit: this.props.page_limit,
    count_orders: this.props.count_orders,
    total_page: this.props.total_page,
    global_lines_weight: 0,
    weight: null,
    sheetActive: false,
    selectedOrder: false,
    selectedLabels: false,
    selectedChange: false,
    selectedChangeOffer: false,
    loading: true,
    map_modal: false,
    countries: [],
    services: [],
    queryValue: null,
    date: [],
    date_to: null,
    date_from: null,
    paymentStatus: [],
    labelStatus: [],
    closedStatus: null,
    errorStatus: [],
    printedStatus: [],
    fulfillmentStatus: [],
    sortValue: 'DESC',
    isFirstPage: false,
    isLastPage: true,
    modalChangeAddress: false,
    modalChangeAddressPickup: false,
    modalRegenerate: false,
    modalRegenerateReturn: false,
    modalBulkGenerate: false,
    orderShippingPickup: false,
    orderShipping: false,
    selectedItems: [],
    selectedTabConfig: 0,
    filterSide: false,
    modalImport: false,
    import_orders: null,
    remaining_days: this.props.remaining_days
  }

  handleOpenSheet = (order_id, action_type = "order") => {
    if (action_type == "order") {
      this.getOrderRequest(order_id);
    } else if (action_type == "change") {
      this.getChangeOrderRequest(order_id);
    } else if (action_type == "change_offer") {
      this.getChangeOfferOrderRequest(order_id);
    } else {
      this.getLabelsRequest(order_id);
    }
  };

  handleCloseSheet = () => {
    this.setState({colissimo_service: false});
    this.setState({colissimo_international_offer: false});
    this.setState({sheetActive: false});
    this.setState({global_lines_weight: 0});
    this.setState({selectedChange: false});
    this.setState({selectedChangeOffer: false});
    this.setState({selectedOrder: false});
    this.setState({selectedLabels: false});
    this.setState({filterSide: false});
  };

  handleToggleSheet = (order_id, action_type = "order") => {
    this.state.sheetActive ? this.handleCloseSheet() : this.handleOpenSheet(order_id, action_type);
  }

  toggleActive = (field) => {
    this.setState({ [field]: !this.state[field] });
  }

  toggleTag = (type, value) => {
    if (type === 'labelStatus' || type === 'date' || type === 'printedStatus') {
      if (value != 'custom') {
        this.setState({ [type]: [value] }, () => 
          this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit)
        );
      } else {
        this.setState({ date_to: null });
        this.setState({ date_from: null });
        this.setState({ [type]: [value] });
      }
    } else if (type === 'weight') {
      this.setState({ [type]: value }, () => 
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit)
      );
    } else {
      if (this.state[type].some((tag) => tag === value)) {
        this.setState({
          [type]: this.state[type].filter(item => item !== value)
        }, () => 
          this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit) 
        );
      } else {
        this.setState({
          [type]: [...this.state[type], value]
        }, () => 
          this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit) 
        );
      }
    }
  };

  handleChangeWithValue = (field, value) => {
    if (field.indexOf('ftd') !== -1) {
      this.setState({ [field]: value });
      this.toggleActive(field.replace('ftd', 'activeddp'));
    } else {
      this.setState({ [field]: value });
    }
  }

  handleChangeAddress = (e) => {
    this.setState({
      orderShipping: { ...this.state.orderShipping, [e.target.name]: e.target.value },
    });
  }

  handleChangeImportOrders = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  handleChangeQueryValue = (e) => {
    this.setState({[e.target.name]: e.target.value}, () => {
      this.callRequest('/orders-filters?page=1&limit='+this.state.page_limit);
    });
    
  }

  handleChangeDpd = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  handleChangeAddressPickup = (e) => {
    this.setState({
      orderShippingPickup: { ...this.state.orderShippingPickup, [e.target.name]: e.target.value },
    });
  }

  handleChange = (field) => {
    
    const includeFilters = [
      'queryValue',
      'weight',
      'date',
      'date_from',
      'date_to',
      'countries',
      'services',
    ];

    return (value) => {
      console.log(value);
      if (field != 'map_modal') {
        this.setState({ page: 1 });
      }

      if (field === 'map_modal') {
        this.setState({ map_modal: false });
        jQuery('#colissimo-widget-container').frameColissimoClose();
        return true;
      }

      this.setState({ [field]: value }, () => {
        if (includeFilters.indexOf(field) !== -1) {       
          if (Array.isArray(value)) {
            if (value[0] == "custom") {
              return true;
            }
          }
          this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
        };
      });
    };
  };

  changeAddress = (shipping) => {
    this.setState({selectedChange: false});
    this.setState({selectedChangeOffer: false});
    this.setState({selectedOrder: false});
    this.setState({selectedLabels: false});
    this.setState({modalChangeAddress: true});
    this.setState({orderShipping: shipping});
  }

  changeAddressPickup = (shipping) => {
    this.setState({selectedChange: false});
    this.setState({selectedChangeOffer: false});
    this.setState({selectedOrder: false});
    this.setState({selectedLabels: false});
    this.setState({modalChangeAddressPickup: true});
    this.setState({orderShippingPickup: shipping});
  }

  handleSelectionChange = (selectedItems) => {
    this.setState({selectedItems});
  };

  changeService = (order_id, shipping, product_code) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    if (!this.state.colissimo_service) {
      product_code = product_code
    } else {
      product_code = this.state.colissimo_service;
    }

    if (product_code == 'PICKUP') {
      this.loadMap(order_id, shipping);
    } else {
      /* We save new service */
      this.setState({map_modal: false});
      this.setState({loading: true});
      axios({
        method: 'post',
        url: '/save-backend-service',
        data: {
          orderId: order_id,
          productCode: product_code,
          instance_id: this.props.shop.instance_id
        }
      }).then(result => {
        this.setState({loading: false});
        if (result.data.success == true) {
          this.props.toggleToast(I18n.t('order_list.success_update_change_service'), false);
        } else {
          this.props.toggleToast(I18n.t('order_list.error_update_change_service'), true);
        }

        this.setState({selectedChange: false});
        this.setState({selectedChangeOffer: false});
        this.setState({colissimo_service: false});
        this.setState({colissimo_international_offer: false})
        this.setState({order_id_update_service: false});
        this.setState({map_modal: false});
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      }).catch(error => {
        console.log(error);
        this.setState({loading: false});
        this.props.toggleToast(I18n.t('order_list.error_update_change_service'), true);
        this.setState({selectedChange: false});
        this.setState({selectedChangeOffer: false});
        this.setState({colissimo_service: false});
        this.setState({colissimo_international_offer: false})
        this.setState({order_id_update_service: false});
        this.setState({map_modal: false});
      });
    }
  }

  changeOffer = (order_id, colissimo_international_offer) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    if (!this.state.colissimo_international_offer) {
      colissimo_international_offer = colissimo_international_offer
    } else {
      colissimo_international_offer = this.state.colissimo_international_offer;
    }

    axios({
      method: 'post',
      url: '/save-backend-offer',
      data: {
        orderId: order_id,
        colissimoInternationalOffer: colissimo_international_offer,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.props.toggleToast(I18n.t('order_list.success_update_change_service'), false);
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      } else {
        this.props.toggleToast(I18n.t('order_list.error_update_change_service'), true);
      }
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({colissimo_service: false});
      this.setState({colissimo_international_offer: false})
      this.setState({order_id_update_service: false});
      this.setState({map_modal: false});
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_update_change_service'), true);
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({colissimo_service: false});
      this.setState({colissimo_international_offer: false})
      this.setState({order_id_update_service: false});
      this.setState({map_modal: false});
    });
  }


  loadMap = (order_id, shipping) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    if (shipping.country_code == 'MC') {
      var countryList = 'FR';
    } else {
      var countryList = shipping.country_code;
    }

    if (this.props.token_api !== false) {
      var preparation_time = this.props.config ? this.props.config.order_preparation_time : 2;
      var token_api = this.props.token_api;
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({selectedOrder: false});
      this.setState({selectedLabels: false});
      this.setState({colissimo_service: false});
      this.setState({colissimo_international_offer: false});
      this.setState({order_id_update_service: order_id});
      this.setState({map_modal: true}, () => {
        if (typeof $ === 'undefined') {
            $ = jQuery;
        }
        //window.setTimeout(function(){
        setTimeout(() => {
          $("#colissimo-widget-container").frameColissimoOpen({
            "ceLang": shipping.country_code,
            "callBackFrame": "callBackFrame",
            "URLColissimo": "https://ws.colissimo.fr",
            "ceCountryList": countryList,
            "ceCountry": countryList,
            "dyPreparationTime": preparation_time,
            "ceAddress": shipping.address1,
            "ceZipCode": shipping.zip,
            "ceTown": shipping.city,
            "token": token_api
          });
        }, 300);

        setTimeout(function(){colissimo_widget_map.resize(); }, 500);
      });
          
      console.log('Colissimo APP : Map loaded');   
    } else {
      this.props.toggleToast(I18n.t('order_list.error_token_api'), true);
    }
  }

  handleValidPickup = () => {
    this.setState({ map_modal: false });
    jQuery('#colissimo-widget-container').frameColissimoClose();
    this.savePickupPoint();
  }

  savePickupPoint = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    var infoPointValue = JSON.parse(this.orderUpdateWebservice.value);
    axios({
      method: 'post',
      url: '/save-backend-pickup-point',
      data: {
        orderId: this.state.order_id_update_service,
        infoPoint: infoPointValue.infoPoint,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.props.toggleToast(I18n.t('order_list.success_update_change_service'), false);
      } else {
        this.props.toggleToast(I18n.t('order_list.error_update_change_service'), true);
      }
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({colissimo_service: false});
      this.setState({colissimo_international_offer: false})
      this.setState({order_id_update_service: false});
      this.setState({map_modal: false});
      this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_update_change_service'), true);
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({colissimo_service: false});
      this.setState({colissimo_international_offer: false})
      this.setState({order_id_update_service: false});
      this.setState({map_modal: false});
    });
  }

  handleSubmit = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});

    axios({
      method: 'post',
      url: '/modify-address',
      data: {
        shipping: this.state.orderShipping,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.setState({modalChangeAddress: false});
        this.props.toggleToast(I18n.t('order_list.success_update_modify_address'), false);
        this.handleToggleSheet(this.state.orderShipping.order_id, 'change');
      } else {
        this.props.toggleToast(I18n.t('order_list.error_update_modify_address'), true);
      }
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_update_modify_address'), true);
    });

  }

  handleSubmitPickup = (event) => {
    event.preventDefault();
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});

    axios({
      method: 'post',
      url: '/modify-address-pickup',
      data: {
        shipping: this.state.orderShippingPickup,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.setState({modalChangeAddressPickup: false});
        this.props.toggleToast(I18n.t('order_list.success_update_modify_address'), false);
        this.handleToggleSheet(this.state.orderShippingPickup.order_id, 'change');
      } else {
        this.props.toggleToast(I18n.t('order_list.error_update_modify_address'), true);
      }
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_update_modify_address'), true);
    });
  }

  handleChangeDdp = (order_id) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }

    this.setState({loading: true});
    if (typeof $ === 'undefined') {
        $ = jQuery;
    }

    /* We verify if weight is set */
    axios({
      method: 'post',
      url: '/update-ddp-value',
      data: {
        order_id: order_id,
        height: $("#height"+order_id).val(),
        width: $("#width"+order_id).val(),
        length: $("#length"+order_id).val(),
        description: $("#description"+order_id).val(),
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.props.toggleToast(I18n.t('order_list.update_ddp_success'), false);
        this.toggleActive(`activeddp${order_id}`);
      } else {
        this.props.toggleToast(I18n.t('order_list.update_ddp_error'), true);
      }
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.update_ddp_error'), true);
    });
  };

  handleImportOrders = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});

    axios({
      method: 'post',
      url: '/import-orders',
      data: {
        orders: this.state.import_orders,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.setState({modalImport: false});
        this.setState({import_orders: null});
        this.props.toggleToast(I18n.t('order_list.modal.success_import_orders'), false);
        this.callRequest('/orders-filters');
      } else {
        this.setState({modalImport: false});
        this.props.toggleToast(I18n.t('order_list.modal.error_import_orders'), true);
      }
    }).catch(error => {
      console.log(error);
      this.setState({modalImport: false});
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.modal.error_import_orders'), true);
    });
  }    

  getChangeOrderRequest = (order_id) => {
    
    this.setState({loading: true});
    axios({
      method: 'post',
      url: '/get-order',
      data: {
        order_id: order_id,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({selectedLabels: false});
      this.setState({selectedChange: result.data});
      this.setState({selectedChangeOffer: false});
      this.setState({selectedOrder: false});
      this.setState({loading: false});
      this.setState({sheetActive: true});
    }).catch(error => {
      console.log(error);
      this.setState({selectedLabels: false});
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({selectedOrder: false});
      this.setState({loading: false});
    });
  }

  getChangeOfferOrderRequest = (order_id) => {
    
    this.setState({loading: true});
    axios({
      method: 'post',
      url: '/get-order',
      data: {
        order_id: order_id,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({selectedLabels: false});
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: result.data});
      this.setState({selectedOrder: false});
      this.setState({loading: false});
      this.setState({sheetActive: true});
    }).catch(error => {
      console.log(error);
      this.setState({selectedLabels: false});
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({selectedOrder: false});
      this.setState({loading: false});
    });
  }

  /* Get order detail */
  getOrderRequest = (order_id) => {
    this.setState({loading: true});
    axios({
      method: 'post',
      url: '/get-order',
      data: {
        order_id: order_id,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({selectedLabels: false});
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({selectedOrder: result.data});
      this.setState({loading: false});
      this.setState({sheetActive: true});
    }).catch(error => {
      console.log(error);
      this.setState({selectedLabels: false});
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({selectedOrder: false});
      this.setState({loading: false});
    });
  }

  /* Get order detail */
  getLabelsRequest = (order_id) => {
    this.setState({loading: true});
    axios({
      method: 'post',
      url: '/get-labels',
      data: {
        order_id: order_id,
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({selectedOrder: false});
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({selectedLabels: result.data});
      this.setState({loading: false});
      this.setState({sheetActive: true});
    }).catch(error => {
      console.log(error);
      this.setState({selectedOrder: false});
      this.setState({selectedLabels: false});
      this.setState({selectedChange: false});
      this.setState({selectedChangeOffer: false});
      this.setState({loading: false});
    });
  }

  /* generate Label */
  generateLabel = (order_id, partial = false, label_id = false, regenerate = false) => {
    this.reinitVars();
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});
    if (typeof $ === 'undefined') {
      $ = jQuery;
    }

    /* We verify if weight is set */
    if (
        (parseInt($("#weight"+order_id).val(), 10) <= 0 || isNaN(parseInt($("#weight"+order_id).val(), 10)))
    ) {
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_weight'), true);
    } else {
      var weight = parseInt($("#weight"+order_id).val(), 10);
      if (regenerate === true) {
        var returnValue = false;
      } else {
        var returnValue = $("#return"+order_id).is(":checked");
      }
      axios({
        method: 'post',
        url: '/generate-label',
        data: {
          order_id: order_id,
          weight: weight,
          insurance: $("#insurance"+order_id).is(":checked"),
          is_return: returnValue,
          non_machinable: $("#machinable"+order_id).is(":checked"),
          ftd: $("#ftd"+order_id).is(":checked"),
          partial: partial,
          label_id: label_id,
          instance_id: this.props.shop.instance_id
        }
      }).then(result => {
        this.setState({loading: false});
        if (result.data.success == true) {
          this.props.toggleToast(result.data.response_ws.message, false);
          if (this.props.config.use_thermal_printer == true && this.props.config.generate_and_print == true) {
            this.printLabelByOrder(order_id);
          }
        } else {
          this.props.toggleToast(result.data.response_ws.message, true);
        }
        if (!regenerate) {
          this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
        } else {
          this.getLabelsRequest(order_id);
        }
      }).catch(error => {
        console.log(error);
        this.setState({loading: false});
        this.props.toggleToast(I18n.t('order_list.error_generate_label'), true);
      });
    }
  };

  generate_partial = (order_id) => {
    this.setState({loading: true});
    if (typeof $ === 'undefined') {
      $ = jQuery;
    }
    if (
      (parseInt($("#global_lines_weight"+order_id).val(), 10) <= 0 || isNaN(parseInt($("#global_lines_weight"+order_id).val(), 10)))
    ) {
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_weight'), true);
    } else {
      var weight = parseInt($("#global_lines_weight"+order_id).val(), 10);
      /* We retrieve each fulfillabel quantity */
      var items = $( "input[name^='fulfillable[" + order_id + "]" ).serializeArray();
      var itemsFulfilled = {};

      $(items).each(function(i, field){
        if (parseInt(field.value, 10) > 0) {
          var product_id = field.name.match(/\[(.*?)\]/ig);
          product_id = product_id[1].replace(/[[\]]/g,'');
          // var productContent = [];
          // productContent['item_id'] = product_id;
          // productContent['qty_fulfilled'] = field.value;
          // itemsFulfilled.push(productContent);
          itemsFulfilled[product_id] = parseInt(field.value, 10);
        }
      });

      if (itemsFulfilled <= 0) {
        this.setState({loading: false});
        this.props.toggleToast(I18n.t('order_list.error_partial_quantity'), true);
      } else {
        axios({
          method: 'post',
          url: '/generate-label-partial',
          data: {
            order_id: order_id,
            weight: parseInt($("#global_lines_weight"+order_id).val(), 10),
            insurance: $("#insurance"+order_id).is(":checked"),
            is_return: $("#return"+order_id).is(":checked"),
            non_machinable: $("#machinable"+order_id).is(":checked"),
            ftd: $("#ftd"+order_id).is(":checked"),
            partial: true,
            label_id: false,
            item_fulfilled: itemsFulfilled,
            instance_id: this.props.shop.instance_id
          }
        }).then(result => {
          this.setState({loading: false});
          if (result.data.success == true) {
            this.props.toggleToast(result.data.response_ws.message, false);
            this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
            this.handleToggleSheet(order_id, 'labels');
          } else {
            this.props.toggleToast(result.data.response_ws.message, true);
            this.handleToggleSheet(order_id, 'labels');
          }
        }).catch(error => {
          console.log(error);
          this.props.toggleToast(I18n.t('order_list.error_generate_label'), true);
          this.setState({loading: false});
          this.handleToggleSheet(order_id, 'labels');
        });
      }
    }
  };

  generateBulkLabel = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});
    var error_weight = false;
    const items = this.state.selectedItems;
    this.setState({modalBulkGenerate: false});
    if (typeof $ === 'undefined') {
      $ = jQuery;
    }
    items.forEach((item) => {
      /* We verify if all weight are set */
      if (
        (parseInt($("#weight"+item).val(), 10) <= 0 || isNaN(parseInt($("#weight"+item).val(), 10)))
      ) {
        error_weight = true;
      }
    });

    if (error_weight === false) {
      /* We generate label in mass */
      var error_content = '';
      /*var totalLength = items.length;
      var i = 1;
      var percent = 0;*/
      Promise.all(items.map(item => {
        axios({
          method: 'post',
          url: '/generate-label',
          data: {
            order_id: item,
            weight: parseInt($("#weight"+item).val(), 10),
            insurance: $("#insurance"+item).is(":checked"),
            is_return: $("#return"+item).is(":checked"),
            non_machinable: $("#machinable"+item).is(":checked"),
            ftd: $("#ftd"+item).is(":checked"),
            partial: false,
            label_id: false,
            bulk_action: true,
            instance_id: this.props.shop.instance_id
          }
          }).then(result => {
            if (result.data.success == true) {
              /* Do nothing */
              if (this.props.config.use_thermal_printer == true && this.props.config.generate_and_print == true) {
                this.printLabelByOrder(item);
              }
            } else {
              error_content += result.data.response_ws.message;
            }
        }).catch(error => {
          error_content += I18n.t('order_list.error_generate_label');
          console.log(error);
        });
      })).then(() => {
        if (error_content != '') {
          this.props.toggleToast(error_content, true);
        } else {
          this.props.toggleToast(I18n.t('order_list.success_bulk'), false);
        }
        this.setState({loading: false}, () => {
          console.log('call orders-filters here');
          this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit, items);
        });
      });   
    } else {
      /* If there is Weight error */
      this.setState({loading: false});
      this.props.toggleToast(I18n.t('order_list.error_weight_bulk'), true);
      this.setState({selectedItems : items});
    }
  };

  generateLabelReturn = (order_id, partial = false, label_id = false, regenerate = false) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});
    if (typeof $ === 'undefined') {
      $ = jQuery;
    }
    /* We verify if weight is set */
    if (
        (parseInt($("#weight"+order_id).val(), 10) <= 0 || isNaN(parseInt($("#weight"+order_id).val(), 10)))
    ) {
        this.setState({loading: false});
        this.props.toggleToast(I18n.t('order_list.error_weight'), true);
    } else {
        var weight = parseInt($("#weight"+order_id).val(), 10);
        axios({
            method: 'post',
            url: '/generate-label-return',
            data: {
                order_id: order_id,
                weight: weight,
                insurance: this.state["insurance"+order_id],
                is_return: this.state["return"+order_id],
                non_machinable: this.state["machinable"+order_id],
                ftd: this.state["ftd"+order_id],
                partial: partial,
                label_id: label_id,
                instance_id: this.props.shop.instance_id
            }
        }).then(result => {
            this.setState({loading: false});
            if (result.data.success == true) {
              this.props.toggleToast(result.data.response_ws.message, false);
            } else {
              this.props.toggleToast(result.data.response_ws.message, true);
            }
            if (!regenerate) {
                this.getLabelsRequest(order_id);
            } else {
                this.getLabelsRequest(order_id);
            }
        }).catch(error => {
            console.log(error);
            this.setState({loading: false});
            this.props.toggleToast(I18n.t('order_list.error_generate_label'), true);
        });
    }
  };

  printLabel = (id) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    axios({
      method: 'get',
      url: '/colissimo-label/'+id+'/'+this.props.shop.instance_id
    }).then(result => {
      if (result.data.printer_request) {
          $.get(result.data.printer_request);
      } else {
        this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
      }
    }).catch(error => {
      console.log(error);
      this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
    });
  };

  printLabelByOrder = (id) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    axios({
      method: 'get',
      url: '/colissimo-label-by-order/'+id+'/'+this.props.shop.instance_id
    }).then(result => {
      if (result.data.printer_request) {
          $.get(result.data.printer_request);
      } else {
        this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
      }
    }).catch(error => {
      console.log(error);
      this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
    });
  };

  regenerateLabel = (order_id, label_id) => {
    this.setState({regenerate_current_order_id: order_id});
    this.setState({regenerate_current_label_id: label_id});
    this.setState({modalRegenerate: true});
  };

  regenerateLabelReturn = (order_id, label_id) => {
    this.setState({regenerate_current_order_id: order_id});
    this.setState({regenerate_current_label_id: label_id});
    this.setState({modalRegenerateReturn: true});
    this.props.returnOrderListActiveModal(order_id, label_id, true);
  };

  generateBulkLabelCheckModal = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    var error_weight = false;
    const items = this.state.selectedItems;
    if (typeof $ === 'undefined') {
        $ = jQuery;
    }
    items.forEach((item) => {
      /* We verify if all weight are set */
      if (
        (parseInt($("#weight"+item).val(), 10) <= 0 || isNaN(parseInt($("#weight"+item).val(), 10)))
      ) {
        error_weight = true;
      }
    });

    if (error_weight === false) {
      this.setState({modalBulkGenerate: true});
    } else {
        /* If there is Weight error */
        this.setState({loading: false});
        this.props.toggleToast(I18n.t('order_list.error_weight_bulk'), true);
    }
  }

  printBulkLabel = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    const items = (this.state.sortValue == 'DESC' ? this.state.selectedItems.sort().reverse() : this.state.selectedItems.sort());

    /* We verify if labels generate */
    /* We verify if all labels generate */
    axios({
        method: 'post',
        url: '/verify-label-exists',
        data: {
          order_ids: items,
          instance_id: this.props.shop.instance_id
        }
    }).then(result => {
        if (result.data.success == true) {
            if (this.props.config.use_thermal_printer == true) {
                items.map((item, index) => {
                    setTimeout(() => { 
                        axios({
                            method: 'get',
                            url: '/colissimo-print-bulk-label/'+item+'/'+this.props.shop.instance_id
                        }).then(result => {
                            if (result.data.printer_request) {
                                $.get(result.data.printer_request);
                            } else {
                              this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
                            }
                        }).catch(error => {
                            console.log(error);
                            this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
                        });
                    }, 1000*index);
                });

            } else {
                window.open('/colissimo-print-bulk-label/'+items+'/'+this.props.shop.instance_id, '_blank');
            }
        } else {
          this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
        }
    }).catch(error => {
        console.log(error);
        this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
    });
  };

  printBulkReturnLabel = () => {
    const items = this.state.selectedItems;
    window.open('/colissimo-print-bulk-return-label/'+items+'/'+this.props.shop.instance_id,'_blank');
  };

  handleRemove = (key) => {
    if (key === 'date_to' || key === 'date_from') {
      this.setState({[key]: null}, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    } else {
      this.setState({[key]: []}, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }
  };

  handleClear = (type) => {
    if (type === 'date') {
      this.setState({
        [type]: [],
        date_to: null,
        date_from: null,
      }, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    } else {
      this.setState({
        [type]: [],
      }, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }
  }

  handleChangePage = (value) => {
    this.setState({
      page: value,
    }, () => {
      this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
    });
  }

  handleClearAll = () => {
    this.setState({
      paymentStatus: [],
      labelStatus: [],
      closedStatus: null,
      errorStatus: [],
      printedStatus: [],
      queryValue: null,
      fulfillmentStatus: [],
      weight: null,
      date: [],
      date_to: null,
      date_from: null,
      countries: [],
      services: [],
      selectedTabConfig: 0,
    }, () => {
      this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
    });
  };

  /* Filter order */
  callRequest = (url, items = false) => {
    this.setState({selectedItems : []});
    /*$('html, body').animate({scrollTop: 0}, 10);*/
    this.setState({loading: true});
    console.log(this.state.queryValue);
    axios.get(url, {
      params: {
        queryValue: this.state.queryValue,
        paymentStatus: this.state.paymentStatus,
        printedStatus: this.state.printedStatus,
        fulfillmentStatus: this.state.fulfillmentStatus,
        labelStatus: this.state.labelStatus,
        closedStatus: this.state.closedStatus,
        errorStatus: this.state.errorStatus,
        weight: this.state.weight,
        date: this.state.date,
        date_from: this.state.date_from,
        date_to: this.state.date_to,
        countries: this.state.countries,
        services: this.state.services,
        sortable: this.state.sortValue,
        instance_id: this.props.shop.instance_id
      },
    }).then(result => {
      this.setState({orders: result.data.orders});
      this.setState({page: result.data.page});
      this.setState({page_limit: result.data.page_limit});
      this.setState({total_page: result.data.total_page});
      this.setState({count_orders: result.data.count_orders});
      this.setState({loading: false});
      if (items !== false) {
        this.setState({selectedItems : items});
      }
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
    });
  }

  handleTabChange = (selectedTabIndex) => {
    this.setState({ selectedTabConfig: selectedTabIndex.id });
    this.setState({
      paymentStatus: [],
      printedStatus: [],
      labelStatus: [],
      closedStatus: null,
      queryValue: null,
      fulfillmentStatus: [],
      weight: null,
      date: [],
      date_to: null,
      date_from: null,
      countries: [],
      services: [],
      errorStatus: [],
    }, () => {
      if (selectedTabIndex.id == 0) {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      }
    });

    if (selectedTabIndex.id == 1) {
      this.setState({ ['labelStatus']: ['unavailable'] }, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }

    if (selectedTabIndex.id == 2) {
      this.setState({ ['labelStatus']: ['available'] }, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }

    if (selectedTabIndex.id == 3) {
      this.setState({ ['fulfillmentStatus']: ['NOT_FULFILLED', 'PARTIALLY_FULFILLED'] }, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }

    if (selectedTabIndex.id == 4) {
      this.setState({ ['errorStatus']: ['error'] }, () => {
        this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
      });
    }
  };


  renderSheetChange = (order) => {
    const sheetActive = this.state.sheetActive;
    const { 
      id,
      wix_order_number,
      payment_status,
    } = order.order

    const {
      address1,
      address2,
      city,
      company,
      country,
      first_name,
      last_name,
      phone,
      country_code,
      zip
    } = order.order_shipping

    const pickup_point = order.pickup_point

    //console.log(order.order_shipping_line[0]);
    const order_shipping_line = (order.order_shipping_line[0] ? order.order_shipping_line[0] : false);
    
    const product_code = order.product_code

    /* We check vars */
    /* If pickup point available for this country */
    const pickup_point_available = this.props.pickup_point_available.indexOf(country_code) === -1 ? false : true;
    /* We check if sign in Country */
    const without_sign_country = this.props.without_sign_country.indexOf(country_code) === -1 ? false : true;
    const code_sign = this.props.iso_outre_mer.indexOf(country_code) === -1 && this.props.iso_outre_mer2.indexOf(country_code) === -1 ? 'DOS' : 'CDS';
    
    const colis_eco = this.props.iso_outre_mer.indexOf(country_code) === -1 && this.props.iso_outre_mer2.indexOf(country_code) === -1 ? false : true;
    /* We check if it's Colissimo international */
    const colissimo_international = this.props.iso_no_worldwide.indexOf(country_code) === -1 ? true : false;
    
    let myOptions = [];

    if (!colissimo_international && without_sign_country && pickup_point_available) {
      myOptions.push({label: I18n.t('order_list.sheet.with_sign'),value: code_sign});
      myOptions.push({label: I18n.t('order_list.sheet.without_sign'),value: 'DOM'});
      myOptions.push({label: I18n.t('order_list.sheet.with_pickup'),value: 'PICKUP'});
    }

    if (!colissimo_international && without_sign_country && !pickup_point_available) {
      myOptions.push({label: I18n.t('order_list.sheet.with_sign'),value: code_sign});
      myOptions.push({label: I18n.t('order_list.sheet.without_sign'),value: 'DOM'});
    }

    if (!colissimo_international && !without_sign_country && pickup_point_available) {
      myOptions.push({label: I18n.t('order_list.sheet.with_sign'),value: code_sign});
      myOptions.push({label: I18n.t('order_list.sheet.with_pickup'),value: 'PICKUP'});
    }

    if (!colissimo_international && !without_sign_country && !pickup_point_available) {
      myOptions.push({label: I18n.t('order_list.sheet.with_sign'),value: code_sign});
    }

    if (colissimo_international) {
      myOptions.push({label: I18n.t('order_list.sheet.colissimo_international'),value: 'COLI'});
    }
    
    if (colis_eco != '') {
      myOptions.push({label: I18n.t('order_list.sheet.colissimo_eco'),value: 'ECO'});
    }

    const renderCheckboxes = myOptions.map((item) => {
        console.log(item);
        var checked = false;
        if (!this.state.colissimo_service && pickup_point !== false) {
          if (item.value == 'PICKUP') {
            checked = true;
          }
        } else {
          if (this.state.colissimo_service  && this.state.colissimo_service == item.value) {
            checked = true;
          } else if (!this.state.colissimo_service) {
            if (product_code == item.value) {
              checked = true;
            }
          } 
        } 
        return (
          <Radio name="colissimo_service" label={item.label} value={item.value} checked={checked} onChange={() => this.handleChangeWithValue("colissimo_service", item.value)} />
        );
      });

    var payment_status_badge = '';

    if (payment_status == 'PARTIALLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="general" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="success" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'FULLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="neutral" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PENDING') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="warning" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'NOT_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="danger" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PARTIALLY_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="urgent" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'UNSPECIFIED_PAYMENT_STATUS') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="standard" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: `${sheetActive ? 0 : -440}px`,
          height: '100%',
          boxShadow:
            '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
          transition: 'right 0.4s ease 0s',
          zIndex: 2000,
        }}
      >
        <SidePanel title="Filter" onCloseButtonClick={this.handleCloseSheet}>
          <SidePanel.Header title={payment_status_badge} showDivider={true} />
          <SidePanel.Content>
            <Box direction="vertical" gap="18px">
              <Heading size="small">{I18n.t('order_list.sheet.carrier')}</Heading>
              <Text size="small" secondary>{order_shipping_line && order_shipping_line.delivery_option}</Text>
              <Divider />
              <Heading size="small">{I18n.t('order_list.sheet.address')}</Heading>
              {pickup_point === false &&
                <Text size="small">
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {company && <div>{company}</div>}
                  {address1 && <div>{address1}</div>}
                  {address2 && <div>{address2}</div>}
                  {zip && <div>{zip} {city}</div>} 
                  {country && <div>{country}</div>}
                  {phone && <div>{phone}</div>}
                  <TextButton size="tiny" onClick={(e) => this.changeAddress(order.order_shipping)}>{I18n.t('order_list.change')}</TextButton>
                </Text>
              }
              {pickup_point !== false && 
                <Text size="small">
                  <div><b>{I18n.t('order_list.pickup_point_number')} {pickup_point.colissimo_id}</b></div>
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {pickup_point.company_name && <div>{pickup_point.company_name}</div>}
                  {pickup_point.address1 && <div>{pickup_point.address1}</div>}
                  {pickup_point.address2 && <div>{pickup_point.address2}</div>}
                  {pickup_point.zipcode && <div>{pickup_point.zipcode} {pickup_point.city}</div>} 
                  {pickup_point.country && <div>{pickup_point.country}</div>}
                  {pickup_point.phone && <div>{pickup_point.phone}</div>}
                  <TextButton size="tiny" onClick={(e) => this.changeAddressPickup(pickup_point)}>{I18n.t('order_list.change_pickup_info')}</TextButton>
                  <TextButton size="tiny" onClick={(e) => this.changeService(id, order.order_shipping, 'PICKUP')}>{I18n.t('order_list.change_pickup')}</TextButton>
                </Text>
              }
              <Divider />
              <Heading size="small">{I18n.t('order_list.sheet.change_service')}</Heading>
              <Text size="small">{I18n.t('order_list.sheet.choose_service')}</Text>
              <div>
                {renderCheckboxes}
                <input type="hidden" id="order-update-webservice" value={id} />
              </div>
              {this.state.colissimo_service && 
                <Button size="tiny" primary={true} onClick={(e) => this.changeService(id, order.order_shipping, (this.state.colissimo_service ? this.state.colissimo_service : (product_code ? product_code : '')))}>{I18n.t('order_list.sheet.save_service')}</Button>
              }
            </Box>
          </SidePanel.Content>
        </SidePanel>
      </div>
    );
  };

  renderSheetChangeOffer = (order) => {
    const sheetActive = this.state.sheetActive;
    const { 
      id,
      wix_order_number,
      payment_status,
    } = order.order

    const {
      address1,
      address2,
      city,
      company,
      country,
      first_name,
      last_name,
      phone,
      country_code,
      zip
    } = order.order_shipping

    const pickup_point = order.pickup_point

    var payment_status_badge = '';

    if (payment_status == 'PARTIALLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="general" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="success" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'FULLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="neutral" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PENDING') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="warning" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'NOT_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="danger" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PARTIALLY_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="urgent" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'UNSPECIFIED_PAYMENT_STATUS') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="standard" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    }

    //console.log(order.order_shipping_line[0]);
    const order_shipping_line = (order.order_shipping_line[0] ? order.order_shipping_line[0] : false);

    const code_offer = (order.code_offer == '0' ? 'DPD' : 'LOCAL');

    const img_dpd = <img src={dpd} width="40px" />

    switch (country_code) {
        case 'DE':
            var icon_offer = <img src={germany} width="80px" />;
            break;
        case 'AT':
            var icon_offer = <img src={austria} width="80px" />;
            break;
        case 'IT':
            var icon_offer = <img src={italy} width="80px" />;
            break;
        case 'LU':
            var icon_offer = <img src={luxembourg} width="27px" />;
            break;
        default:
            var icon_offer = <img src={dpd} width="40px" />;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: `${sheetActive ? 0 : -440}px`,
          height: '100%',
          boxShadow:
            '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
          transition: 'right 0.4s ease 0s',
          zIndex: 2000,
        }}
      >
        <SidePanel title="Filter" onCloseButtonClick={this.handleCloseSheet}>
          <SidePanel.Header title={payment_status_badge} showDivider={true} />
          <SidePanel.Content>
            <Box direction="vertical" gap="18px">
              <Heading size="small">{I18n.t('order_list.sheet.carrier')}</Heading>
              <Text size="small" secondary>{order_shipping_line && order_shipping_line.delivery_option}</Text>
              <Divider />
              <Heading size="small">{I18n.t('order_list.sheet.address')}</Heading>
              {pickup_point === false &&
                <Text size="small">
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {company && <div>{company}</div>}
                  {address1 && <div>{address1}</div>}
                  {address2 && <div>{address2}</div>}
                  {zip && <div>{zip} {city}</div>} 
                  {country && <div>{country}</div>}
                  {phone && <div>{phone}</div>}
                  <TextButton size="tiny" onClick={(e) => this.changeAddress(order.order_shipping)}>{I18n.t('order_list.change')}</TextButton>
                </Text>
              }
              {pickup_point !== false && 
                <Text size="small">
                  <div><b>{I18n.t('order_list.pickup_point_number')} {pickup_point.colissimo_id}</b></div>
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {pickup_point.company_name && <div>{pickup_point.company_name}</div>}
                  {pickup_point.address1 && <div>{pickup_point.address1}</div>}
                  {pickup_point.address2 && <div>{pickup_point.address2}</div>}
                  {pickup_point.zipcode && <div>{pickup_point.zipcode} {pickup_point.city}</div>} 
                  {pickup_point.country && <div>{pickup_point.country}</div>}
                  {pickup_point.phone && <div>{pickup_point.phone}</div>}
                  <TextButton size="tiny" onClick={(e) => this.changeAddressPickup(pickup_point)}>{I18n.t('order_list.change_pickup_info')}</TextButton>
                  <TextButton size="tiny" onClick={(e) => this.changeService(id, order.order_shipping, 'PICKUP')}>{I18n.t('order_list.change_pickup')}</TextButton>
                </Text>
              }
              <Divider />
              <Heading size="small">{I18n.t('order_list.sheet.change_service')}</Heading>
              <Text size="small">{I18n.t('order_list.sheet.choose_service')}</Text>
              <div>
                <Radio name="colissimo_service" label={img_dpd} value='DPD' checked={!this.state.colissimo_international_offer ? (code_offer ? (code_offer == 'DPD' ? true : false) : true) : (this.state.colissimo_international_offer == 'DPD' ? true :false)} onChange={() => this.handleChangeWithValue("colissimo_international_offer", 'DPD')} />
                <Radio name="colissimo_service" label={icon_offer} value='LOCAL' checked={!this.state.colissimo_international_offer ? (code_offer ? (code_offer == 'LOCAL' ? true : false) : false) : (this.state.colissimo_international_offer == 'LOCAL' ? true :false)} onChange={() => this.handleChangeWithValue("colissimo_international_offer", 'LOCAL')} />
                <input type="hidden" id="order-update-webservice" value={id} />
              </div>
              {this.state.colissimo_international_offer && 
                <Button size="tiny" primary={true} onClick={(e) => this.changeOffer(id, (this.state.colissimo_international_offer ? this.state.colissimo_international_offer : (code_offer ? code_offer : 'DPD')))}>{I18n.t('order_list.sheet.save_service')}</Button>
              }
            </Box>
          </SidePanel.Content>
        </SidePanel>
      </div>
    );
  };

  /* RENDER ORDER DETAIL */
  renderSheet = (order) => {
    const sheetActive = this.state.sheetActive;
    const { 
      id,
      wix_order_number,
      payment_status,
      total_price,
      currency
    } = order.order

    const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: currency
    });

    const {
      address1,
      address2,
      city,
      company,
      country,
      first_name,
      last_name,
      phone,
      zip
    } = order.order_shipping

    const order_lines = order.order_line_items

    const pickup_point = order.pickup_point

    const order_shipping_line = (order.order_shipping_line[0] ? order.order_shipping_line[0] : false);

    var payment_status_badge = '';

    if (payment_status == 'PARTIALLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="general" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="success" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'FULLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="neutral" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PENDING') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="warning" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'NOT_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="danger" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PARTIALLY_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="urgent" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'UNSPECIFIED_PAYMENT_STATUS') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="standard" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    }
    
    let sum = 0;
    const unfulfill_lines = order_lines.filter(line => (line.fulfillable_quantity !== 0 && payment_status != 'FULLY_REFUNDED')).map(line => {
      sum += parseFloat(parseFloat(line.weight * 1000) * parseInt((this.state["fulfillable["+id+"]["+line.id+"]"] ? this.state["fulfillable["+id+"]["+line.id+"]"] : line.fulfillable_quantity)));
      return (
        <Box direction="vertical" padding="SP2">
          <Text weight="bold" size="tiny">{line.name}</Text>
          <Text size="tiny">{formatter.format(`${line.price}`)} x {line.quantity} - {I18n.t('order_list.sheet.weight')} {parseFloat(line.weight * 1000) * parseInt(line.fulfillable_quantity)} {I18n.t('order_list.sheet.grams')}</Text>
          <Text weight="bold" size="tiny">SKU : {line.sku}</Text>
          <Box direction="vertical" paddingTop="SP2" paddingBottom="SP2">
            <Text size="small">{I18n.t('order_list.sheet.qty_to_fulfill')}</Text>
            <NumberInput
              name={`fulfillable[${id}][${line.id}]`}
              value={`${this.state["fulfillable["+id+"]["+line.id+"]"] ? this.state["fulfillable["+id+"]["+line.id+"]"] : line.fulfillable_quantity}`}
              onChange={this.handleChange(`fulfillable[${id}][${line.id}]`)}
              max={line.fulfillable_quantity} />
          </Box>
            <Divider />
        </Box>
        
      );
    });

    const fulfill_lines = order_lines.filter(line => (line.fulfillable_quantity === 0 || (line.quantity - line.fulfillable_quantity) > 0 || payment_status == 'FULLY_REFUNDED')).map(line => {
      return (
      <Box direction="vertical" padding="SP2">
        <Text weight="bold" size="tiny">{line.name}</Text>
        <Text size="tiny">{formatter.format(`${line.price}`)} x {line.quantity}</Text>
        <Text weight="bold" size="tiny">SKU : {line.sku}</Text>
        <Box direction="vertical" paddingTop="SP2">
          <Badge skin="success">{I18n.t('order_list.sheet.qty_fulfilled_refunded')} {line.quantity - line.fulfillable_quantity}</Badge>
        </Box>
        <Box direction="vertical" paddingTop="SP2" paddingBottom="SP2">
          <Divider />
        </Box>
      </Box>
      );
    });

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: `${sheetActive ? 0 : -440}px`,
          height: '100%',
          boxShadow:
            '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
          transition: 'right 0.4s ease 0s',
          zIndex: 2000,
        }}
      >
        <SidePanel title="Filter" onCloseButtonClick={this.handleCloseSheet}>
          <SidePanel.Header title={payment_status_badge} showDivider={true} />
          <SidePanel.Content>
            <Box direction="vertical" gap="18px">
              <Heading size="small">{I18n.t('order_list.sheet.carrier')}</Heading>
              <Text size="small" secondary>{order_shipping_line && order_shipping_line.delivery_option}</Text>
              <Divider />
              <Heading size="small">{I18n.t('order_list.sheet.address')}</Heading>
              {pickup_point === false &&
                <Text size="small">
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {company && <div>{company}</div>}
                  {address1 && <div>{address1}</div>}
                  {address2 && <div>{address2}</div>}
                  {zip && <div>{zip} {city}</div>} 
                  {country && <div>{country}</div>}
                  {phone && <div>{phone}</div>}
                  <TextButton size="tiny" onClick={(e) => this.changeAddress(order.order_shipping)}>{I18n.t('order_list.change')}</TextButton>
                </Text>
              }
              {pickup_point !== false && 
                <Text size="small">
                  <div><b>{I18n.t('order_list.pickup_point_number')} {pickup_point.colissimo_id}</b></div>
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {pickup_point.company_name && <div>{pickup_point.company_name}</div>}
                  {pickup_point.address1 && <div>{pickup_point.address1}</div>}
                  {pickup_point.address2 && <div>{pickup_point.address2}</div>}
                  {pickup_point.zipcode && <div>{pickup_point.zipcode} {pickup_point.city}</div>} 
                  {pickup_point.country && <div>{pickup_point.country}</div>}
                  {pickup_point.phone && <div>{pickup_point.phone}</div>}
                  <TextButton size="tiny" onClick={(e) => this.changeAddressPickup(pickup_point)}>{I18n.t('order_list.change_pickup_info')}</TextButton>
                  <TextButton size="tiny" onClick={(e) => this.changeService(id, order.order_shipping, 'PICKUP')}>{I18n.t('order_list.change_pickup')}</TextButton>
                </Text>
              }
              <Divider />
              {unfulfill_lines.length > 0 &&
                <Box direction="vertical"> 
                  <TableListHeader options={[{ value: I18n.t('order_list.sheet.unfulfill_product') }]} />
                  {unfulfill_lines}
                </Box>}
                {fulfill_lines.length > 0 &&
                  <Box direction="vertical"> 
                    <TableListHeader options={[{ value: I18n.t('order_list.sheet.fulfill_product') }]} />
                    {fulfill_lines}
                  </Box>
                }
                <Box direction="vertical" padding="SP2" backgroundColor="D60" color="D10">
                  <Text weight="bold" size="tiny">{`Total ${formatter.format(total_price)}`}</Text>
                </Box>

                {unfulfill_lines.length > 0 &&
                  <Box direction="vertical">
                    <Divider/>
                    <Box direction="vertical" paddingTop="SP4">
                      <Text size="small">{I18n.t('order_list.sheet.global_weight')}</Text>
                      <NumberInput
                        name={`global_lines_weight${id}`}
                        id={`global_lines_weight${id}`}
                        value={`${this.state["global_lines_weight"+id] ? this.state["global_lines_weight"+id] : sum}`}
                        onChange={this.handleChange(`global_lines_weight${id}`)}
                      />
                      <div>&nbsp;</div>
                      <Button onClick={(e) => this.generate_partial(id)}>
                          {I18n.t('order_list.sheet.generate_label_partial')}
                      </Button>
                    </Box>
                  </Box>
                }
            </Box>
          </SidePanel.Content>
        </SidePanel>
      </div>
    );
  };

  /* RENDER ORDER DETAIL */
  renderSheetLabels = (data) => {
    const sheetActive = this.state.sheetActive;
    const { 
        id,
        wix_order_number,
        payment_status,
        total_weight,
        country_code,
    } = data.order[0]

    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
      timeZone: this.props.shop.time_zone
    };

    const tare = this.props.config.packing_tare ? this.props.config.packing_tare : 0;
    const totalWeight = this.state["weight"+id] !== undefined ? this.state["weight"+id] : (parseFloat(total_weight) ? (parseFloat(total_weight) * 1000 + parseInt(tare)) : (this.props.config ? parseInt(this.props.config.default_weight) : 1000));
    const return_available = ((this.props.config !== null && this.props.config.sender_iso_country == 'FR' && this.props.iso_return_available.indexOf(country_code) === -1 && this.props.iso_fr.indexOf(country_code) === -1) || (this.props.config !== null && this.props.config.sender_iso_country != 'FR' && this.props.iso_dom_return_available.indexOf(country_code) === -1)) ? false : true;

    const labels_lines = data.labels.map(line => {
      return (
        <Box direction="vertical" padding="SP2">
          <Box>
            <Text>{I18n.t('order_list.sheet.tracking_number')}</Text>&nbsp;<Text weight="bold" skin={line.file_deleted != true  ? "success" : "error"}>{line.shipping_number}</Text>
          </Box>
          <Text size="small" skin="disabled">{I18n.t('order_list.sheet.created_label')}{new Intl.DateTimeFormat('default', options).format(new Date(line.created_at))}</Text>
          {line.partial == true &&
            <Text size="small" skin="success">{I18n.t('order_list.sheet.partial_fulfilled')}</Text>
          }
          {line.print_at &&
            <Text size="small" skin="success">{I18n.t('order_list.sheet.print_at')}{new Intl.DateTimeFormat('default', options).format(new Date(line.print_at))}</Text>
          }
          {line.file_deleted == true &&
            <Text size="small" skin="error">{I18n.t('order_list.sheet.file_delete_at')}{new Intl.DateTimeFormat('default', options).format(new Date(line.updated_at))}</Text>
          }
          {line.cn23 == true && line.file_deleted != true &&
            <TextButton size="small" as="a" href={`/colissimo-label-cn23/${line.id}/${this.props.shop.instance_id}`} target="_blank">{I18n.t('order_list.print_cn23')}</TextButton>
          }
          {(line.file_deleted != true && line.coliship != true) &&
            <Box direction="vertical" paddingTop="SP2">
              <Box>
                <NumberInput
                  type="text"
                  size="small"
                  name={`weight${line.order_id}`}
                  id={`regenerate_weight${line.order_id}`}
                  value={isNaN(totalWeight) ? 1000 : parseInt(`${totalWeight}`)}
                  onChange={this.handleChange(`weight${line.order_id}`)}
                />
              </Box>
              <Box>
                <Text size="tiny">{I18n.t('order_list.label_weight')}</Text>
              </Box>
              <Box paddingTop="SP2">
                {( this.props.config.use_thermal_printer == true) && 
                  <Button
                  priority="secondary"
                  prefixIcon={<PrintSmall />}
                  onClick={(e) => this.printLabel(line.id)}
                >
                  {I18n.t('order_list.print')}
                </Button>
                }
                {( this.props.config.use_thermal_printer == false) && 
                  <Button
                    priority="secondary"
                    prefixIcon={<PrintSmall />}
                    as="a" href={`/colissimo-label/${line.id}/${this.props.shop.instance_id}`} target="_blank"
                  >
                    {I18n.t('order_list.print')}
                  </Button>
                }
                &nbsp;<Button
                  skin="standard"
                  prefixIcon={<ShippingSmall />}
                  onClick={(e) => {this.setState({modalRegenerate: true}); this.regenerateLabel(line.order_id,line.id)}}
                >
                  {I18n.t('order_list.regenerate')}
                </Button>
              </Box>
            </Box>
          }
          <Box paddingTop="SP2">
            <Divider />
          </Box>
        </Box>
      );
    });

    const labels_return_lines = data.labels_return.map(line => {
      return (
        <Box direction="vertical" padding="SP2">
          <Box>
            <Text>{I18n.t('order_list.sheet.tracking_number')}</Text>&nbsp;<Text weight="bold" skin={line.file_deleted != true  ? "success" : "error"}>&nbsp;{line.shipping_number}</Text>
          </Box>
          <Text size="small" skin="disabled">{I18n.t('order_list.sheet.created_label')}{new Intl.DateTimeFormat('default', options).format(new Date(line.created_at))}</Text>
          {line.partial == true &&
            <Text size="small" skin="success">{I18n.t('order_list.sheet.partial_fulfilled')}</Text>
          }
          {line.print_at &&
            <Text size="small" skin="success">{I18n.t('order_list.sheet.print_at')}{new Intl.DateTimeFormat('default', options).format(new Date(line.print_at))}</Text>
          }
          {line.file_deleted == true &&
            <Text size="small" skin="error">{I18n.t('order_list.sheet.file_delete_at')}{new Intl.DateTimeFormat('default', options).format(new Date(line.updated_at))}</Text>
          }
          {line.cn23 == true && line.file_deleted != true &&
            <TextButton size="small" as="a" href={`/colissimo-label-cn23/${line.id}/${this.props.shop.instance_id}`} target="_blank">{I18n.t('order_list.print_cn23')}</TextButton>
          }
          {(line.file_deleted != true && line.coliship != true) &&
            <Box direction="vertical" paddingTop="SP2" paddingBottom="SP2">
              <Box>
                <NumberInput
                  type="text"
                  size="small"
                  name={`weight${line.order_id}`}
                  id={`regenerate_weight${line.order_id}`}
                  value={isNaN(totalWeight) ? 1000 : parseInt(`${totalWeight}`)}
                  onChange={this.handleChange(`weight${line.order_id}`)}
                />
              </Box>
              <Box>
                <Text size="tiny">{I18n.t('order_list.label_weight')}</Text>
              </Box>
              <Box paddingTop="SP2">
                {( this.props.config.use_thermal_printer == true) && 
                  <Button
                  priority="secondary"
                  prefixIcon={<PrintSmall />}
                  onClick={(e) => this.printLabel(line.id)}
                >
                  {I18n.t('order_list.print')}
                </Button>
                }
                {( this.props.config.use_thermal_printer == false) && 
                  <Button
                    priority="secondary"
                    prefixIcon={<PrintSmall />}
                    as="a" href={`/colissimo-label/${line.id}/${this.props.shop.instance_id}`} target="_blank"
                  >
                    {I18n.t('order_list.print')}
                  </Button>
                }
                &nbsp;<Button
                  skin="standard"
                  prefixIcon={<ShippingSmall />}
                  onClick={(e) => {this.setState({modalRegenerateReturn: true}); this.regenerateLabelReturn(line.order_id,line.id)}}
                >
                  {I18n.t('order_list.regenerate')}
                </Button>
              </Box>
            </Box>
          }
          <Box paddingTop="SP2">
            <Divider />
          </Box>
        </Box>
      );
    });

    var payment_status_badge = '';

    if (payment_status == 'PARTIALLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="general" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="success" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'FULLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="neutral" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PENDING') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="warning" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'NOT_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="danger" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PARTIALLY_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="urgent" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'UNSPECIFIED_PAYMENT_STATUS') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="standard" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: `${sheetActive ? 0 : -440}px`,
          height: '100%',
          boxShadow:
            '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
          transition: 'right 0.4s ease 0s',
          zIndex: 2000,
        }}
      >
        <SidePanel title="Filter" onCloseButtonClick={this.handleCloseSheet}>
          <SidePanel.Header title={payment_status_badge} showDivider={true} />
          <SidePanel.Content>
            <Box direction="vertical" gap="18px">
                {data.labels.length > 0 &&
                  <Box direction="vertical"> 
                    <TableListHeader options={[{ value: I18n.t('order_list.sheet.labels') }]} />
                    {labels_lines}
                  </Box>
                }
                {data.labels.length <= 0 &&
                  <div>
                    <Box>
                      <NumberInput
                        type="text"
                        size="small"
                        name={`weight${id}`}
                        id={`weight${id}`}
                        value={isNaN(totalWeight) ? 1000 : (parseFloat(`${totalWeight}`)) }
                        onChange={this.handleChange(`weight${id}`)}
                      />
                    </Box>
                    <Box>
                      <Text size="tiny">{I18n.t('order_list.label_weight')}</Text>
                    </Box>
                    <Box paddingTop="SP2">
                      <Button
                        skin="standard"
                        prefixIcon={<ShippingSmall />}
                        onClick={(e) => this.generateLabel(id)}
                      >
                        {I18n.t('order_list.generate')}
                      </Button>
                    </Box>
                  </div>         
                }
                {data.labels_return.length > 0 &&
                  <Box direction="vertical"> 
                    <TableListHeader options={[{ value: I18n.t('order_list.sheet.labels') }]} />
                    {labels_return_lines}
                  </Box>
                }
                {data.labels_return.length <= 0 &&
                  (return_available === true ? (
                    <div>
                      <Box>
                        <NumberInput
                          type="text"
                          size="small"
                          name={`weight${id}`}
                          id={`weight${id}`}
                          value={isNaN(totalWeight) ? 1000 : (parseFloat(`${totalWeight}`))}
                          onChange={this.handleChange(`weight${id}`)}
                        />
                      </Box>
                      <Box>
                        <Text size="tiny">{I18n.t('order_list.label_weight')}</Text>
                      </Box>
                      <Box paddingTop="SP2">
                        <Button
                          skin="standard"
                          prefixIcon={<ShippingSmall />}
                          onClick={(e) => this.generateLabelReturn(id)}
                        >
                          {I18n.t('order_list.generate_return')}
                        </Button>
                      </Box>
                    </div>) : (
                      <Box><Text variation="negative">{I18n.t('order_list.sheet.unavailable_return')}</Text></Box>
                  ))
                }
            </Box>
          </SidePanel.Content>
        </SidePanel>
      </div>
    );
  };

  renderColumnOrderNumber = (row) => {
    const {id, wix_order_number, payment_status, wix_order_created_at, commercial_name, error_generate_label, error_generate_label_message, number_label, country_code, province_code} = row;

    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
      timeZone: this.props.shop.time_zone
    };

    const cn23 = ((this.props.config !== null && this.props.config.sender_iso_country == 'FR' && this.props.iso_eu_countries_no_cn23.indexOf(country_code) === -1 && this.props.iso_fr.indexOf(country_code) === -1) || (this.props.config !== null && this.props.config.sender_iso_country != 'FR' && this.props.iso_dom_countries_no_cn23.indexOf(country_code) === -1) || (this.props.config !== null && this.props.config.sender_iso_country == 'FR' && country_code == 'ES' && this.props.province_code_cn23.indexOf(province_code) > -1 )) ? (
      <Box verticalAlign="middle">
        <Text size="tiny" skin="error"><MapSmall /></Text>&nbsp;<Text size="tiny" skin="error">{I18n.t('order_list.cn23_alert')}</Text>
      </Box>) : null;

    return (
      <div>
        <Box gap="SP1" verticalAlign="middle">
          {error_generate_label === true &&
            <Tooltip content={error_generate_label_message}><StatusWarningFilled /></Tooltip>
          }
          <TextButton onClick={(e) => this.handleToggleSheet(id)}>#{wix_order_number}</TextButton>
          {payment_status == 'PARTIALLY_REFUNDED' && <Badge skin="general" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'PAID' && <Badge skin="success" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'FULLY_REFUNDED' && <Badge skin="neutral" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'PENDING' && <Badge skin="warning" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'NOT_PAID' && <Badge skin="danger" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'PARTIALLY_PAID' && <Badge skin="urgent" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
          {payment_status == 'UNSPECIFIED_PAYMENT_STATUS' && <Badge skin="standard" size="tiny">
              {I18n.t(`${payment_status}`)}
          </Badge>}
        </Box>
        <Box>
          <Text size="tiny" skin="disabled">{new Intl.DateTimeFormat('default', options).format(new Date(wix_order_created_at))}</Text>
        </Box>
        <Box>
          <Text size="tiny"><span style={{color:'#F49342'}}>{commercial_name !== null && commercial_name !== '' ? commercial_name : I18n.t('order_list.no_colissimo_order')}</span> <TextButton size="tiny" onClick={(e) => this.handleToggleSheet(id, 'change')}>{I18n.t('order_list.change')}</TextButton></Text>
        </Box>
        {number_label > 0 && 
          <Box>
              <TextButton underline="always" prefixIcon={<DocumentSmall />} size="small" skin="premium" onClick={(e) => this.handleToggleSheet(id, 'labels')}>{I18n.t('order_list.label_available')}</TextButton>
          </Box>}
        {number_label == 0 &&
          cn23
        }
      </div>
    );
  }

  renderColumnCustomer = (row) => {
    const {id, full_name, email, product_code, country_code, international_offer} = row;

    if (this.props.config !== null && this.props.config.sender_iso_country == 'FR' && this.props.dpd_eligibility.indexOf(country_code) > -1 && product_code == 'DOS') {
      var icon_offer = <img src={dpd} width="40px" />;

      if (international_offer == '1') {
          switch (country_code) {
              case 'DE':
                  var icon_offer = <img src={germany} width="70px" />;
                  break;
              case 'AT':
                  var icon_offer = <img src={austria} width="70px" />;
                  break;
              case 'IT':
                  var icon_offer = <img src={italy} height="20px" />;
                  break;
              case 'LU':
                  var icon_offer = <img src={luxembourg} height="20px" />;
                  break;
              default:
                  var icon_offer = <img src={dpd} width="40px" />;
          }
      }
    }

    return (
      <div>
        <Box>
          <Text weight="bold" size="tiny">{full_name}</Text>
        </Box>
        <Box>
          <Text weight="thin" size="tiny">{email}</Text>
        </Box>
        {(this.props.config !== null && this.props.config.sender_iso_country == 'FR' && this.props.dpd_eligibility.indexOf(country_code) > -1 && product_code == 'DOS') &&
          <Box><Text weight="thin" size="tiny">via {icon_offer} <TextButton size="tiny" onClick={(e) => this.handleToggleSheet(id, 'change_offer')}>{I18n.t('order_list.change')}</TextButton></Text></Box>
        }
      </div>
    );
  }

  capitalize = (str) => {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  renderColumnFulfillment = (row) => {
    const {fulfillment_status, country_code, country} = row;

    return (
      <div>
        <Box align="center">
            {fulfillment_status == 'FULFILLED' && <Badge skin="neutral" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
            {fulfillment_status == 'NOT_FULFILLED' && <Badge skin="warning" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
            {fulfillment_status == 'CANCELED' && <Badge skin="danger" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
            {fulfillment_status == 'PARTIALLY_FULFILLED' && <Badge skin="urgent" size="tiny">
                {I18n.t(`${fulfillment_status}`)}
            </Badge>}
        </Box>
        <Box align="center">
          {(typeof country != 'undefined' && country != null) ? (<Text size="tiny"><span style={{color:'#F49342'}}>{this.capitalize(country)}</span></Text>) : ('')}
        </Box>
        {(typeof country_code != 'undefined' && country_code != null) ? (<div style={{backgroundImage:`url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${country_code.toLowerCase()}.svg)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '1em'}}></div>) : (' ')}
      </div>
    );
  }

  renderColumnWeight = (row) => {
    const {id, total_weight} = row;

    const tare = this.props.config.packing_tare ? this.props.config.packing_tare : 0;
    const totalWeight = this.state["weight"+id] !== undefined ? this.state["weight"+id] : (parseFloat(total_weight) ? (parseFloat(total_weight) * 1000 + parseInt(tare)) : (this.props.config ? parseInt(this.props.config.default_weight) : 1000));

    return (
      <div>
        <Box>
          <NumberInput
            type="text"
            size="small"
            name={`weight${id}`}
            id={`weight${id}`}
            value={isNaN(totalWeight) ? 1000 : parseInt(`${totalWeight}`)}
            onChange={this.handleChange(`weight${id}`)}
          />
        </Box>
        <Box>
          <Text size="tiny">{I18n.t('order_list.label_weight')}</Text>
        </Box>
      </div>
    );
  }

  renderColumnActions = (row) => {
    const {id, number_label, fulfillment_status,coliship} = row;

    return (
      <div>
        {(number_label == 0 && fulfillment_status != 'FULFILLED') &&
          <Button
            skin="standard"
            size="small"
            prefixIcon={<ShippingSmall />}
            onClick={(e) => this.generateLabel(id)}
          >
            {I18n.t('order_list.generate')}
          </Button>
        }
        {(number_label == 0 && fulfillment_status == 'FULFILLED') &&
          <PopoverMenu
            textSize="small"
            triggerElement={
              <Tooltip content="More actions">
                <Button
                  skin="standard"
                  size="small"
                  suffixIcon={<ChevronDownLargeSmall />}
                >
                  {I18n.t('order_list.generate')}
                </Button>
              </Tooltip>
            }
          >
            <PopoverMenu.MenuItem
              text={I18n.t('order_list.generate_label')}
              prefixIcon={<ShippingSmall />}
              onClick={(e) => this.generateLabel(id)}
            />
            <PopoverMenu.MenuItem
              text={I18n.t('order_list.generate_return')}
              prefixIcon={<RedoSmall />}
              onClick={(e) => this.generateLabelReturn(id)}
            />
          </PopoverMenu>
        }
        {(number_label == 1 && coliship !== true) &&
          <div>
            {( this.props.config.use_thermal_printer == true) && 
              <Button
                priority="secondary"
                size="small"
                prefixIcon={<PrintSmall />}
                onClick={(e) => this.printLabelByOrder(id)}
              >
                {I18n.t('order_list.print')}
              </Button>
            }
            {( this.props.config.use_thermal_printer == false) && 
              <Button
                priority="secondary"
                size="small"
                prefixIcon={<PrintSmall />}
                as="a" href={`/colissimo-label-by-order/${id}/${this.props.shop.instance_id}`} target="_blank"
              >
                {I18n.t('order_list.print')}
              </Button>
            }
          </div>
        }
        {number_label > 1 &&
          <Button
            priority="secondary"
            size="small"
            prefixIcon={<PrintSmall />}
            onClick={(e) => this.handleToggleSheet(id, 'labels')}
          >
            {I18n.t('order_list.details')}
          </Button>
        }
      </div>
    );
  }

  renderColumnOptions = (row) => {
    const {id, country_code, product_code, total_price, height, width, length, description} = row;

    const checkedInsurance = this.state["insurance"+id] !== undefined ? this.state["insurance"+id] : (this.props.config ? (this.props.config.insure_shipments && total_price >= this.props.config.amount_to_insurance ? this.props.config.insure_shipments : false) : false);
    const checkedReturn = this.state["return"+id] !== undefined ? this.state["return"+id] : (this.props.config ? this.props.config.auto_print_return_label : false);
    const checkedMachinable = this.state["machinable"+id];
    const checkedFtd = this.state["ftd"+id];

    const ftd = this.props.iso_outre_mer.indexOf(country_code) === -1 ? false : true;
        
    if ((product_code == '' || product_code == null) && this.props.iso_no_worldwide.indexOf(country_code) === -1) {
        var productCode = 'COLI';
    } else if (product_code == '' || product_code == null) {
        var productCode = 'DOM'
    } else {
        var productCode = product_code;
    }

    const ddp = (this.props.delivery_duty_paid.indexOf(country_code) > -1 && (productCode == 'DOS' || productCode == 'COLI'))  ? true : false;

    const insurance_available = (this.props.insurable_products.indexOf(productCode) >= 0 || (productCode == 'DOS' && country_code == 'FR')) ? true : false;
    const return_available = ((this.props.config !== null && this.props.config.sender_iso_country == 'FR' && this.props.iso_return_available.indexOf(country_code) === -1 && this.props.iso_fr.indexOf(country_code) === -1) || (this.props.config !== null && this.props.config.sender_iso_country != 'FR' && this.props.iso_dom_return_available.indexOf(country_code) === -1)) ? false : true;

    return (
      <Box align="center">
        <Box direction="vertical" align="center">
          <Checkbox
            tooltipContent={I18n.t('order_list.insurance_tooltip')}
            tooltipProps={{
              placement: 'top-start',
              textAlign: 'start',
            }}
            checked={insurance_available ? checkedInsurance : false}
            id={`insurance${id}`} 
            onChange={() => this.handleChangeWithValue(`insurance${id}`, !checkedInsurance)}
            disabled={!insurance_available}
          />
          <div style={{textAlign: 'center'}}>
            <Text size="tiny">{I18n.t('order_list.insurance')}</Text>
          </div>
        </Box>
        <Box direction="vertical" align="center" paddingLeft="SP2">
          <Checkbox
            tooltipContent={I18n.t('order_list.return_tooltip')}
            tooltipProps={{
              placement: 'top-start',
              textAlign: 'start',
            }}
            checked={return_available ? checkedReturn : false}
            id={`return${id}`} 
            onChange={() => this.handleChangeWithValue(`return${id}`, !checkedReturn)}
            disabled={!return_available}
          />
          <div style={{textAlign: 'center'}}>
            <Text size="tiny">{I18n.t('order_list.return_label')}</Text>
          </div>
        </Box>
        <Box direction="vertical" align="center" paddingLeft="SP2">
          <Checkbox
            tooltipContent={I18n.t('order_list.machinable_tooltip')}
            tooltipProps={{
              placement: 'top-start',
              textAlign: 'start',
            }}
            checked={checkedMachinable}
            id={`machinable${id}`}
            onChange={() => this.handleChangeWithValue(`machinable${id}`, !checkedMachinable)}
          />
          <div style={{textAlign: 'center'}}>
            <Text size="tiny">{I18n.t('order_list.machinable_label')}</Text>
          </div>
        </Box>
        {ftd === true ? (
          <Box direction="vertical" align="center" paddingLeft="SP2">
            <Checkbox
              tooltipContent={I18n.t('order_list.ftd_tooltip')}
              tooltipProps={{
                placement: 'top-start',
                textAlign: 'start',
              }}
              checked={checkedFtd}
              id={`ftd${id}`}
              onChange={() => this.handleChangeWithValue(`ftd${id}`, !checkedFtd)}
            />
            <div style={{textAlign: 'center'}}>
              <Text size="tiny">{I18n.t('order_list.ftd_label')}</Text>
            </div>
          </Box>
        ) : ''}
        {ddp === true ? (
          <div>
            <Modal
            isOpen={this.state["activeddp"+id]}
            onRequestClose={() => this.handleChangeWithValue(`activeddp${id}`)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText={I18n.t('order_list.sheet.save_service')}
              secondaryButtonText={I18n.t('order_list.close_ddp')}
              onCloseButtonClick={() => this.handleChangeWithValue(`activeddp${id}`, false)}
              secondaryButtonOnClick={() => this.handleChangeWithValue(`activeddp${id}`, false)}
              primaryButtonOnClick={() => this.handleChangeDdp(id)}
              title={I18n.t('order_list.modal.ddp_title')}
              content={
                <Box direction="vertical" marginBottom="SP2">
                  <Layout>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t("order_list.label_width")}
                        required
                      >
                        <Input
                          type="text"
                          name={`width${id}`}
                          id={`width${id}`}
                          value={this.state["width"+id] !== undefined ? this.state["width"+id] : (width > 0 ? `${width}` : '')}
                          onChange={this.handleChangeDpd}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('order_list.label_height')}
                        required
                      >
                        <Input
                          type="text"
                          name={`height${id}`}
                          id={`height${id}`}
                          value={this.state["height"+id] !== undefined ? this.state["height"+id] : (height > 0 ? `${height}` : '')}
                          onChange={this.handleChangeDpd}
                        />
                      </FormField>
                    </Cell>
                  </Layout>
                  <br/>
                  <Layout>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t("order_list.label_length")}
                        required
                      >
                        <Input
                          type="text"
                          name={`length${id}`}
                          id={`length${id}`}
                          value={this.state["length"+id] !== undefined ? this.state["length"+id] : (length > 0 ? `${length}` : '')}
                          onChange={this.handleChangeDpd}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={6}>
                      <FormField
                        label={I18n.t('order_list.label_description')}
                        required
                      >
                        <Input
                          type="text"
                          name={`description${id}`}
                          id={`description${id}`}
                          value={this.state["description"+id] !== undefined ? this.state["description"+id] : description}
                          onChange={this.handleChangeDpd}
                        />
                        <Text size="tiny">{I18n.t('order_list.helptext_description')}</Text>
                      </FormField>
                    </Cell>
                  </Layout>
                </Box>
              }
            ></CustomModalLayout>
          </Modal>
          <Box direction="vertical" align="center" paddingLeft="SP2">
            <Checkbox
              tooltipContent={I18n.t('order_list.ddp_tooltip')}
              tooltipProps={{
                placement: 'top-start',
                textAlign: 'start',
              }}
              checked={checkedFtd}
              id={`ftd${id}`}
              onChange={() => this.handleChangeWithValue(`ftd${id}`, !checkedFtd)}
            />
            <div style={{textAlign: 'center'}}>
              <Text size="tiny">{I18n.t('order_list.ddp_label')}</Text>
            </div>
          </Box>
          </div>
        ) : ''}

      </Box>
    );
  }

  reinitVars = () => {
    this.setState({modalRegenerate: false});
    this.setState({modalRegenerateReturn: false});
    this.setState({regenerate_current_order_id: null});
    this.setState({regenerate_current_label_id: null});
  }

  displayModalRegenerate = () => {
    return (
      <Modal
        isOpen={this.state.modalRegenerate}
        onRequestClose={() => this.reinitVars()}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
          primaryButtonText={I18n.t('order_list.modal.regenerate_ok_button')}
          secondaryButtonText={I18n.t('order_list.modal.cancel_button')}
          onCloseButtonClick={() => this.reinitVars()}
          secondaryButtonOnClick={() => this.reinitVars()}
          primaryButtonOnClick={() => this.generateLabel(this.state.regenerate_current_order_id, false, this.state.regenerate_current_label_id, true)}
          title={I18n.t('order_list.modal.regenerate_title')}
          content={
            <Box direction="vertical" marginBottom="SP2">
              <Text>{I18n.t('order_list.modal.regenerate_message')}</Text>
            </Box>
          }
        ></CustomModalLayout>
      </Modal>
    );
  }

  displayModalRegenerateReturn = () => {
    return (
      <Modal
        isOpen={this.state.modalRegenerateReturn}
        onRequestClose={() => this.reinitVars()}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
          primaryButtonText={I18n.t('order_list.modal.regenerate_ok_button')}
          secondaryButtonText={I18n.t('order_list.modal.cancel_button')}
          onCloseButtonClick={() => this.reinitVars()}
          secondaryButtonOnClick={() => this.reinitVars()}
          primaryButtonOnClick={() => this.generateLabelReturn(this.state.regenerate_current_order_id, false, this.state.regenerate_current_label_id, true)}
          title={I18n.t('order_list.modal.regenerate_title')}
          content={
            <Box direction="vertical" marginBottom="SP2">
              <Text>{I18n.t('order_list.modal.regenerate_message')}</Text>
            </Box>
          }
      ></CustomModalLayout>
      </Modal>
    );
  }

  displayModalBulkGenerate = () => {
    return (
      <Modal
        isOpen={this.state.modalBulkGenerate}
        onRequestClose={() => this.setState({modalBulkGenerate: false})}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
          primaryButtonText={I18n.t('order_list.modal.generate_ok_button')}
          secondaryButtonText={I18n.t('order_list.modal.cancel_button')}
          onCloseButtonClick={() => this.setState({modalBulkGenerate: false})}
          secondaryButtonOnClick={() => this.setState({modalBulkGenerate: false})}
          primaryButtonOnClick={() => this.generateBulkLabel()}
          title={I18n.t('order_list.modal.generate_bulk_label_title')}
          content={
            <Box direction="vertical" marginBottom="SP2">
              <Text>{I18n.t('order_list.modal.generate_bulk_label_message')}</Text>
            </Box>
          }
        ></CustomModalLayout>
      </Modal>
    );
  }

  displayModalAddress = (shipping) => {
    return (
      <Modal
        isOpen={this.state.modalChangeAddress}
        onRequestClose={() => this.setState({modalChangeAddress: false})}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
            onCloseButtonClick={() => this.setState({modalChangeAddress: false})}
            title={I18n.t('order_list.modal.modify_address')}
            content={
              <Box direction="vertical" marginBottom="SP2">
                <Layout>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('order_list._form.firstname')}
                      required
                    >
                      <Input
                        type="text"
                        status={shipping.first_name !== null && shipping.first_name.length > 0 ? '' : 'error'}
                        name="first_name"
                        statusMessage={shipping.first_name !== null && shipping.first_name.length > 0 ? '' : I18n.t('configs.address.firstname_error')}
                        tooltipPlacement="top-end"
                        value={shipping.first_name}
                        onChange={this.handleChangeAddress}
                        maxLength="35"
                        suffix={<Input.Affix>{shipping.first_name !== null ? 35 - shipping.first_name.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('order_list._form.lastname')}
                      required
                    >
                      <Input
                        type="text"
                        status={shipping.last_name !== null && shipping.last_name.length > 0 ? '' : 'error'}
                        name="last_name"
                        statusMessage={shipping.last_name !== null && shipping.last_name.length > 0 ? '' : I18n.t('configs.address.lastname_error')}
                        tooltipPlacement="top-end"
                        value={shipping.last_name}
                        onChange={this.handleChangeAddress}
                        suffix={<Input.Affix>{shipping.last_name !== null ? 35 - shipping.last_name.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                </Layout>
                <Layout>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('order_list._form.company_name')}
                    >
                      <Input
                        type="text"
                        name="company"
                        tooltipPlacement="top-end"
                        value={shipping.company}
                        onChange={this.handleChangeAddress}
                        maxLength="35"
                        suffix={<Input.Affix>{shipping.company !== null ? 35 - shipping.company.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('order_list._form.mobile_phone')}
                      required
                    >
                      <Input
                        type="text"
                        status={shipping.phone !== null && shipping.phone.length > 0 ? '' : 'error'}
                        name="phone"
                        statusMessage={shipping.phone !== null && shipping.phone.length > 0 ? '' : I18n.t('configs.address.phone_error')}
                        tooltipPlacement="top-end"
                        value={shipping.phone}
                        onChange={this.handleChangeAddress}
                      />
                      <Text size="tiny">{I18n.t('configs.address.phone_help')}</Text>
                    </FormField>
                  </Cell>
                </Layout>
                <Cell>
                  <FormField
                    label={I18n.t('order_list._form.address1')}
                    required
                  >
                    <Input
                      type="text"
                      status={shipping.address1 !== null && shipping.address1.length > 0 ? '' : 'error'}
                      name="address1"
                      statusMessage={shipping.address1 !== null && shipping.address1.length > 0 ? '' : I18n.t('configs.address.address1_error')}
                      tooltipPlacement="top-end"
                      value={shipping.address1}
                      onChange={this.handleChangeAddress}
                      maxLength="35"
                      suffix={<Input.Affix>{shipping.address1 !== null ? 35 - shipping.address1.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Cell>
                  <FormField
                    label={I18n.t('order_list._form.address2')}
                  >
                    <Input
                      type="text"
                      name="address2"
                      tooltipPlacement="top-end"
                      value={shipping.address2}
                      onChange={this.handleChangeAddress}
                      maxLength="35"
                      suffix={<Input.Affix>{shipping.address2 !== null ? 35 - shipping.address2.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                  <br/>
                </Cell>
                <Layout>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('order_list._form.zip_code')}
                      required
                    >
                      <Input
                        type="number"
                        status={shipping.zip !== null && shipping.zip.length > 0 ? '' : 'error'}
                        name="zip"
                        statusMessage={shipping.zip !== null && shipping.zip.length > 0 ? '' : I18n.t('configs.address.postcode_error')}
                        tooltipPlacement="top-end"
                        value={shipping.zip}
                        onChange={this.handleChangeAddress}
                      />
                    </FormField>
                  </Cell>
                  <Cell span={6}>
                    <FormField
                      label={I18n.t('order_list._form.city')}
                      required
                    >
                      <Input
                        type="text"
                        status={shipping.city !== null && shipping.city.length > 0 ? '' : 'error'}
                        name="city"
                        statusMessage={shipping.city !== null && shipping.city.length > 0 ? '' : I18n.t('configs.address.city_error')}
                        tooltipPlacement="top-end"
                        value={shipping.city}
                        onChange={this.handleChangeAddress}
                        maxLength="35"
                        suffix={<Input.Affix>{shipping.city !== null ? 35 - shipping.city.length +`/35` : `35/35`}</Input.Affix>}
                      />
                    </FormField>
                  </Cell>
                </Layout>
                <br/><br/>
                <Cell>
                  <Page.Footer divider>
                    <Page.Footer.End>
                      <Button onClick={(e) => this.handleSubmit(e)}>{I18n.t('order_list._form.save_address')}</Button>
                    </Page.Footer.End>
                  </Page.Footer>
                </Cell>
              </Box>
            }
        ></CustomModalLayout>
      </Modal>
    );
  }

  displayModalAddressPickup = (shipping) => {
    return (
      <Modal
        isOpen={this.state.modalChangeAddressPickup}
        onRequestClose={() => this.setState({modalChangeAddressPickup: false})}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
          onCloseButtonClick={() => this.setState({modalChangeAddressPickup: false})}
          title={I18n.t('order_list.modal.modify_address')}
          content={
            <Box direction="vertical" marginBottom="SP2">
              <Layout>
                <Cell span={6}>
                  <FormField
                    label={I18n.t('order_list._form.company_name')}
                  >
                    <Input
                      type="text"
                      name="company"
                      tooltipPlacement="top-end"
                      value={shipping.company_name}
                      onChange={this.handleChangeAddressPickup}
                      maxLength="35"
                      suffix={<Input.Affix>{shipping.company_name !== null ? 35 - shipping.company_name.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                </Cell>
                <Cell span={6}>
                  <FormField
                    label={I18n.t('order_list._form.mobile_phone')}
                    required
                  >
                    <Input
                      type="text"
                      status={shipping.phone !== null && shipping.phone.length > 0 ? '' : 'error'}
                      name="phone"
                      statusMessage={shipping.phone !== null && shipping.phone.length > 0 ? '' : I18n.t('configs.address.phone_error')}
                      tooltipPlacement="top-end"
                      value={shipping.phone}
                      onChange={this.handleChangeAddressPickup}
                    />
                    <Text size="tiny">{I18n.t('configs.address.phone_help')}</Text>
                  </FormField>
                </Cell>
              </Layout>
              <Cell>
                <FormField
                  label={I18n.t('order_list._form.address1')}
                  required
                >
                  <Input
                    type="text"
                    status={shipping.address1 !== null && shipping.address1.length > 0 ? '' : 'error'}
                    name="address1"
                    statusMessage={shipping.address1 !== null && shipping.address1.length > 0 ? '' : I18n.t('configs.address.address1_error')}
                    tooltipPlacement="top-end"
                    value={shipping.address1}
                    onChange={this.handleChangeAddressPickup}
                    maxLength="35"
                    suffix={<Input.Affix>{shipping.address1 !== null ? 35 - shipping.address1.length +`/35` : `35/35`}</Input.Affix>}
                  />
                </FormField>
                <br/>
              </Cell>
              <Cell>
                <FormField
                  label={I18n.t('order_list._form.address2')}
                >
                  <Input
                    type="text"
                    name="address2"
                    tooltipPlacement="top-end"
                    value={shipping.address2}
                    onChange={this.handleChangeAddressPickup}
                    maxLength="35"
                    suffix={<Input.Affix>{shipping.address2 !== null ? 35 - shipping.address2.length +`/35` : `35/35`}</Input.Affix>}
                  />
                </FormField>
                <br/>
              </Cell>
              <Layout>
                <Cell span={6}>
                  <FormField
                    label={I18n.t('order_list._form.zip_code')}
                    required
                  >
                    <Input
                      type="number"
                      status={shipping.zipcode !== null && shipping.zipcode.length > 0 ? '' : 'error'}
                      name="zipcode"
                      statusMessage={shipping.zipcode !== null && shipping.zipcode.length > 0 ? '' : I18n.t('configs.address.postcode_error')}
                      tooltipPlacement="top-end"
                      value={shipping.zipcode}
                      onChange={this.handleChangeAddressPickup}
                    />
                  </FormField>
                </Cell>
                <Cell span={6}>
                  <FormField
                    label={I18n.t('order_list._form.city')}
                    required
                  >
                    <Input
                      type="text"
                      status={shipping.city !== null && shipping.city.length > 0 ? '' : 'error'}
                      name="city"
                      statusMessage={shipping.city !== null && shipping.city.length > 0 ? '' : I18n.t('configs.address.city_error')}
                      tooltipPlacement="top-end"
                      value={shipping.city}
                      onChange={this.handleChangeAddressPickup}
                      maxLength="35"
                      suffix={<Input.Affix>{shipping.city !== null ? 35 - shipping.city.length +`/35` : `35/35`}</Input.Affix>}
                    />
                  </FormField>
                </Cell>
              </Layout>
              <br/><br/>
              <Cell>
                <Page.Footer divider>
                  <Page.Footer.End>
                    <Button onClick={(e) => this.handleSubmitPickup(e)}>{I18n.t('order_list._form.save_address')}</Button>
                  </Page.Footer.End>
                </Page.Footer>
              </Cell>
            </Box>
          }
        ></CustomModalLayout>
      </Modal>
    );
  }

  displayModalImport = () => {
    return (
      <Modal
        isOpen={this.state.modalImport}
        onRequestClose={() => this.setState({modalImport: false})}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
          primaryButtonText={I18n.t('order_list.modal.import_orders')}
          secondaryButtonText={I18n.t('order_list.modal.cancel_button')}
          onCloseButtonClick={() => this.setState({modalImport: false})}
          secondaryButtonOnClick={() => this.setState({modalImport: false})}
          primaryButtonOnClick={() => this.handleImportOrders()}
          title={I18n.t('order_list.modal.import_title')}
          content={
            <Box direction="vertical" marginBottom="SP2">
              <Text>{I18n.t('order_list.modal.import_message')}</Text>
              <br/>
              <FormField
                label={I18n.t('order_list.modal.import_order_form')}
                required
              >
                <Input
                  type="text"
                  status={this.state.import_orders !== null && this.state.import_orders.length > 0 ? '' : 'error'}
                  name="import_orders"
                  statusMessage={this.state.import_orders !== null && this.state.import_orders.length > 0 ? '' : I18n.t('order_list.modal.import_order_error')}
                  tooltipPlacement="top-end"
                  value={this.state.import_orders}
                  onChange={this.handleChangeImportOrders}
                />
              </FormField>
            </Box>
          }
        ></CustomModalLayout>
      </Modal>
    );
  }

  disambiguateLabel = (key, value) => {
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: this.props.shop.time_zone
    };
    
    switch (key) {
      case 'weight':
        return `${I18n.t('order_list.filters.weight_label')} ${value[0]} ${I18n.t('order_list.filters.grams')} ${I18n.t('order_list.filters.operator')} ${value[1]} ${I18n.t('order_list.filters.grams')}`;
      case 'fulfillmentStatus':
        return value.map((val) => `${I18n.t(val)}`).join(', ');
      case 'paymentStatus':
        return value.map((val) => `${I18n.t(val)}`).join(', ');
      case 'labelStatus':
        switch (value[0]) {
          case 'unavailable':
            return `${I18n.t('order_list.filters.unavailable_label')}`;
          case 'available':
            return `${I18n.t('order_list.filters.available_label')}`;
          case 'return':
            return `${I18n.t('order_list.filters.return_label')}`;
          default:
            return null;
        }
      case 'printedStatus':
        switch (value[0]) {
          case true:
            return `${I18n.t('order_list.filters.printed')}`;
          case false:
            return `${I18n.t('order_list.filters.unprint')}`;
          default:
            return null;
        }
      case 'closedStatus':
        switch (value[0]) {
          case true:
            return `${I18n.t('order_list.filters.archived_order')}`;
          case false:
            return `${I18n.t('order_list.filters.unarchived_order')}`;
          default:
            return null;
        }
      case 'errorStatus':
        switch (value[0]) {
          case 'error':
            return `${I18n.t('order_list.filters.error_generating_label')}`;
          default:
            return null;
        }
      case 'date':
        switch (value[0]) {
          case 'past_day':
            return `${I18n.t('order_list.filters.date_today')}`;
          case 'past_week':
            return `${I18n.t('order_list.filters.date_past_week')}`;
          case 'past_month':
            return `${I18n.t('order_list.filters.date_past_month')}`;
          case 'past_quarter':
            return `${I18n.t('order_list.filters.date_past_quarter')}`;
          case 'past_year':
            return `${I18n.t('order_list.filters.date_past_year')}`;
          default:
            return null;
        }
      case 'date_from':
        return `${I18n.t('order_list.filters.date_from')} ${new Intl.DateTimeFormat('default', options).format(new Date(value))}`;
      case 'date_to':
        return `${I18n.t('order_list.filters.date_to')} ${new Intl.DateTimeFormat('default', options).format(new Date(value))}`;
      case 'countries':
        return value.map((val) => `${val}`).join(', ');
      case 'services':
        return value.map((val) => `${val}`).join(', ');
      default:
        return value;
    }
};

  render() {
    const {
      orders,
      loading,
      modalChangeAddress,
      modalChangeAddressPickup,
      modalRegenerate,
      modalRegenerateReturn,
      modalBulkGenerate,
      modalImport,
      orderShipping,
      orderShippingPickup,
      filterSide,
      queryValue
    } = this.state;

    const columns = [
      {
        title: I18n.t('order_list.order_number'),
        render: (row) => this.renderColumnOrderNumber(row),
        width: 260
      },
      {
        title: I18n.t('order_list.customer'),
        render: (row) => this.renderColumnCustomer(row),
        width: 150
      },
      {
        title: I18n.t('order_list.fulfillmment'),
        render: (row) => this.renderColumnFulfillment(row),
        width: 120,
        align: 'center'
      },
      {
        title: I18n.t('order_list.weight'),
        render: (row) => this.renderColumnWeight(row),
        width: 80
      },
      {
        title: I18n.t('order_list.options'),
        render: (row) => this.renderColumnOptions(row),
        width: 260
      },
      {
        title: I18n.t('order_list.actions'),
        render: (row) => this.renderColumnActions(row),
        width: 120
      }
    ];

    const includeFilters = [
      'queryValue',
      'paymentStatus',
      'printedStatus',
      'fulfillmentStatus',
      'labelStatus',
      'closedStatus',
      'errorStatus',
      'weight',
      'date',
      'date_from',
      'date_to',
      'countries',
      'services'
    ];

    const appliedFilters = Object.keys(this.state)
      .filter((key) => !isEmpty(this.state[key]) && includeFilters.indexOf(key) !== -1)
      .map((key) => {
        return {
        id: key,
        value: this.state[key],
        type: key,
        children: this.disambiguateLabel(key, this.state[key]),
        };
    });

    const tagExists = ({ type, value }) =>
      this.state[type].some((tag) => tag === value)

    const LabelFilter = [
      {
        label: I18n.t('order_list.filters.unavailable_label'),
        value: 'unavailable',
      },
      {
        label: I18n.t('order_list.filters.available_label'),
        value: 'available',
      },
      {
        label: I18n.t('order_list.filters.return_label'),
        value: 'return',
      },
    ];

    const PrintedFilter = [
      {
        label: I18n.t('order_list.filters.printed'),
        value: true,
      },
      {
        label: I18n.t('order_list.filters.unprint'),
        value: false,
      },
    ];

    const dateFilter = [
      {label: I18n.t('order_list.filters.date_today'), value: 'past_day'},
      {label: I18n.t('order_list.filters.date_past_week'), value: 'past_week'},
      {label: I18n.t('order_list.filters.date_past_month'), value: 'past_month'},
      {label: I18n.t('order_list.filters.date_past_quarter'), value: 'past_quarter'},
      {label: I18n.t('order_list.filters.date_past_year'), value: 'past_year'},
      {label: I18n.t('order_list.filters.date_custom'), value: 'custom'},
    ];

    const paginationMarkup =
      this.state.total_page > 1 ? (
      <Pagination
        currentPage={this.state.page}
        totalPages={this.state.total_page}
        onChange={(e) => this.handleChangePage(e.page)}
      />
  ) : null;

    const emptyStateMarkup = (!orders.length && loading === false) ? (
      <Card className="notroundedTop">
      <Table.EmptyState
        subtitle={<Box direction="vertical" paddingTop="SP2">
          <Box><br/></Box>
          <Box align="center"><Text weight="bold">{I18n.t('emptystate.orders.title')}</Text></Box>
          <Box align="center">{I18n.t('emptystate.orders.text1')}</Box>
          <Box align="center"><TextButton as="a" href="https://www.loom.com/share/b04d8d31553d402cb18d7d2bd54aec73?sid=54e33208-d1e7-40e3-b0f4-26a5a9ecf5ed" target="_blank">{I18n.t('emptystate.orders.url_link1')}</TextButton></Box>
          <Box align="center">{I18n.t('emptystate.orders.text2')}</Box>
          <Box align="center"><TextButton as="a" href="https://www.loom.com/share/a63194882594435ab0fe56125c41e1df?sid=3bb66af3-4e5a-4c3c-88e3-a64cb0d3b3de" target="_blank">{I18n.t('emptystate.orders.url_link2')}</TextButton></Box>
        </Box>}
        image={
          <Image
            height={226}
            width={226}
            src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/no-Commandes_226.svg?v=1671459568"
            transparent
          />
        }
      >
        <Button as="a" href="https://loom.com/share/folder/c13b118bcbe94b7f8dde5926bacfae92" target="_blank">{I18n.t('emptystate.link_text')}</Button>
      </Table.EmptyState></Card>
    ) : undefined;
    
    console.log(this.state.remaining_days);
    return (
      <div>
        <Page width="100%" className="fullWidth" height="100vh">
          <Page.Header
            title={I18n.t('order_list.resources_items_orders_title')}
            subtitle={<Image src={LogoColissimo} width="60" transparent />}
            actionsBar={<Box>
              {this.state.remaining_days !== false &&
                <Button as="a" skin="premium" href={this.props.upgrade_url} target="_blank">{I18n.t('upgrade.upgrade_app_button')}&nbsp;-&nbsp;{this.state.remaining_days}&nbsp;{I18n.t('upgrade.remaining_days')} </Button>
              }&nbsp;
              <Button skin="inverted" prefixIcon={<DownloadImportSmall />} onClick={(e) => { this.setState({modalImport: true}); }} >
                {I18n.t('order_list.import_orders')}
              </Button>
              &nbsp;&nbsp;
              <Button onClick={(e) => { this.setState({loading: true}, () => {
              this.callRequest('/orders-filters?page='+this.state.page+'&limit='+this.state.page_limit);
            });}}>{I18n.t('order_list.refresh')}</Button></Box>}
          ></Page.Header>
          <Page.Content>
            <Modal
              screen="desktop"
              isOpen={loading}
            >
              <Loader size="large" />
            </Modal>
            <Table 
              data={orders}
              columns={columns}
              horizontalScroll
              stickyColumns={2}
              showSelection
              onSelectionChanged={this.handleSelectionChange}
              selectedIds={this.state.selectedItems}
            >
              <Page.Sticky>
                <Card className="notroundedBottom">
                  <Tabs
                    items={[
                      { id: 0, title: <Text size="small">{I18n.t('order_list._tabs.all')}</Text> },
                      { id: 1, title: <Text size="small">{I18n.t('order_list._tabs.without_label')}</Text> },
                      { id: 2, title: <Text size="small">{I18n.t('order_list._tabs.with_label')}</Text> },
                      { id: 3, title: <Text size="small">{I18n.t('order_list._tabs.unfulfilled')}</Text> },
                      { id: 4, title: <Text size="small">{I18n.t('order_list._tabs.error')}</Text> },
                    ]}
                    activeId={this.state.selectedTabConfig}
                    type="uniformSide"
                    onClick={(value) => this.handleTabChange(value)}
                  />
                </Card>
                <Card className="notrounded">
                  <TableToolbar>
                    {this.state.selectedItems.length > 0 &&
                      <TableToolbar.ItemGroup position="start">
                        <TableToolbar.Item>
                          <Box gap="12px">
                            <Box gap="3px">
                              <Text size="small" weight="bold">
                                {this.state.selectedItems.length} {I18n.t('order_list.resources_items_orders_title')} {I18n.t('order_list.selected')}
                              </Text>
                              <Text size="small">{I18n.t('order_list.on_total')} {this.state.orders.length}</Text>
                            </Box>
                          </Box>
                        </TableToolbar.Item>
                        <TableToolbar.Item>
                          <Box height="18px">
                            <Divider direction="vertical" />
                          </Box>
                        </TableToolbar.Item>
                        <TableToolbar.Item layout="button">
                          <Button
                            skin="inverted"
                            size="small"
                            onClick={(e) => this.generateBulkLabelCheckModal()}
                            prefixIcon={<ShippingSmall />}
                          >
                            {I18n.t('order_list.bulk_generate_label')}
                          </Button>
                        </TableToolbar.Item>
                        <TableToolbar.Item layout="button">
                          <PopoverMenu
                            triggerElement={
                              <Button
                                skin="inverted"
                                size="small"
                                suffixIcon={<ChevronDownSmall />}
                              >
                                {I18n.t('order_list.more_actions')}
                              </Button>
                            }
                          >
                            <PopoverMenu.MenuItem
                              prefixIcon={<PrintSmall />}
                              text={I18n.t('order_list.bulk_print_label')}
                              onClick={(e) => this.printBulkLabel()}
                            />
                            <PopoverMenu.MenuItem
                              prefixIcon={<PrintSmall />}
                              text={I18n.t('order_list.bulk_print_return_label')}
                              onClick={(e) => this.printBulkReturnLabel()}
                            />
                          </PopoverMenu>
                        </TableToolbar.Item>
                      </TableToolbar.ItemGroup>
                    }
                    {this.state.selectedItems.length <= 0 &&
                      <TableToolbar.ItemGroup position="start">
                        <TableToolbar.Item>
                          <TableToolbar.Label>{this.state.orders.length}{I18n.t('order_list.resources_items_orders_title')}</TableToolbar.Label>
                        </TableToolbar.Item>
                      </TableToolbar.ItemGroup>
                    }
                    <TableToolbar.ItemGroup position="end">
                      <TableToolbar.Item>
                        <Button
                          size="small"
                          priority="secondary"
                          prefixIcon={<ContentFilterSmall />}
                          onClick={(e) => this.toggleActive("filterSide")}
                        >
                          {I18n.t('order_list.filters.filters')} (9)
                        </Button>
                      </TableToolbar.Item>
                      <TableToolbar.Item>
                        <Search name="queryValue" size="small" debounceMs={250} value={queryValue} onChange={this.handleChangeQueryValue}/>
                      </TableToolbar.Item>
                    </TableToolbar.ItemGroup>
                  </TableToolbar>
                  <Table.Titlebar />
                  <Table.SubToolbar>
                    <Box verticalAlign="middle" width="100%" align="space-between">
                      <Box gap="12px" verticalAlign="middle">
                        <Text size="small">{I18n.t('order_list.filters.filter_by')}</Text>
                        <TagList
                          tags={appliedFilters}
                          actionButton={{
                            label: I18n.t('order_list.filters.clear_all'),
                            onClick: () => this.handleClearAll(),
                          }}
                          onTagRemove={(e) => this.handleRemove(e)}
                        />
                      </Box>
                    </Box>
                  </Table.SubToolbar>
                </Card>
              </Page.Sticky>
              <Table.Content titleBarVisible={false}/>
              {emptyStateMarkup}
            </Table>
            <br/>
            <Layout justifyItems="center">
              <Cell>
                {paginationMarkup}
              </Cell>
            </Layout>
            <Box>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  right: `${filterSide ? 0 : -440}px`,
                  display: `${filterSide ? 'block' : 'none'}`,
                  height: '100%',
                  boxShadow:
                    '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
                  transition: 'right 0.4s ease 0s',
                  zIndex: 2000,
                }}
              >
                <SidePanel title="Filter" onCloseButtonClick={() => this.handleCloseSheet()}>
                  <SidePanel.Header title={I18n.t('order_list.filters.filters')} />
                  <SidePanel.Content className="Nopadding">
                  <Accordion
                    skin="light"
                    hideShadow
                    size="small"
                    items={[
                      {
                        title: I18n.t('order_list.filters.payment_status'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            {this.props.payment_status.map((value, key) => (
                              <Checkbox
                                checked={tagExists({
                                  type: 'paymentStatus',
                                  value: value,
                                })}
                                onChange={(e) => this.toggleTag('paymentStatus', value)}
                              >
                                {I18n.t(value)}
                              </Checkbox>
                            ))}
                            <TextButton size="small" onClick={(e) => this.handleClear('paymentStatus')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.fulfill_status'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            {this.props.fulfillment_status.map((value, key) => (
                              <Checkbox
                                checked={tagExists({
                                  type: 'fulfillmentStatus',
                                  value: value,
                                })}
                                onChange={(e) => this.toggleTag('fulfillmentStatus', value)}
                              >
                                {I18n.t(value)}
                              </Checkbox>
                            ))}
                            <TextButton size="small" onClick={(e) => this.handleClear('fulfillmentStatus')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.labelStatus'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            {LabelFilter.map((value, key) => (
                              <Radio
                                checked={tagExists({
                                  type: 'labelStatus',
                                  value: value.value,
                                })}
                                onChange={(e) => this.toggleTag('labelStatus', value.value)}
                                label={value.label}
                              />
                            ))}
                            <TextButton size="small" onClick={(e) => this.handleClear('labelStatus')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.printedStatus'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            {PrintedFilter.map((value, key) => (
                              <Radio
                                checked={tagExists({
                                  type: 'printedStatus',
                                  value: value.value,
                                })}
                                onChange={(e) => this.toggleTag('printedStatus', value.value)}
                                label={value.label}
                              />
                            ))}
                            <TextButton size="small" onClick={(e) => this.handleClear('printedStatus')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.errorStatus'),
                        children: (
                          <Box direction="vertical" gap="12px">
                              <Radio
                                checked={tagExists({
                                  type: 'errorStatus',
                                  value: 'error',
                                })}
                                onChange={(e) => this.toggleTag('errorStatus', 'error')}
                                label={I18n.t('order_list.filters.errorStatus')}
                              />
                              <TextButton size="small" onClick={(e) => this.handleClear('errorStatus')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.countries'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            {this.props.countries.map((value, key) => (
                              <Checkbox
                                checked={tagExists({
                                  type: 'countries',
                                  value: value,
                                })}
                                onChange={(e) => this.toggleTag('countries', value)}
                              >
                                {this.capitalize(value)}
                              </Checkbox>
                            ))}
                            <TextButton size="small" onClick={(e) => this.handleClear('countries')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.services'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            {this.props.services.map((value, key) => (
                              <Checkbox
                                checked={tagExists({
                                  type: 'services',
                                  value: value,
                                })}
                                onChange={(e) => this.toggleTag('services', value)}
                              >
                                {value}
                              </Checkbox>
                            ))}
                            <TextButton size="small" onClick={(e) => this.handleClear('services')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.weight'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            <Slider
                              marks={{
                                0: `0 Kg`,
                                5: `5 Kg`,
                                10: `10 Kg`,
                                30: `30 Kg`,
                                50: `50 Kg`,
                              }}
                              min={0}
                              max={50}
                              value={this.state.weight || [0, 50]}
                              onChange={(e) => { this.toggleTag('weight', e)}}
                            />
                            <TextButton size="small" onClick={(e) => this.handleClear('weight')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                      {
                        title: I18n.t('order_list.filters.date'),
                        children: (
                          <Box direction="vertical" gap="12px">
                            {dateFilter.map((value, key) => (
                              <Radio
                                checked={tagExists({
                                  type: 'date',
                                  value: value.value,
                                })}
                                onChange={(e) => this.toggleTag('date', value.value)}
                                label={value.label}
                              />
                            ))}
                            {this.state.date[0] == 'custom' && 
                              <Box direction="vertical">
                                <FormField label={I18n.t('order_list.filters.date_from')}>
                                  <DatePicker placeholderText="Date" size="medium" width="240px" value={this.state.date_from} onChange={this.handleChange('date_from')} />
                                </FormField>
                                <FormField label={I18n.t('order_list.filters.date_to')}>
                                  <DatePicker placeholderText="Date" size="medium" width="240px" value={this.state.date_to} onChange={this.handleChange('date_to')} />
                                </FormField>
                              </Box>
                            }
                            <TextButton size="small" onClick={(e) => this.handleClear('date')}>{I18n.t('order_list.filters.clear')}</TextButton>
                          </Box>
                        ),
                      },
                    ]}
                  />
                  </SidePanel.Content>
                  <SidePanel.Footer>
                    <Box gap="12px" paddingBottom="SP4">
                      <Text size="small">{appliedFilters.length} {I18n.t('order_list.filters.filters_applied')}</Text>
                      <TextButton size="small" onClick={(e) => this.handleClearAll()}>{I18n.t('order_list.filters.clear_all')}</TextButton>
                    </Box>
                    <Button priority="secondary" onClick={(e) => this.handleCloseSheet()} fullWidth>
                      {I18n.t('order_list.filters.close')}
                    </Button>
                  </SidePanel.Footer>
                </SidePanel>
              </div>
            </Box>
          </Page.Content>
        </Page>
        {!this.state.selectedOrder === false ? this.renderSheet(this.state.selectedOrder) : null}
        {!this.state.selectedLabels === false ? this.renderSheetLabels(this.state.selectedLabels) : null}
        {!this.state.selectedChange === false ? this.renderSheetChange(this.state.selectedChange) : null}
        {!this.state.selectedChangeOffer === false ? this.renderSheetChangeOffer(this.state.selectedChangeOffer) : null}
        <Modal
        isOpen={this.state.map_modal}
        onRequestClose={() => this.setState({map_modal: false})}
        shouldCloseOnOverlayClick
        screen="desktop"
        >
          <CustomModalLayout
            onCloseButtonClick={() => this.setState({map_modal: false})}
            title={I18n.t('front.modal_header')}
            content={
              <Box direction="vertical">
                <div style={{display: 'none'}}>
                  <input name="colissimo_order_service_update" id="order-update-webservice" value="" onClick={(e) => this.handleChange("colissimo_order_service_update")} type="hidden" ref={(orderUpdateWebservice) => { this.orderUpdateWebservice = orderUpdateWebservice }} />
                  <Button size="slim" primary={false} id="validPickup" onClick={(e) => this.handleValidPickup()}></Button>
                </div>
                <div id="colissimo-widget-container" class="wix-admin">
                  <Box direction="vertical" align="center" padding="SP4">
                    <Loader size="medium" />
                    <Text skin="standard">{I18n.t('front.please_wait')}</Text>
                    </Box>
                </div>
              </Box>
            }
          />
        </Modal>
        {modalChangeAddress ? this.displayModalAddress(orderShipping) : null}
        {modalChangeAddressPickup ? this.displayModalAddressPickup(orderShippingPickup) : null}
        {modalRegenerate ? this.displayModalRegenerate() : null}
        {modalRegenerateReturn ? this.displayModalRegenerateReturn() : null}
        {modalBulkGenerate ? this.displayModalBulkGenerate() : null}
        {modalImport ? this.displayModalImport() : null}
      </div>
    );
  }
}

function isEmpty(value) {
  if (Array.isArray(value)) {
      if (value[0] == "custom") {
          return true;
      }
      return value.length === 0;
  } else {
      /* Add specific Hack for custom value */
      return value === '' || value == null;
  }
}

export default OrderIndex