import React from "react";

import {
  Card,
  Image,
  Layout,
  Box,
  Cell,
  Text,
  Accordion
} from '@wix/design-system';

import ScreenFAQ3 from "../../../assets/images/screenFAQ_3.png";
import ScreenFAQ4 from "../../../assets/images/screenFAQ_4.png";
import ScreenFAQ5 from "../../../assets/images/screenFAQ_5.png";
import ScreenFAQ6 from "../../../assets/images/screenFAQ_6.png";
import ScreenFAQ7 from "../../../assets/images/screenFAQ_7.png";

class Faq extends React.Component {
  render() {
    return (
      <Layout cols={12}>
        <Cell span={12}>
          <Card>
            <Card.Content>
            <Accordion
              skin="light"
              hideShadow
              size="small"
              items={[
                {
                  title: I18n.t('support.slide3.faq1.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq1.text1')} <b>02 41 74 20 88</b></Text>
                    </Box>
                  ),
                },
                {
                  title: I18n.t('support.slide3.faq2.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq2.text1')}</Text>
                    </Box>
                  ),
                },
                {
                  title: I18n.t('support.slide3.faq3.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq3.text1')}</Text>
                      <br/><br/>
                      <Image src={ScreenFAQ3} />
                    </Box>
                  ),
                },
                {
                  title: I18n.t('support.slide3.faq4.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq4.text1')}</Text>
                      <br/><br/>
                      <Image src={ScreenFAQ4} />
                    </Box>
                  ),
                },
                {
                  title: I18n.t('support.slide3.faq5.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq5.text1')}</Text>
                      <br/><br/>
                      <Image src={ScreenFAQ5} />
                      <Image src={ScreenFAQ6} />
                    </Box>
                  ),
                },
                {
                  title: I18n.t('support.slide3.faq6.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq6.text1')}</Text>
                      <br/><br/>
                      <Image src={ScreenFAQ7} />
                    </Box>
                  ),
                },
                {
                  title: I18n.t('support.slide3.faq8.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq8.text1')}</Text>
                      <br/>
                      <Text>
                        <ul>
                          <li>{I18n.t('support.slide3.faq8.li1')}</li>
                          <li>{I18n.t('support.slide3.faq8.li2')}</li>
                          <li>{I18n.t('support.slide3.faq8.li3')}</li>
                          <li>{I18n.t('support.slide3.faq8.li4')}</li>
                          <li>{I18n.t('support.slide3.faq8.li5')}</li>
                          <li>{I18n.t('support.slide3.faq8.li6')}</li>
                          <li>{I18n.t('support.slide3.faq8.li7')}</li>
                          <li>{I18n.t('support.slide3.faq8.li8')}</li>
                          <li>{I18n.t('support.slide3.faq8.li9')}</li>
                          <li>{I18n.t('support.slide3.faq8.li10')}</li>
                          <li>{I18n.t('support.slide3.faq8.li11')}</li>
                          <li>{I18n.t('support.slide3.faq8.li12')}</li>
                          <li>{I18n.t('support.slide3.faq8.li13')}</li>
                          <li>{I18n.t('support.slide3.faq8.li14')}</li>
                          <li>{I18n.t('support.slide3.faq8.li15')}</li>
                          <li>{I18n.t('support.slide3.faq8.li16')}</li>
                          <li>{I18n.t('support.slide3.faq8.li17')}</li>
                          <li>{I18n.t('support.slide3.faq8.li18')}</li>
                          <li>{I18n.t('support.slide3.faq8.li19')}</li>
                          <li>{I18n.t('support.slide3.faq8.li20')}</li>
                          <li>{I18n.t('support.slide3.faq8.li21')}</li>
                          <li>{I18n.t('support.slide3.faq8.li22')}</li>
                          <li>{I18n.t('support.slide3.faq8.li23')}</li>
                          <li>{I18n.t('support.slide3.faq8.li24')}</li>
                          <li>{I18n.t('support.slide3.faq8.li25')}</li>
                          <li>{I18n.t('support.slide3.faq8.li26')}</li>
                        </ul>
                      </Text>
                    </Box>
                  ),
                },
                {
                  title: I18n.t('support.slide3.faq9.title'),
                  children: (
                    <Box direction="vertical" gap="12px">
                      <Text>{I18n.t('support.slide3.faq9.text1')}</Text>
                      <br/>
                      <Text>
                        <ul>
                          <li>{I18n.t('support.slide3.faq9.li1')}</li>
                          <li>{I18n.t('support.slide3.faq9.li2')}</li>
                          <li>{I18n.t('support.slide3.faq9.li3')}</li>
                          <li>{I18n.t('support.slide3.faq9.li4')}</li>
                          <li>{I18n.t('support.slide3.faq9.li5')}</li>
                          <li>{I18n.t('support.slide3.faq9.li6')}</li>
                          <li>{I18n.t('support.slide3.faq9.li7')}</li>
                        </ul>
                      </Text>
                    </Box>
                  ),
                },
              ]}
            />
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
    )
  }
}

export default Faq