import React from "react";
import {
  Card,
  Button,
  Table,
  Image,
  Layout,
  Box,
  Cell,
  Text,
} from '@wix/design-system';

class Video extends React.Component {

  render() {
    return (
      <Layout cols={12}>
        <Cell span={12}>
          <Card>
            <Card.Content>
              <Table.EmptyState
                className="NoTopBorder"
                subtitle={<Box direction="vertical" paddingTop="SP2">
                  <Box><br/></Box>
                  <Box align="center"><Text weight="bold">{I18n.t('support.slide5.tuto_video')}</Text></Box>
                  <Box align="center">{I18n.t('support.slide5.tuto_video_text1')}</Box>
                  <Box align="center">{I18n.t('support.slide5.tuto_video_text2')}</Box>
                  <Box align="center">{I18n.t('support.slide5.tuto_video_text3')}</Box>
                </Box>}
                image={
                  <Image
                    height={226}
                    width={226}
                    src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/video_siege_226.svg?v=1652261794"
                    transparent
                  />
                }
              >
                <Button as="a" href="https://loom.com/share/folder/c13b118bcbe94b7f8dde5926bacfae92" target="_blank">{I18n.t('support.slide5.view_videos_button')}</Button>
              </Table.EmptyState>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
    );
  }
}

export default Video