import React from "react";
import PropTypes from "prop-types";
import {
  Cell,
  Card,
  Layout,
  MarketingLayout,
  SectionHelper,
  Image,
  Box,
  Text,
  Divider,
  Button,
  Accordion
} from '@wix/design-system';

import Step1 from "../../../assets/images/step1.png";
import Step2 from "../../../assets/images/step2.png";
import Step3 from "../../../assets/images/step3.png";
import Step4 from "../../../assets/images/step4.png";
import Step5 from "../../../assets/images/step5.png";
import Step6 from "../../../assets/images/step6.png";
import Step7 from "../../../assets/images/step7.png";
import ColissimoStep1 from "../../../assets/images/colissimo-1.png";
import ColissimoStep2 from "../../../assets/images/colissimo-2.png";
import ColissimoStep3 from "../../../assets/images/colissimo-3.png";

class Carrier extends React.Component {

  render() {

    const descriptionSection = (<Box direction="vertical">
        <Box className="carrier">
          <Text size="small">
            <p>{I18n.t('support.slide2.bloc1.text1')}</p>
            <p>
              <ul>
                <li>{I18n.t('support.slide2.bloc1.text3')}</li>
                <li>{I18n.t('support.slide2.bloc1.text4')}</li>
                <li>{I18n.t('support.slide2.bloc1.text5')}</li>
                <li>{I18n.t('support.slide2.bloc1.text6')}</li>
              </ul>
            </p>
            <p>{I18n.t('support.slide2.bloc1.text2')}<b>{I18n.t('support.slide2.bloc1.title')}</b></p>
          </Text>
        </Box>
        <br/>
        <Box>
          <Button as="a" href="https://www.loom.com/share/fc764885b62b45d89792ec66055bf8e3?sid=e2d66626-afea-4de9-8df9-3dde3ade4c72" target="_blank">{I18n.t('configs.view_video_button')}</Button>
        </Box>
      </Box>
    );

    return (
      <div class="carrier_page">
      <Layout cols={12}>
        <Cell span={4}>
          <Card>
            <MarketingLayout
              title={<Box>{I18n.t('support.slide2.bloc1.title')}</Box>}
              description={descriptionSection}
              size="tiny"
            />
          </Card>
        </Cell>
        <Cell span={8}>
          <Card>
            <Card.Content>
              <Cell>
                <Image src={Step1} />
              </Cell>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
      <Divider />
      <Layout cols={12}>
        <Cell span={4}>
          <Card>
            <MarketingLayout
              title={I18n.t('support.slide2.bloc2.title')}
              description={<Box className="carrier" direction="vertical"><Text size="small">{I18n.t('support.slide2.bloc2.text1')}</Text></Box>}
              size="tiny"
            />
          </Card>
        </Cell>
        <Cell span={8}>
          <Card>
            <Card.Content>
              <Cell>
                <Image src={Step2} />
                <br/>
                <Image src={Step3} />
              </Cell>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
      <Divider />
      <Layout cols={12}>
        <Cell span={4}>
          <Card className="carrier">
            <MarketingLayout
              title={I18n.t('support.slide2.bloc3.title')}
              description={
                <Box className="carrier" direction="vertical">
                  <Text size="small">
                    <p>{I18n.t('support.slide2.bloc3.text1')}</p>
                    <p>
                      <ul>
                        <li>{I18n.t('support.slide2.bloc3.li1')}</li>
                        <li>{I18n.t('support.slide2.bloc3.li2')}</li>
                        <li>{I18n.t('support.slide2.bloc3.li3')}</li>
                        <li>{I18n.t('support.slide2.bloc3.li4')}</li>
                        <li>{I18n.t('support.slide2.bloc3.li5')}</li>
                      </ul>
                    </p>
                    <p>{I18n.t('support.slide2.bloc1.text2')}<b>{I18n.t('support.slide2.bloc1.title')}</b></p>
                  </Text>
                </Box>
              }
              size="tiny"
            />
          </Card>
        </Cell>
        <Cell span={8}>
          <Card>
            <Card.Content>
              <Cell>
                <Image src={Step4} />
              </Cell>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
      <Divider />
      <Layout cols={12}>
        <Cell span={4}>
          <Card className="carrier">
            <MarketingLayout
              title={I18n.t('support.slide2.bloc5.title')}
              description={
                <Box className="carrier" direction="vertical">
                  <Box>
                    <SectionHelper fullWidth appearance="standard">
                      <Text size="small">
                        <p>
                          {I18n.t('support.slide2.bloc5.errorText1')} <b>{I18n.t('support.slide2.bloc5.errorText2')}</b>.
                        </p><br/>
                        <p>
                            {I18n.t('support.slide2.bloc5.errorText3')} <b>{I18n.t('support.slide2.bloc5.errorText4')}</b> {I18n.t('support.slide2.bloc5.errorText5')} <b>{I18n.t('support.slide2.bloc5.errorText6')}</b>.
                        </p><br/>
                        <p>
                            {I18n.t('support.slide2.bloc5.errorText7')}
                            <ul>
                              <li>{I18n.t('support.slide2.bloc5.li1')}</li>
                              <li>{I18n.t('support.slide2.bloc5.li2')}</li>
                              <li>{I18n.t('support.slide2.bloc5.li3')}</li>
                              <li>{I18n.t('support.slide2.bloc5.li4')}</li>
                              <li>{I18n.t('support.slide2.bloc5.li5')}</li>
                              <li>{I18n.t('support.slide2.bloc5.li6')}</li>
                              <li>{I18n.t('support.slide2.bloc5.li7')}</li>
                              <li>{I18n.t('support.slide2.bloc5.li8')}</li>
                            </ul>
                        </p>
                      </Text>
                    </SectionHelper>
                  </Box>
                  <Box>
                    <Text size="small">
                      <p>
                        {I18n.t('support.slide2.bloc5.carrier1.text1')}&nbsp;<Image src={ColissimoStep1} width="16px" />
                      </p><br/>

                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc5.carrier1.title'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    {I18n.t('support.slide2.bloc5.carrier1.zone')}
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc5.carrier1.li1')}</li>
                                      <li>{I18n.t('support.slide2.bloc5.carrier1.li2')}</li>
                                      <li>{I18n.t('support.slide2.bloc5.carrier1.li3')}</li>
                                    </ul>
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                      <p>
                      {I18n.t('support.slide2.bloc5.carrier1.text2')}&nbsp;<Image src={ColissimoStep2} width="16px" />
                      </p>
                      <p>
                        {I18n.t('support.slide2.bloc5.carrier1.text3')}&nbsp;<Image src={ColissimoStep3} width="16px" />
                      </p>
                      <p>
                        <b>{I18n.t('support.slide2.bloc5.carrier1.text4')}</b>
                      </p><br/>
                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc5.carrier2.title'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    {I18n.t('support.slide2.bloc5.carrier2.zone')}
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc5.carrier2.li1')}</li>
                                      <li>{I18n.t('support.slide2.bloc5.carrier2.li2')}</li>
                                      <li>{I18n.t('support.slide2.bloc5.carrier2.li3')}</li>
                                      <li>{I18n.t('support.slide2.bloc5.carrier2.li4')}</li>
                                      <li>{I18n.t('support.slide2.bloc5.carrier2.li5')}</li>
                                    </ul>
                                  </p>
                                </Text>
                              </Box>
                            ),
                          },
                          {
                            title: I18n.t('support.slide2.bloc5.carrier3.title'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    <b>{I18n.t('support.slide2.bloc5.carrier3.subtitle')}</b>
                                  </p>
                                  <p>
                                    {I18n.t('support.slide2.bloc5.carrier3.zone')}
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc5.carrier3.li1')}</li>
                                      <li>{I18n.t('support.slide2.bloc5.carrier3.li2')}</li>
                                    </ul>
                                  </p>
                                </Text>
                              </Box>
                            ),
                          },
                          {
                            title: I18n.t('support.slide2.bloc5.carrier4.title'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    {I18n.t('support.slide2.bloc5.carrier4.zone')}
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc5.carrier4.li1')}</li>
                                    </ul>
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                    </Text>
                  </Box>
                </Box>
              }
              size="tiny"
            />
          </Card>
        </Cell>
        <Cell span={8}>
          <Card>
            <Card.Content>
              <Cell>
                <Image src={Step5} />
                <br/>
                <Image src={Step6} />
              </Cell>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
      <Divider />
      <Layout cols={12}>
        <Cell span={4}>
          <Card className="carrier">
            <MarketingLayout
              title={I18n.t('support.slide2.bloc4.title')}
              description={
                <Box className="carrier" direction="vertical">
                <Text size="small">
                  <Box direction="vertical">
                    <Box direction="vertical">
                      <p><b><u>{I18n.t('support.slide2.bloc4.zone1.subtitle')}</u></b></p>
                      <p>
                        {I18n.t('support.slide2.bloc4.zone1.text1')}&nbsp;<Image src={ColissimoStep1} width="16px" />
                      </p>
                    </Box>
                    <Box direction="vertical">
                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc4.zone1.text2'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    <b>{I18n.t('support.slide2.bloc4.zone1.text3')}&nbsp;<Image src={ColissimoStep2} width="16px" /></b>
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc4.zone1.li1')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone1.li2')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone1.li3')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone1.li4')}</li>
                                    </ul>
                                    {I18n.t('support.slide2.bloc4.zone1.text4')}&nbsp;<Image src={ColissimoStep3} width="16px" />
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                    </Box>
                    <br/>
                    <Box direction="vertical">
                      <p><b>{I18n.t('support.slide2.bloc4.zone1.text5')}</b></p>
                      <p><b><u>{I18n.t('support.slide2.bloc4.zone2.subtitle')}</u></b></p>
                      <p>
                        {I18n.t('support.slide2.bloc4.zone2.text1')}&nbsp;<Image src={ColissimoStep1} width="16px" />
                      </p>
                    </Box>
                    <Box direction="vertical">
                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc4.zone2.text2'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                <p>
                                    <b>{I18n.t('support.slide2.bloc4.zone2.text3')}&nbsp;<Image src={ColissimoStep2} width="16px" /></b>
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li1')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li2')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li3')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li4')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li5')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li6')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li7')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li8')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li9')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li10')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li11')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li12')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li13')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li14')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li15')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li16')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone2.li17')}</li>
                                    </ul>
                                    {I18n.t('support.slide2.bloc4.zone2.text4')}&nbsp;<Image src={ColissimoStep3} width="16px" />
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                    </Box>
                    <br/>
                    <Box direction="vertical">
                      <p><b><u>{I18n.t('support.slide2.bloc4.zone3.subtitle')}</u></b></p>
                      <p>
                        {I18n.t('support.slide2.bloc4.zone3.text1')}&nbsp;<Image src={ColissimoStep1} width="16px" />
                      </p>
                    </Box>
                    <Box direction="vertical">
                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc4.zone3.text2'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    <b>{I18n.t('support.slide2.bloc4.zone3.text3')}&nbsp;<Image src={ColissimoStep2} width="16px" /></b>
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li1')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li2')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li3')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li4')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li5')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li6')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li7')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li8')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li9')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li10')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone3.li11')}</li>
                                    </ul>
                                    {I18n.t('support.slide2.bloc4.zone3.text4')}&nbsp;<Image src={ColissimoStep3} width="16px" />
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                    </Box>
                    <br/>
                    <Box direction="vertical">
                      <p><b><u>{I18n.t('support.slide2.bloc4.zone4.subtitle')}</u></b></p>
                      <p>
                        {I18n.t('support.slide2.bloc4.zone4.text1')}&nbsp;<Image src={ColissimoStep1} width="16px" />
                      </p>
                    </Box>
                    <Box direction="vertical">
                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc4.zone4.text2'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    <b>{I18n.t('support.slide2.bloc4.zone4.text3')}&nbsp;<Image src={ColissimoStep2} width="16px" /></b>
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li1')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li2')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li3')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li4')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li5')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li6')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li7')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li8')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li9')}</li>
                                      <li>{I18n.t('support.slide2.bloc4.zone4.li10')}</li>
                                    </ul>
                                    {I18n.t('support.slide2.bloc4.zone4.text4')}&nbsp;<Image src={ColissimoStep3} width="16px" />
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                    </Box>
                    <br/>
                    <Box direction="vertical">
                      <p><b><u>{I18n.t('support.slide2.bloc4.zone5.subtitle')}</u></b></p>
                      <p>
                        {I18n.t('support.slide2.bloc4.zone5.text1')}&nbsp;<Image src={ColissimoStep1} width="16px" />
                      </p>
                    </Box>
                    <Box direction="vertical">
                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc4.zone5.text2'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    <b>{I18n.t('support.slide2.bloc4.zone5.text3')}&nbsp;<Image src={ColissimoStep2} width="16px" /></b>
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc4.zone5.li1')}</li>
                                    </ul>
                                    {I18n.t('support.slide2.bloc4.zone5.text4')}&nbsp;<Image src={ColissimoStep3} width="16px" />
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                    </Box>
                    <br/>
                    <Box direction="vertical">
                      <p><b><u>{I18n.t('support.slide2.bloc4.zone6.subtitle')}</u></b></p>
                      <p>
                        {I18n.t('support.slide2.bloc4.zone6.text1')}&nbsp;<Image src={ColissimoStep1} width="16px" />
                      </p>
                    </Box>
                    <Box direction="vertical">
                      <Accordion
                        skin="light"
                        hideShadow
                        size="small"
                        items={[
                          {
                            title: I18n.t('support.slide2.bloc4.zone6.text2'),
                            children: (
                              <Box direction="vertical" gap="12px" className="carrier_layout">
                                <Text size="small">
                                  <p>
                                    <b>{I18n.t('support.slide2.bloc4.zone6.text3')}&nbsp;<Image src={ColissimoStep2} width="16px" /></b>
                                    <ul>
                                      <li>{I18n.t('support.slide2.bloc4.zone6.li1')}</li>
                                    </ul>
                                    {I18n.t('support.slide2.bloc4.zone6.text4')}&nbsp;<Image src={ColissimoStep3} width="16px" />
                                  </p>
                                </Text>
                              </Box>
                            ),
                          }
                      ]} />
                    </Box>
                  </Box>
                </Text>
                </Box>
              }
              size="tiny"
            />
          </Card>
        </Cell>
        <Cell span={8}>
          <Card>
            <Card.Content>
              <Cell>
                <Image src={Step7} />
              </Cell>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
      </div>
    );
  }
}

export default Carrier