import React from "react";
import {
  Page,
  Card,
  Button,
  Box,
  Badge,
  Text,
  Heading,
  Divider,
  SidePanel,
  TableListHeader,
  Tabs,
  Image
} from '@wix/design-system';

import { PrintSmall } from '@wix/wix-ui-icons-common';

import ByLabelWithoutDeposit from "./ByLabelWithoutDeposit"
import ByLabelWithDeposit from "./ByLabelWithDeposit"
import ByDeposit from "./ByDeposit"
import LogoColissimo from "../../../assets/images/Colissimo_Logo_H.png";

import { passCsrfToken } from '../../util/helpers'
import axios from 'axios'

class DepositGlobal extends React.Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  state = {
    selectTab: 0,
    selectedOrder: false,
    sheetActive: false,
    sheetDepositActive: false,
    selectedDeposit: false,
  };

  componentDidMount = () => {
    passCsrfToken(document, axios);
  }

  callRequest = (event) => {
    event.preventDefault();
    this.child.current.callRequest();
  }

  handleCloseSheet = () => {
    this.setState({ sheetActive: false });
    this.setState({ sheetDepositActive: false });
  };

  handleSelectedTab = (value) => {
    this.setState({selectTab: value.id});
  }

  handleSheetChange = (value) => {
    this.setState({ sheetActive: value });
  };

  handleSheetDepositChange = (value) => {
    this.setState({ sheetDepositActive: value });
  };

  handleOrderChange = (value) => {
    this.setState({ selectedOrder: value });
  };

  handleDepositChange = (value) => {
    this.setState({ selectedDeposit: value });
  };

  printLabel = (id) => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    axios({
      method: 'get',
      url: '/colissimo-label/'+id+'/'+this.props.shop.instance_id
    }).then(result => {
      if (result.data.printer_request) {
          $.get(result.data.printer_request);
      } else {
        this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
      }
    }).catch(error => {
      console.log(error);
      this.props.toggleToast(I18n.t('order_list.error_print_bulk_label'), true);
    });
  };

  renderSwitch() {
    //updateStatus depend on App function handleChildStatusChange
    switch(this.state.selectTab) {
      case 0:
        return (
          <ByLabelWithoutDeposit {...this.props}
            labels={this.props.labels}
            services={this.props.services}
            toggleToast={this.props.toggleToast}
            contentOverlay={this.props.contentOverlay}
            handleOrderChange={this.handleOrderChange}
            handleSheetChange={this.handleSheetChange}
            sheetActive={this.state.sheetActive}
            shop={this.props.shop}
            ref={this.child}
          />
        );
      case 1:
        return (
            <ByLabelWithDeposit {...this.props}
            labels={this.props.labels}
            services={this.props.services}
            toggleToast={this.props.toggleToast}
            contentOverlay={this.props.contentOverlay} 
            handleOrderChange={this.handleOrderChange}
            handleSheetChange={this.handleSheetChange}
            sheetActive={this.state.sheetActive}
            shop={this.props.shop}
            ref={this.child}
          />
          
        );
      case 2:
        return (
          <ByDeposit {...this.props}
            labels={this.props.labels}
            toggleToast={this.props.toggleToast}
            contentOverlay={this.props.contentOverlay}
            handleDepositChange={this.handleDepositChange}
            handleSheetDepositChange={this.handleSheetDepositChange}
            sheetDepositActive={this.state.sheetDepositActive}
            shop={this.props.shop}
            ref={this.child}
          />
        );
      default:
        return (
          <ByLabelWithoutDeposit {...this.props}
            labels={this.props.labels}
            services={this.props.services}
            toggleToast={this.props.toggleToast}
            contentOverlay={this.props.contentOverlay} 
            handleOrderChange={this.handleOrderChange}
            handleSheetChange={this.handleSheetChange}
            sheetActive={this.state.sheetActive}
            shop={this.props.shop}
            ref={this.child}
          />
        );
      }
    }
  
  renderSheetDeposit = (deposit) => {
    const sheetActive = this.state.sheetDepositActive;

    console.log(deposit);
    const title = (<Box><Heading size="small">{I18n.t('deposit_list.deposit_number')} {deposit.number}</Heading></Box>);

    const labels = deposit.labels.labels;
    console.log(labels);

    const label_lines = labels.map(line => {
      return (
      <Box direction="vertical" padding="SP2">
        <Text weight="bold" size="tiny">{I18n.t('order_list.order_number')} : #{line.wix_order_number}</Text>
        <Text size="tiny">{I18n.t('order_list.label_number')} : {line.shipping_number}</Text>
        <Box direction="vertical" paddingTop="SP2" paddingBottom="SP2">
          {( this.props.config.use_thermal_printer == true) && 
            <Button
              priority="secondary"
              size="small"
              prefixIcon={<PrintSmall />}
              onClick={(e) => this.printLabel(line.id)}
            >
              {I18n.t('order_list.print')}
            </Button>
          }
          {( this.props.config.use_thermal_printer == false) && 
            <Button
              priority="secondary"
              size="small"
              prefixIcon={<PrintSmall />}
              as="a" href={`/colissimo-label/${line.id}/${this.props.shop.instance_id}`} target="_blank"
            >
              {I18n.t('order_list.print')}
            </Button>
          }
        </Box>
        <Box direction="vertical" paddingTop="SP2">
          <Divider />
        </Box>
      </Box>
      );
    });
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: `${sheetActive ? 0 : -440}px`,
          height: '100%',
          boxShadow:
            '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
          transition: 'right 0.4s ease 0s',
          zIndex: 2000,
        }}
      >
        <SidePanel title="Filter" onCloseButtonClick={this.handleCloseSheet}>
          <SidePanel.Header title={title} showDivider={true} />
          <SidePanel.Content>
            <Box direction="vertical" gap="18px">
              {label_lines.length > 0 &&
                label_lines
              }
            </Box>
          </SidePanel.Content>
        </SidePanel>
      </div>
    );
  }

  /* RENDER ORDER DETAIL */
  renderSheet = (order) => {
    const sheetActive = this.state.sheetActive;
    const { 
      id,
      wix_order_number,
      payment_status,
      total_price,
      currency
    } = order.order

    const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: currency
    });

    const {
      address1,
      address2,
      city,
      company,
      country,
      first_name,
      last_name,
      phone,
      zip
    } = order.order_shipping

    const order_lines = order.order_line_items

    const pickup_point = order.pickup_point

    const order_shipping_line = (order.order_shipping_line[0] ? order.order_shipping_line[0] : false);

    var payment_status_badge = '';

    if (payment_status == 'PARTIALLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="general" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="success" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'FULLY_REFUNDED') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="neutral" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PENDING') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="warning" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'NOT_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="danger" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'PARTIALLY_PAID') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="urgent" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    } else if (payment_status == 'UNSPECIFIED_PAYMENT_STATUS') {
      payment_status_badge = (<Box><Heading size="small">{I18n.t('order_list.sheet.title')} #{wix_order_number}</Heading>&nbsp;<Badge skin="standard" size="tiny">{I18n.t(`${payment_status}`)}</Badge></Box>);
    }

    const fulfill_lines = order_lines.filter(line => (line.fulfillable_quantity === 0 || (line.quantity - line.fulfillable_quantity) > 0 || payment_status == 'FULLY_REFUNDED')).map(line => {
      return (
      <Box direction="vertical" padding="SP2">
        <Text weight="bold" size="tiny">{line.name}</Text>
        <Text size="tiny">{formatter.format(`${line.price}`)} x {line.quantity}</Text>
        <Text weight="bold" size="tiny">SKU : {line.sku}</Text>
        <Box direction="vertical" paddingTop="SP2">
          <Badge skin="success">{I18n.t('order_list.sheet.qty_fulfilled_refunded')} {line.quantity - line.fulfillable_quantity}</Badge>
        </Box>
        <Box direction="vertical" paddingTop="SP2" paddingBottom="SP2">
          <Divider />
        </Box>
      </Box>
      );
    });

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: `${sheetActive ? 0 : -440}px`,
          height: '100%',
          boxShadow:
            '0 3px 24px 0 rgba(22, 45, 61, 0.18), 0 8px 8px 0 rgba(22, 45, 61, 0.12)',
          transition: 'right 0.4s ease 0s',
          zIndex: 2000,
        }}
      >
        <SidePanel title="Filter" onCloseButtonClick={this.handleCloseSheet}>
          <SidePanel.Header title={payment_status_badge} showDivider={true} />
          <SidePanel.Content>
            <Box direction="vertical" gap="18px">
              <Heading size="small">{I18n.t('order_list.sheet.carrier')}</Heading>
              <Text size="small" secondary>{order_shipping_line && order_shipping_line.delivery_option}</Text>
              <Divider />
              <Heading size="small">{I18n.t('order_list.sheet.address')}</Heading>
              {pickup_point === false &&
                <Text size="small">
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {company && <div>{company}</div>}
                  {address1 && <div>{address1}</div>}
                  {address2 && <div>{address2}</div>}
                  {zip && <div>{zip} {city}</div>} 
                  {country && <div>{country}</div>}
                  {phone && <div>{phone}</div>}
                </Text>
              }
              {pickup_point !== false && 
                <Text size="small">
                  <div><b>{I18n.t('order_list.pickup_point_number')} {pickup_point.colissimo_id}</b></div>
                  <div>{first_name && first_name}&nbsp;{last_name && last_name}</div>
                  {pickup_point.company_name && <div>{pickup_point.company_name}</div>}
                  {pickup_point.address1 && <div>{pickup_point.address1}</div>}
                  {pickup_point.address2 && <div>{pickup_point.address2}</div>}
                  {pickup_point.zipcode && <div>{pickup_point.zipcode} {pickup_point.city}</div>} 
                  {pickup_point.country && <div>{pickup_point.country}</div>}
                  {pickup_point.phone && <div>{pickup_point.phone}</div>}
                </Text>
              }
              <Divider />
              {fulfill_lines.length > 0 &&
                <Box direction="vertical"> 
                  <TableListHeader options={[{ value: I18n.t('order_list.sheet.fulfill_product') }]} />
                  {fulfill_lines}
                </Box>
              }
              <Box direction="vertical" padding="SP2" backgroundColor="D60" color="D10">
                <Text weight="bold" size="tiny">{`Total ${formatter.format(total_price)}`}</Text>
              </Box>
            </Box>
          </SidePanel.Content>
        </SidePanel>
      </div>
    );
  };

  /* RENDER GENERAL ORDER VIEW */
  render() {
    return (
      <div>
        <Page className="fullWidth" height="120vh">
          <Page.Header
            title={I18n.t('deposit_list.resources_items_deposits_title')}
            subtitle={<Image src={LogoColissimo} width="60" transparent />}
            actionsBar={<Button onClick={(e) => this.callRequest(e)}>{I18n.t('order_list.refresh')}</Button>}
          ></Page.Header>
          <Page.Content>
            <Card className="notroundedBottom">
              <Page.Sticky>
                <Card className="notroundedBottom">
                  <Tabs
                    items={[
                      { id: 0, title: <Text size="small">{I18n.t('deposit_list._tabs.without_deposit')}</Text> },
                      { id: 1, title: <Text size="small">{I18n.t('deposit_list._tabs.with_deposit')}</Text> },
                      { id: 2, title: <Text size="small">{I18n.t('deposit_list._tabs.deposit')}</Text> },
                    ]}
                    activeId={this.state.selectTab}
                    type="uniformSide"
                    onClick={(value) => this.handleSelectedTab(value)}
                  />
                </Card>
              </Page.Sticky>
              {this.renderSwitch()}
            </Card>
          </Page.Content>
        </Page>
        {!this.state.selectedOrder === false ? this.renderSheet(this.state.selectedOrder) : null}
        {!this.state.selectedDeposit === false ? this.renderSheetDeposit(this.state.selectedDeposit) : null}
      </div>
    );
  }
}
    
export default DepositGlobal