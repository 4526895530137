import React from "react";
import {
  Card,
  Button,
  Modal,
  MarketingLayout,
  SectionHelper,
  Text,
  Layout,
  Cell,
  CustomModalLayout,
  Box,
  Loader
} from '@wix/design-system';

import { DownloadImportSmall } from '@wix/wix-ui-icons-common';

import { passCsrfToken } from '../../util/helpers'
import axios from 'axios'

class Orders extends React.Component {
    
  state = {
    config:  this.props.configData,
    loading: false,
    modalImportConfirm: false,
  };

  componentDidMount = () => {
    passCsrfToken(document, axios);
  }

  importOrders = () => {
    this.setState({ modalImportConfirm: true });
  }

  displayModalImport = () => {
    return (
      <Modal
        isOpen={this.state.modalImportConfirm}
        onRequestClose={() => this.setState({ modalImportConfirm: false })}
        shouldCloseOnOverlayClick
        screen="desktop"
      >
        <CustomModalLayout
            primaryButtonText={I18n.t('configs.modal.confirm_import')}
            secondaryButtonText={I18n.t('deposit_list.modal.cancel_button')}
            onCloseButtonClick={() => this.setState({ modalImportConfirm: false })}
            secondaryButtonOnClick={() => this.setState({ modalImportConfirm: false })}
            primaryButtonOnClick={() => this.processImportOrders()}
            title={I18n.t('configs.modal.synchronized_order_title')}
            content={
              <Box direction="vertical" marginBottom="SP2">
                <Text>{I18n.t('configs.modal.confirm_import')}</Text>
              </Box>
            }
        ></CustomModalLayout>
      </Modal>
    );
  }

  processImportOrders = () => {
    if (this.props.showToast) {
      this.props.toggleToast();
    }
    this.setState({loading: true});
    this.setState({ modalImportConfirm: false });
    /* We import orders */
    axios({
      method: 'post',
      url: '/bulk-import-orders',
      data: {
        instance_id: this.props.shop.instance_id
      }
    }).then(result => {
      this.setState({loading: false});
      if (result.data.success == true) {
        this.props.toggleToast( I18n.t('configs.synchronized_order.success_message'), false);
      } else {
        this.props.toggleToast( I18n.t('configs.synchronized_order.error_message'), true);
      }
      this.setState({
        config: { ...this.state.config, ['synchronized_order']: true },
        });
    }).catch(error => {
      this.props.toggleToast( I18n.t('configs.synchronized_order.error_message'), true);
    });
    this.setState({loading: false});
  }

    render() {
      const {
        config,
        modalImportConfirm,
        loading
      } = this.state;

      return (
        <div>
          <Modal
          screen="desktop"
          isOpen={loading}
        >
          <Loader size="large" />
        </Modal>
          <Layout cols={12}>
            <Cell span={4}>
              <Card>
                <MarketingLayout
                  title={I18n.t('configs.synchronized_order.title')}
                  description={I18n.t('configs.synchronized_order.help')}
                  size="tiny"
                />
              </Card>
            </Cell>
            <Cell span={8}>
              <Card>
                <Card.Content>
                  {!config.synchronized_order && <SectionHelper fullWidth appearance="standard" title={I18n.t('configs.synchronized_order.title_banner')}>
                      <Text>
                        {I18n.t('configs.synchronized_order.text_banner')}
                      </Text>
                      <br/><br/>
                      <Button onClick={(e) => this.importOrders()}
                        prefixIcon={<DownloadImportSmall />}
                      >
                        {I18n.t('configs.import_orders')}
                      </Button>
                    </SectionHelper>
                  }
                  {config.synchronized_order && <SectionHelper fullWidth title={I18n.t('configs.synchronized_order.title_banner')}>
                      <Text>
                        {I18n.t('configs.synchronized_order.already_text_banner')}
                      </Text>
                    </SectionHelper>
                  }
                </Card.Content>
              </Card>
            </Cell>
          </Layout>
          {modalImportConfirm ? this.displayModalImport() : null}
        </div>
      );
    }
}

export default Orders
