import React from 'react';
import { 
  Modal,
  CustomModalLayout,
  Cell,
  Layout,
  Card,
  Box,
  Badge,
  Text,
  Image,
  Heading
} from '@wix/design-system';

class WhoIsContent extends React.Component {

    render() {
        return(
          <Modal
            isOpen={this.props.whoisPage}
            onRequestClose={() => this.props.contentOverlay('whoisPage', false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              secondaryButtonText={I18n.t('installguide.close_modal')}
              secondaryButtonOnClick={() => this.props.contentOverlay('whoisPage', false)}
              onCloseButtonClick={() => this.props.contentOverlay('whoisPage', false)}
              title={I18n.t('whoiscontent.titleSlide')}
              content={
                <Layout cols={12}>
                  <Cell span={12}>
                    <Layout cols={12}>
                      <Cell span={4}>
                        <Box height="18px" marginTop={2} marginLeft={-1}>
                          <Badge size="small" skin="standard">
                            ToM
                          </Badge>
                        </Box>
                        <Card stretchVertically>
                          <Card.Content>
                            <Box marginBottom="12px">
                              <Image src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/tom.png?v=1671549104" width="226" transparent />
                            </Box>
                            <Box marginBottom="12px">
                              <Heading size="medium">{I18n.t('whoiscontent.tom_title')}</Heading>
                            </Box>
                            <Box marginBottom="12px">
                              <Text>{I18n.t('whoiscontent.tom_text')}</Text>
                            </Box >
                          </Card.Content>
                        </Card>
                      </Cell>
                      <Cell span={4}>
                        <Box height="18px" marginTop={2} marginLeft={-1}>
                          <Badge size="small" skin="standard">
                            Antoine
                          </Badge>
                        </Box>
                        <Card stretchVertically>
                          <Card.Content>
                            <Box marginBottom="12px">
                              <Image src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/antone.png?v=1671549104" width="226" transparent />
                            </Box>
                            <Box marginBottom="12px">
                              <Heading size="medium">{I18n.t('whoiscontent.antone_title')}</Heading>
                            </Box>
                            <Box marginBottom="12px">
                              <Text>{I18n.t('whoiscontent.antone_text')}</Text>
                            </Box >
                          </Card.Content>
                        </Card>
                      </Cell>
                      <Cell span={4}>
                        <Box height="18px" marginTop={2} marginLeft={-1}>
                          <Badge size="small" skin="standard">
                            JoJo
                          </Badge>
                        </Box>
                        <Card stretchVertically>
                          <Card.Content>
                            <Box marginBottom="12px">
                              <Image src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/jojo.png?v=1671549104" width="226" transparent />
                            </Box>
                            <Box marginBottom="12px">
                              <Heading size="medium">{I18n.t('whoiscontent.jojo_title')}</Heading>
                            </Box>
                            <Box marginBottom="12px">
                              <Text>{I18n.t('whoiscontent.jojo_text')}</Text>
                            </Box >
                          </Card.Content>
                        </Card>
                      </Cell>
                    </Layout>
                  </Cell>
                </Layout>
              }
            />
          </Modal>
        )
    }
}

export default WhoIsContent