import React from "react";
import {
  Card,
  Layout,
  Cell,
} from '@wix/design-system';

import HelpBox from "../../../assets/images/helpBox.png";

class General extends React.Component {

  render() {
    return (
      <Layout cols={12}>
        <Cell span={12}>
          <Card>
            <Card.Content>
              <div width="100%" style={{textAlign:'center',marginTop:'-2%'}}>
                <img src={HelpBox} width="60%" />
              </div>
            </Card.Content>
          </Card>
        </Cell>
      </Layout>
    );
  }
}

export default General