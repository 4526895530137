import React from "react";

import {
  Page,
  Card,
  Tabs,
  Button,
  Badge,
  Box,
  Text,
  Image,
  Modal,
  MessageModalLayout
} from '@wix/design-system';

import { WixBookingSmall } from '@wix/wix-ui-icons-common';

import MyAccount from "./MyAccount";
import FrontOffice from "./FrontOffice";
import BackOffice from "./BackOffice";
import Orders from "./Orders";
import Intro from "./Intro";
import Carrier from "./Carrier";
import LogoColissimo from "../../../assets/images/Colissimo_Logo_H.png";

class ConfigForm extends React.Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  state = {
    selectTab: 0,
    confirmTab: 0,
    displayUpdateConfig: false,
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.child.current.handleSubmit();
  }

  handleSelectedTab = (value) => {
    if (this.props.updatedConfig) {
      /* We display modal */
      this.setState({ displayUpdateConfig: true });
      this.setState({confirmTab: value.id});
    } else {
      this.setState({selectTab: value.id});
    }
  }

  checkRedirect = (value) => {
    this.setState({ displayUpdateConfig: false });
    this.props.handleUpdateConfig(false);
    this.setState({selectTab: value});
  } 

  displayModalUpdate = () => {
      this.setState({ displayUpdateConfig: true });
  } 

  renderSwitch() {
    if (!Array.isArray(this.props.configData.local_offer)) {
      this.props.configData.local_offer = this.props.configData.local_offer == '' ? [] : Object.keys(JSON.parse(this.props.configData.local_offer));
    }

    switch(this.state.selectTab) {
      case 0:
        return (
          <MyAccount
            {...this.props}
            configData={this.props.configData}
            childChange={this.props.childChange}
            handleUpdateConfig={this.props.handleUpdateConfig}
            toggleToast={this.props.toggleToast}
            ref={this.child}
          />
        );
      case 1:
        return (
          <FrontOffice
            {...this.props}
            configData={this.props.configData}
            childChange={this.props.childChange}
            handleUpdateConfig={this.props.handleUpdateConfig}
            toggleToast={this.props.toggleToast}
            ref={this.child}
          />
        );
      case 2:
        return (
          <BackOffice
            {...this.props}
            configData={this.props.configData}
            childChange={this.props.childChange}
            handleUpdateConfig={this.props.handleUpdateConfig}
            toggleToast={this.props.toggleToast}
            ref={this.child}
          />
        );
      case 3:
        return (
          <Carrier
            {...this.props}
            ref={this.child}
          />
        );
      case 4:
        return (
          <Orders
            {...this.props}
            configData={this.props.configData}
            childChange={this.props.childChange}
            handleUpdateConfig={this.props.handleUpdateConfig}
            toggleToast={this.props.toggleToast}
            ref={this.child}
          />
        );
      case 5:
        return (
          <Intro
            {...this.props}
            ref={this.child}
          />
        );
      default:
        return (
          <MyAccount 
            {...this.props}
            configData={this.props.configData}
            childChange={this.props.childChange}
            handleUpdateConfig={this.props.handleUpdateConfig}
            toggleToast={this.props.toggleToast}
            ref={this.child}
          />
        );
    }
  }
  
  render() {
    const modalUpdateConfig = (
      <Modal
          isOpen={this.state.displayUpdateConfig}
          onRequestClose={() => this.setState({ displayUpdateConfig: false })}
          shouldCloseOnOverlayClick
          screen="desktop"
      >
          <MessageModalLayout
              onCloseButtonClick={() => this.setState({ displayUpdateConfig: false })}
              primaryButtonText={I18n.t('configs.modal.ok_button')}
              primaryButtonOnClick={() => this.checkRedirect(this.state.confirmTab)}
              secondaryButtonOnClick={() => this.setState({ displayUpdateConfig: false })}
              secondaryButtonText={I18n.t('configs.modal.cancel_button')}
              title={I18n.t('configs.modal.title')}
              content={
                  <Text>{I18n.t('configs.modal.message')}</Text>
              }
          />
      </Modal>
    );

    return (
      <div>
        <Page className="fullWidth" height="120vh">
            <Page.Header
              title={I18n.t('configs.my_account.title')}
              subtitle={<Image src={LogoColissimo} width="60" transparent />}
              actionsBar={<Box gap="SP2"><Button prefixIcon={<WixBookingSmall />} skin="inverted" as="a" href="https://calendly.com/antoine-du-studio/aide-a-la-configuration-de-l-app-colissimo" target="_blank">{I18n.t('configs.help_configuration')}</Button><Button onClick={(e) => this.handleSubmit(e)}>{I18n.t('configs.save_settings')}</Button></Box>}
            ></Page.Header>
            <Page.Content>
              <Card className="notroundedBottom">
                <Page.Sticky>
                  <Card className="notroundedBottom">
                    <Tabs
                      items={[
                        { id: 0, title: <Box verticalAlign="middle"><Badge skin="standard" className="rounded">1</Badge>&nbsp;<Text size="small">{I18n.t('menu.my_account')}</Text></Box> },
                        { id: 1, title: <Box verticalAlign="middle"><Badge skin="standard" className="rounded">2</Badge>&nbsp;<Text size="small">{I18n.t('menu.front_office')}</Text></Box> },
                        { id: 2, title: <Box verticalAlign="middle"><Badge skin="standard" className="rounded">3</Badge>&nbsp;<Text size="small">{I18n.t('menu.back_office')}</Text></Box> },
                        { id: 3, title: <Box verticalAlign="middle"><Badge skin="standard" className="rounded">4</Badge>&nbsp;<Text size="small">{I18n.t('menu.carrier_settings')}</Text></Box> },
                        { id: 4, title: <Box verticalAlign="middle"><Badge skin="standard" className="rounded">5</Badge>&nbsp;<Text size="small">{I18n.t('menu.import_orders')}</Text></Box> },
                        { id: 5, title: <Box verticalAlign="middle"><Badge skin="standard" className="rounded">6</Badge>&nbsp;<Text size="small">{I18n.t('menu.no_colissimo_account')}</Text></Box> },
                      ]}
                      activeId={this.state.selectTab}
                      type="uniformSide"
                      onClick={(value) => this.handleSelectedTab(value)}
                    />
                  </Card>
                </Page.Sticky>
                {this.renderSwitch()}
              </Card>
            </Page.Content>
            <Page.FixedFooter className="fullWidth">
              <Page.Footer className="fullWidth" divider>
                <Page.Footer.End className="fullWidth">
                  <Box gap="SP2">
                    <Button onClick={(e) => this.handleSubmit(e)}>{I18n.t('configs.save_settings')}</Button>
                  </Box>
                </Page.Footer.End>
              </Page.Footer>
            </Page.FixedFooter>
        </Page>
        {modalUpdateConfig}
      </div>
    );
  }
}

export default ConfigForm
