import React from 'react';
import { 
  Modal,
  CustomModalLayout,
  Box,
  Text,
  Image
} from '@wix/design-system';

import InstallationGuide from "../../../assets/images/guideinstallationwix.png";

class InstallGuide extends React.Component {

    render() {
        return(
          <Modal
            isOpen={this.props.installguide}
            onRequestClose={() => this.props.contentOverlay('installguide', false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText={I18n.t('onboarding.config_button')}
              secondaryButtonText={I18n.t('installguide.close_modal')}
              secondaryButtonOnClick={() => this.props.contentOverlay('installguide', false)}
              onCloseButtonClick={() => this.props.contentOverlay('installguide', false)}
              title={I18n.t('installguide.titleSlide')}
              content={
                <Box direction="vertical">
                  <Box marginBottom="36px">
                    <Image src={InstallationGuide} width="100%" transparent />
                  </Box>
                  <Box marginBottom="12px" width="100%">
                    <Text>
                      {I18n.t('installguide.text1')} <a href='https://www.colissimo.entreprise.laposte.fr/' target='_blank'>https://www.colissimo.entreprise.laposte.fr/</a> {I18n.t('installguide.text1-1')} <b>{I18n.t('installguide.text1-2')}</b><br/>
                    </Text>
                  </Box>
                  <Box marginBottom="12px" width="100%">
                    <Text>
                      {I18n.t('installguide.text2')}
                    </Text>
                  </Box>
                  <Box marginBottom="12px" width="100%">
                    <Text>
                      {I18n.t('installguide.text3')}
                    </Text>
                  </Box>
                  <Box marginBottom="12px" width="100%">
                    <Text listStyle="circle">
                      <ul>
                        <li>{I18n.t('installguide.text4')} <a href='https://www.loom.com/share/734323161cf74190b0aecca1340cf1ef?sid=e71475e4-9e58-448b-bac1-55c153d1b65e' target='_blank'>{I18n.t('installguide.text4-1')}</a></li>
                        <li>{I18n.t('installguide.text5')}</li>
                        <li>{I18n.t('installguide.text6')} <a href='https://www.loom.com/share/e1733077280f4b1282f8f6ee3dd870d8?sid=21c67cd2-1e9e-4137-9bef-b8b5a4127be0' target='_blank'>{I18n.t('installguide.text6-1')}</a></li>
                        <li>{I18n.t('installguide.text7')} <a href='https://www.loom.com/share/fc764885b62b45d89792ec66055bf8e3?sid=c844238c-a415-4657-a6cf-e25a021c1fe1' target='_blank'>{I18n.t('installguide.text7-1')}</a></li>
                      </ul>
                    </Text>
                  </Box>
                  <Box marginBottom="36px" width="100%">
                    <Text>{I18n.t('installguide.text8')}</Text>
                  </Box>
                </Box>
              }
            />
          </Modal>
        )
    }
}

export default InstallGuide