import React from "react";
import {
  Page,
  Card,
  Button,
  Text,
  Tabs,
  Image
} from '@wix/design-system';

import General from "./General";
import Faq from "./Faq";
import Contact from "./Contact";
import Video from "./Video";
import LogoColissimo from "../../../assets/images/Colissimo_Logo_H.png";

class Support extends React.Component {
  state = {
    selectTab: 0,
  }

  handleSelectedTab = (value) => {
    this.setState({selectTab: value.id});
  }

  renderSwitch() {
    switch(this.state.selectTab) {
      case 0:
        return (
          <General  />
        );
      case 1:
        return (
          <Faq />
        );
      case 2:
          return (
            <Video />
          )
      case 3:
        return (
          <Contact
            shop={this.props.shop}
            toggleToast={this.props.toggleToast}
            contentOverlay={this.props.contentOverlay}
          />
        );
      default:
        return (
          <General />
        );
    }
  }

  render() {
    return (
      <div>
        <Page className="fullWidth support" height="120vh">
          <Page.Header
            title={I18n.t('support.title')}
            subtitle={<Image src={LogoColissimo} width="60" transparent />}
          ></Page.Header>
          <Page.Content>
            <Card className="notroundedBottom">
              <Page.Sticky>
                <Card className="notroundedBottom">
                  <Tabs
                    items={[
                      { id: 0, title: <Text size="small">{I18n.t('support.slide1.title')}</Text> },
                      { id: 1, title: <Text size="small">{I18n.t('support.slide3.title')}</Text> },
                      { id: 2, title: <Text size="small">{I18n.t('support.slide5.title')}</Text> },
                      { id: 3, title: <Text size="small">{I18n.t('support.slide4.title')}</Text> },
                    ]}
                    activeId={this.state.selectTab}
                    type="uniformSide"
                    onClick={(value) => this.handleSelectedTab(value)}
                  />
                </Card>
              </Page.Sticky>
              {this.renderSwitch()}
            </Card>
          </Page.Content>
        </Page>
      </div>
    );
  }
}

export default Support
