import React from "react";
import {
  Card,
  Button,
  Modal,
  MarketingLayout,
  FormField,
  Input,
  Image,
  Layout,
  Cell,
  Heading,
  Loader,
  Box,
  TextButton,
  Dropdown,
  NumberInput,
  SectionHelper,
  Text,
  Radio,
  Checkbox,
  MultiSelect,
  Divider
} from '@wix/design-system';

import { DownloadImport, Add } from '@wix/wix-ui-icons-common';

import dpd from "../../../assets/images/dpd.png";
import austria from "../../../assets/images/austria.png";
import italy from "../../../assets/images/italy.png";
import germany from "../../../assets/images/germany.png";
import luxembourg from "../../../assets/images/luxembourg.png";

import { passCsrfToken } from '../../util/helpers'
import axios from 'axios'

class BackOffice extends React.Component {

  constructor(props) {
    super(props);

    var local_offer = [];

    if (props.configData.local_offer != "") {
      if (props.configData.local_offer.includes('DE')) {
       local_offer.push({ id: 'DE', label: (<div>{I18n.t('configs.back_end.germany')} <img src={germany} width="70px" /></div>) });
      } 

      if (props.configData.local_offer.includes('AU')) {
        local_offer.push({ id: 'AU', label: (<div>{I18n.t('configs.back_end.austria')} <img src={austria} width="70px" /></div>) });
      } 
      
      if (props.configData.local_offer.includes('IT')) {
        local_offer.push({ id: 'IT', label: (<div>{I18n.t('configs.back_end.italy')} <img src={italy} height="15px" /></div>) });
      } 
      
      if (props.configData.local_offer.includes('LU')) {
        local_offer.push({ id: 'LU', label: (<div>{I18n.t('configs.back_end.luxembourg')} <img src={luxembourg} height="15px" /></div>) });
      }

      this.state = {
        config: props.configData,
        selectedLocalOffer: local_offer,
        loadingSaveProcess: false
      };
    } else {
      this.state = {
        config: props.configData,
        selectedLocalOffer: [],
        loadingSaveProcess: false
      };
    }
  }

  componentDidMount = () => {
    passCsrfToken(document, axios);
  }

  /* Pour le changement de chaque champ de formulaire */
  handleChange = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, [e.target.name]: e.target.value },
    })
  };

  /* Pour le changement de chaque champ de formulaire */
  handleChangeRadio = (field, value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, [field]: value },
    })
  };
  /* Pour le changement du nombre de jour de préparation */
  handleChangeDays = (value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, order_preparation_time: value },
    })
  };

  /* Pourle changement du poids par défaut */
  handleChangeDefaultWeight = (value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, default_weight: value },
    })
  };

  /* Pourle changement de la tare */
  handleChangeTare = (value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, packing_tare: value },
    })
  };

  /* Pour le changement du HS CODE */
  handleChangeHsCode = (value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, default_hs_code: value },
    })
  };

  /* Pour le changement Insurance amount */
  handleChangeInsuranceAmount = (value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, amount_to_insurance: value },
    })
  };

  /* Pour le changement Insurance amount */
  handleChangeReturnLifeTime = (value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, return_lifetime: value },
    })
  };

  /* Gestion des mises à jour du format d'impression */
  handleSelectLabelFormat = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, ['label_format']: e.id },
    })
  }

  /* gestion du protocol d'impression */
  handleSelectProtocolPrinter = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, ['usb_protocole']: e.id },
    })
  }

  handleChangeFont = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, ['widget_font']: e.id },
    })
    $('#widget_font_text h1').css("font-family", e.id);
  }

  handleChangeColor = (field, value) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({
      config: { ...this.state.config, [field]: value },
    })
  }

  /* Modification des local Offer */
  handleOnSelectLocalOffer = (e) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({selectedLocalOffer: [...this.state.selectedLocalOffer, {id: e.id, label: e.value }]});
    this.setState({config: { ...this.state.config, ['local_offer']: [...this.state.config.local_offer, e.id ]}});
  }

  handleOnRemoveLocalOffer = (offerId) => {
    this.props.handleUpdateConfig(true);
    this.setState({showNotification: false});
    this.setState({selectedLocalOffer: this.state.selectedLocalOffer.filter((currLocalOffer) => currLocalOffer.id !== offerId)});
    this.setState({config: { ...this.state.config, ['local_offer']: this.state.config.local_offer.filter((currLocalOffer) => currLocalOffer !== offerId)}});
    console.log(this.state.selectedLocalOffer);
  }

  /* Submit FORM */
  handleSubmit = () => {
    this.setState({showNotification: false});
    this.setState({ loadingSaveProcess: true });

    axios({
    url: this.state.config.id ? '/configs/' + this.state.config.id : '/configs',
    method: this.state.config.id ? 'patch' : 'post',
    data: {
      config: this.state.config,
      instance_id: this.props.shop.instance_id
    },
    }).then(result => {
      if (result.data.success == true) {
        var data = result.data;
        data.config.local_offer = Object.keys(JSON.parse(data.config.local_offer));
        this.setState({ config: data.config });
        this.props.childChange(data.config);
        this.setState({loadingSaveProcess: false});
        //We update general props
        this.props.handleUpdateConfig(false);
        this.props.toggleToast(I18n.t('configs.save_success'), false);
      } else {
        this.setState({loadingSaveProcess: false});
        this.props.toggleToast(I18n.t('configs.save_error'), true);
      }
    }).catch(error => {
      console.log(error);
      this.setState({loadingSaveProcess: false});
      this.props.toggleToast(I18n.t('configs.save_error'), true);
    });
  };

  downloadFile = () => {
    axios({
      method: 'post',
      url: '/download-fmt-file',
    }).then(result => {
        if (result.data.success == true) {
            const FileDownload = require('js-file-download');
            FileDownload(result.data.content_fmt, 'shopify.fmt');
        }
    }).catch(error => {
        console.log(error);
    });
  }

  render() {
    const {
      config,
      loadingSaveProcess,
      selectedLocalOffer
    } = this.state;

    const icon_offer_germany = (<div>{I18n.t('configs.back_end.germany')} <img src={germany} width="70px" /></div>);
    const icon_offer_austria = (<div>{I18n.t('configs.back_end.austria')} <img src={austria} width="70px" /></div>);
    const icon_offer_italy = (<div>{I18n.t('configs.back_end.italy')} <img src={italy} height="15px" /></div>);
    const icon_offer_luxembourg = (<div>{I18n.t('configs.back_end.luxembourg')} <img src={luxembourg} height="15px" /></div>);
    
    console.log(config);
    console.log(selectedLocalOffer);

    const descriptionSection = (
        <Card>
          <MarketingLayout
            title={I18n.t('configs.back_end.title_parameters')}
            description={`${I18n.t('configs.view_video')} ${I18n.t('configs.step3_video')}`}
            actions={<TextButton as="a" href="https://www.loom.com/share/e1733077280f4b1282f8f6ee3dd870d8?sid=9515cdea-ee6d-475b-945a-43c9e7890efd" target="_blank">{I18n.t('configs.view_video_button')}</TextButton>}
            size="tiny"
            image={
              <Box width="100%">
                <Image src="https://cdn.shopify.com/s/files/1/0304/0172/4555/files/video_ecran_113.svg?v=1652256024" width="70%" height="70%" transparent />
              </Box>
            }
          />
        </Card>
    );

      
    return (
      <div>
        <Modal
          screen="desktop"
          isOpen={loadingSaveProcess}
        >
          <Loader size="large" />
        </Modal>
        {/* URL WIDGET */}
        <Layout cols={12}>
          <Cell span={4}>
            {descriptionSection}
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <FormField
                    label={I18n.t('configs.back_end.label_preparation_time')}
                    infoContent={I18n.t('configs.back_end.help_preparation_time')}
                  >
                    <NumberInput prefix={<Input.Affix>{I18n.t('configs.back_end.days')}</Input.Affix>} value={`${config.order_preparation_time}`} onChange={(value) => this.handleChangeDays(value)} />
                  </FormField>
                  <br/>
                  <Heading size="large">{I18n.t('configs.back_end.print_label')}</Heading>
                  <br/>
                  <FormField
                  label={I18n.t('configs.back_end.label_generate_label_shopify')}
                  >
                      <Radio
                      alignItems="top"
                      checked={config.generate_label_shopify === true}
                      onChange={() => this.handleChangeRadio('generate_label_shopify', true)}
                      label={
                        <Cell>
                          <Text size="small" secondary>
                            {I18n.t('configs.back_end.generate_label_shopify')}
                          </Text>
                          <br/>
                          {config.generate_label_shopify === true && 
                            <Box marginTop="6px" width="100%">
                              <FormField
                                  label={I18n.t('configs.back_end.label_label_format')}
                              >
                                <Dropdown
                                  name="label_format"
                                  options={[
                                    {value: "PDF A4 300dpi", id: "PDF_A4_300dpi"},
                                    {value: "PDF 10x15 300dpi", id: "PDF_10x15_300dpi"},
                                    {value: "ZPL 10x15 203dpi", id: "ZPL_10x15_203dpi"},
                                    {value: "ZPL 10x15 300dpi", id: "ZPL_10x15_300dpi"},
                                    {value: "DPL 10x15 203dpi", id: "DPL_10x15_203dpi"},
                                    {value: "DPL 10x15 300dpi", id: "DPL_10x15_300dpi"},
                                  ]}
                                  onSelect={this.handleSelectLabelFormat}
                                  selectedId={config.label_format !== null && config.label_format ? config.label_format : 'PDF_A4_300dpi'}
                                />
                              </FormField>
                            </Box>
                          }
                        </Cell>
                      }
                      />
                      <br/>
                      <Radio
                      alignItems="top"
                      checked={config.generate_label_shopify === false}
                      onChange={() => this.handleChangeRadio('generate_label_shopify', false)}
                      label={
                        <Cell>
                          <Text size="small" secondary>
                            {I18n.t('configs.back_end.generate_label_coliship')}
                          </Text>
                          <br/>
                          {config.generate_label_shopify === false && 
                            <Box marginTop="6px" width="100%">
                              <SectionHelper fullWidth appearance="standard" title={I18n.t('configs.back_end.configuration_coliship')}>
                                <Text size="small">
                                  {I18n.t('configs.back_end.coliship_message')}
                                  <br/>
                                </Text>
                                <br/>
                                <Button onClick={(e) => this.downloadFile()} size="tiny" prefixIcon={<DownloadImport />}>
                                  {I18n.t('configs.back_end.download_fmt')}
                                </Button>
                              </SectionHelper>
                            </Box>
                          }
                        </Cell>
                      }
                      />
                  </FormField>
                  <br/>
                  <FormField>
                      <Checkbox
                        checked={config.notify_customer === true}
                        onChange={() => this.handleChangeRadio('notify_customer', !config.notify_customer)}
                      >
                        {I18n.t('configs.back_end.notify_customer')}
                      </Checkbox>
                      <br/>
                      <Text size="tiny">{I18n.t('configs.back_end.help_notify_customer')}</Text>
                  </FormField>
                  <br/>
                  <Heading size="large">{I18n.t('configs.back_end.thermal_printer_title')}</Heading>
                  <br/>
                  <FormField
                  label={I18n.t('configs.back_end.thermal_printer_label')}
                  >
                    <Radio
                    alignItems="top"
                    checked={config.use_thermal_printer === true}
                    onChange={() => this.handleChangeRadio('use_thermal_printer', true)}
                    label={
                        <Cell>
                          <Text size="small" secondary>
                            {I18n.t('configs.back_end.use_thermal_printer_yes')}
                          </Text>
                          <br/>
                          {config.use_thermal_printer === true && 
                            <Cell>
                              <Box marginTop="6px" width="100%">
                                  <SectionHelper fullWidth appearance="standard" title={I18n.t('configs.back_end.configuration_thermal_printer')}>
                                      <Text listStyle="circle" size="small">
                                          <ul>
                                              <li>{I18n.t('configs.back_end.thermal_printer_format_step1')}</li>
                                              <li>{I18n.t('configs.back_end.thermal_printer_format_step2')}<br/><a href='https://happycolissimo.happycommerce.io/kit-imprimante-thermique.zip' target='_blank'>{I18n.t('configs.back_end.download_kit')}</a></li>
                                          </ul>
                                      </Text>
                                  </SectionHelper>
                              </Box>
                              <Box marginTop="6px" width="100%">
                                <FormField
                                    label={I18n.t('configs.back_end.thermal_printer_port_label')}
                                >
                                  <Radio
                                    alignItems="top"
                                    checked={config.thermal_printer_protocol === 'USB'}
                                    onChange={() => this.handleChangeRadio('thermal_printer_protocol', 'USB')}
                                    label={
                                      <Cell>
                                        <Text size="small" secondary>
                                          {I18n.t('configs.back_end.thermal_printer_usb')}
                                        </Text>
                                        <br/>
                                        {config.thermal_printer_protocol === 'USB' &&
                                          <Box marginTop="6px" width="100%">
                                            <FormField
                                                label={I18n.t('configs.back_end.thermal_printer_protocole_label')}
                                            >
                                              <Dropdown
                                                name="usb_protocole"
                                                options={[
                                                  {value: "DATAMAX", id: "DATAMAX"},
                                                  {value: "INTERMEC", id: "INTERMEC"},
                                                  {value: "ZEBRA", id: "ZEBRA"},
                                                ]}
                                                onSelect={this.handleSelectProtocolPrinter}
                                                selectedId={config.usb_protocole !== null && config.usb_protocole ? config.usb_protocole : 'DATAMAX'}
                                              />
                                            </FormField>
                                          </Box>
                                        }
                                      </Cell>
                                    }
                                  />
                                  <br/>
                                  <Radio
                                    alignItems="top"
                                    checked={config.thermal_printer_protocol === 'ETHERNET'}
                                    onChange={() => this.handleChangeRadio('thermal_printer_protocol', 'ETHERNET')}
                                    label={
                                      <Cell>
                                        <Text size="small" secondary>
                                          {I18n.t('configs.back_end.thermal_printer_ethernet')}
                                        </Text>
                                        <br/>
                                        {config.thermal_printer_protocol === 'ETHERNET' &&
                                          <Box marginTop="6px" width="100%">
                                            <FormField
                                              label={I18n.t('configs.back_end.thermal_printer_ip_label')}
                                            >
                                              <Input
                                                type="text"
                                                name="printer_ip_address"
                                                value={config.printer_ip_address}
                                                onChange={this.handleChange}
                                                placeholder="127.0.0.1"
                                              />
                                            </FormField>
                                          </Box>
                                        }
                                      </Cell>
                                    }
                                  />
                                </FormField>
                              </Box>
                              <br/>
                              <Box marginTop="6px" width="100%">
                                <FormField>
                                  <Checkbox
                                    checked={config.generate_and_print === true}
                                    onChange={() => this.handleChangeRadio('generate_and_print', !config.generate_and_print)}
                                  >
                                    {I18n.t('configs.back_end.generate_and_print')}
                                  </Checkbox>
                                  <br/>
                                  <Text size="tiny">{I18n.t('configs.back_end.help_generate_and_print')}</Text>
                                </FormField>
                              </Box>
                            </Cell>
                          }
                        </Cell>
                      }
                    />
                    <br/>
                    <Radio
                    alignItems="top"
                    checked={config.use_thermal_printer === false}
                    onChange={() => this.handleChangeRadio('use_thermal_printer', false)}
                    label={
                      <Cell>
                        <Text size="small" secondary>
                          {I18n.t('configs.back_end.use_thermal_printer_no')}
                        </Text>
                      </Cell>
                    }
                    />
                  </FormField>
                  <br/>
                  <Heading size="large">{I18n.t('configs.back_end.weight_title_options')}</Heading>
                  <br/>
                  <FormField
                    label={I18n.t('configs.back_end.label_default_weight')}
                    infoContent={I18n.t('configs.back_end.help_default_weight')}
                  >
                    <NumberInput placeholder={I18n.t('configs.back_end.placeholder_default_weight')} value={`${config.default_weight !== null && config.default_weight ? config.default_weight : 1000}`} onChange={(value) => this.handleChangeDefaultWeight(value)} />
                    <Text size="tiny">{I18n.t('configs.back_end.help_default_weight')}</Text>
                  </FormField>
                  <br/>
                  <FormField
                    label={I18n.t('configs.back_end.label_packing_tare')}
                    infoContent={I18n.t('configs.back_end.help_packing_tare')}
                  >
                    <NumberInput placeholder={I18n.t('configs.back_end.placeholder_packing_tare')} value={`${config.packing_tare !== null && config.packing_tare ? config.packing_tare : 0}`} onChange={(value) => this.handleChangeTare(value)} />
                    <Text size="tiny">{I18n.t('configs.back_end.help_packing_tare')}</Text>
                  </FormField>
                  <br/>
                  <Heading size="large">{I18n.t('configs.back_end.title_dom_tom')}</Heading>
                  <br/>
                  <FormField>
                    <Checkbox
                        checked={config.colissimo_eco === true}
                        onChange={() => this.handleChangeRadio('colissimo_eco', !config.colissimo_eco)}
                    >
                      {I18n.t('configs.back_end.colissimo_eco')}
                    </Checkbox>
                    <br/>
                    <Text size="tiny">{I18n.t('configs.back_end.help_colissimo_eco')}</Text>
                  </FormField>
                  <br/>
                  <Heading size="large">{I18n.t('configs.back_end.send_worldwide')}</Heading>
                  <br/>
                  <Box marginTop="6px" width="100%">
                    <SectionHelper fullWidth appearance="warning" title={I18n.t('configs.back_end.warning_send_worldwide')}>
                      <Text listStyle="circle" size="small">
                        <ul>
                          <li>{I18n.t('configs.back_end.warning_send_worldwide_list1')}</li>
                          <li>{I18n.t('configs.back_end.warning_send_worldwide_list2')}</li>
                        </ul>
                      </Text>
                    </SectionHelper>
                  </Box>
                  <br/>
                  <FormField
                    label={I18n.t('configs.back_end.label_default_hs_code')}
                    infoContent={I18n.t('configs.back_end.help_default_hs_code')}
                  >
                    <NumberInput placeholder={I18n.t('configs.back_end.placeholder_default_hs_code')} value={`${config.default_hs_code!== null && config.default_hs_code ? config.default_hs_code : ''}`} onChange={(value) => this.handleChangeHsCode(value)} />
                    <Text size="tiny">{I18n.t('configs.back_end.help_default_hs_code')}</Text>
                  </FormField>
                  <br/>
                  <FormField
                    label={I18n.t('configs.back_end.label_eori')}
                  >
                    <Input
                      type="text"
                      name="eori"
                      value={config.eori}
                      onChange={this.handleChange}
                      placeholder={I18n.t('configs.back_end.label_eori_placeholder')}
                    />
                    <Text size="tiny">{I18n.t('configs.back_end.help_eori')}</Text>
                  </FormField>
                  <br/>
                  <Box marginTop="6px" width="100%">
                    <SectionHelper fullWidth appearance="danger" title={I18n.t('configs.back_end.change_international_offer')}> 
                      <Text size="small">
                        {I18n.t('configs.back_end.text_international_offer')}
                        </Text>
                      <Text listStyle="circle" size="small">
                        {I18n.t('configs.back_end.text_international_offer2')}
                        <ul>
                          <li>
                          {I18n.t('configs.back_end.germany')}
                          </li>
                          <li>
                          {I18n.t('configs.back_end.austria')}
                          </li>
                          <li>
                          {I18n.t('configs.back_end.italy')}
                          </li>
                          <li>
                          {I18n.t('configs.back_end.luxembourg')}
                          </li>
                        </ul>
                      </Text>
                      <Text size="small">
                        <b>{I18n.t('configs.back_end.text_international_offer3')} <img src={dpd} width="40px" /></b>
                      </Text>
                      <br/>
                      <Text size="small">
                        {I18n.t('configs.back_end.text_international_offer4')}
                      </Text>
                    </SectionHelper>
                  </Box>
                  <br/>
                  <FormField
                    label={I18n.t('configs.back_end.change_offer_choicelist_title')}
                  >
                    <MultiSelect
                      tags={selectedLocalOffer}
                      options={[
                        { id: 'DE', value: icon_offer_germany },
                        { id: 'AU', value: icon_offer_austria },
                        { id: 'IT', value: icon_offer_italy },
                        { id: 'LU', value: icon_offer_luxembourg },
                      ]}
                      customSuffix={
                        <Box>
                          <TextButton prefixIcon={<Add />}>{I18n.t('configs.back_end.choose_postal_network')}</TextButton>
                        </Box>
                      }
                      onSelect={this.handleOnSelectLocalOffer}
                      onRemoveTag={this.handleOnRemoveLocalOffer}
                    />
                  </FormField>
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
        <Divider />
        {/* FOMRULAIRE D'ADRESSE */}
        <Layout cols={12}>
          <Cell span={4}>
          <Card>
            <MarketingLayout
              title={I18n.t('configs.back_end.title_advanced_parameters')}
              description={I18n.t('configs.back_end.help_advanced_parameters')}
              size="tiny"
            />
          </Card>
          </Cell>
          <Cell span={8}>
            <Card>
              <Card.Content>
                <Cell>
                  <Heading size="large">{I18n.t('configs.back_end.title_synchronize')}</Heading>
                  <br/>
                  <FormField>
                    <Checkbox
                        checked={config.sychronize_no_colissimo_order === true}
                        onChange={() => this.handleChangeRadio('sychronize_no_colissimo_order', !config.sychronize_no_colissimo_order)}
                    >
                      {I18n.t('configs.back_end.synchronize_no_colissimo_order_label')}
                    </Checkbox>
                    <br/>
                    <Text size="tiny">{I18n.t('configs.back_end.help_synchronize_no_colissimo_order_label')}</Text>
                  </FormField>
                  <br/>
                  <Heading size="large">{I18n.t('configs.back_end.title_insure')}</Heading>
                  <br/>
                  <FormField>
                    <Checkbox
                        checked={config.insure_shipments === true}
                        onChange={() => this.handleChangeRadio('insure_shipments', !config.insure_shipments)}
                    >
                      {I18n.t('configs.back_end.insure_shipments')}
                    </Checkbox>
                    <br/>
                    <Text size="tiny">{I18n.t('configs.back_end.help_insure_shipments')}</Text>
                  </FormField>
                  <br/>
                  <FormField
                    label={I18n.t('configs.back_end.label_amount_to_insurance')}
                    infoContent={I18n.t('configs.back_end.help_amount_to_insurance')}
                  >
                    <NumberInput value={`${config.amount_to_insurance!== null && config.amount_to_insurance ? config.amount_to_insurance : ''}`} onChange={(value) => this.handleChangeInsuranceAmount(value)} />
                    <Text size="tiny">{I18n.t('configs.back_end.help_amount_to_insurance')}</Text>
                  </FormField>
                  <br/>
                  <Heading size="large">{I18n.t('configs.back_end.title_return')}</Heading>
                  <br/>
                  <FormField
                  label={I18n.t('configs.back_end.label_enable_return')}
                  >
                    <Radio
                      alignItems="top"
                      checked={config.enable_return === true}
                      onChange={() => this.handleChangeRadio('enable_return', true)}
                      label={
                        <Cell>
                          <Text size="small" secondary>
                            {I18n.t('configs.back_end.label_yes')}
                          </Text>
                          <br/>
                          {config.enable_return === true &&
                            <Cell>
                              <Box marginTop="SP2" width="100%" direction="vertical">
                                <Box>
                                <Checkbox
                                    checked={config.auto_print_return_label === true}
                                    onChange={() => this.handleChangeRadio('auto_print_return_label', !config.auto_print_return_label)}
                                >
                                  {I18n.t('configs.back_end.auto_print_return_label')}
                                </Checkbox>
                                </Box>
                                <Box>
                                  <Text size="tiny">{I18n.t('configs.back_end.help_auto_print_return_label')}</Text>
                                </Box>
                              </Box>
                              <Box marginTop="SP2" width="100%" direction="vertical">
                                <Checkbox
                                    checked={config.display_return_label_customer === true}
                                    onChange={() => this.handleChangeRadio('display_return_label_customer', !config.display_return_label_customer)}
                                >
                                  {I18n.t('configs.back_end.display_return_label_customer')}
                                </Checkbox>
                              </Box>
                              <Box marginTop="SP2" width="100%" direction="vertical">
                                <Box>
                                  <Checkbox
                                      checked={config.allow_customer_generate_return === true}
                                      onChange={() => this.handleChangeRadio('allow_customer_generate_return', !config.allow_customer_generate_return)}
                                  >
                                    {I18n.t('configs.back_end.allow_customer_generate_return_label')}
                                  </Checkbox>
                                </Box>
                                <Box>
                                  <Text size="tiny">{I18n.t('configs.back_end.help_allow_customer_generate_return_label')}</Text>
                                </Box>
                              </Box>
                              <Box marginTop="SP2" width="100%" direction="vertical">
                                <Box>
                                  <Checkbox
                                      checked={config.generate_return_only_for_france === true}
                                      onChange={() => this.handleChangeRadio('generate_return_only_for_france', !config.generate_return_only_for_france)}
                                  >
                                    {I18n.t('configs.back_end.generate_return_only_for_france_label')}
                                  </Checkbox>
                                </Box>
                                <Box>
                                  <Text size="tiny">{I18n.t('configs.back_end.help_generate_return_only_for_france_label')}</Text>
                                </Box>
                              </Box>
                              <Box marginTop="SP2" width="100%" direction="vertical">
                                <FormField
                                  label={I18n.t('configs.back_end.label_return_lifetime')}
                                  infoContent={I18n.t('configs.back_end.help_return_lifetime')}
                                >
                                  <NumberInput value={`${config.return_lifetime!== null && config.return_lifetime ? config.return_lifetime : 20}`} onChange={(value) => this.handleChangeReturnLifeTime(value)} />
                                  <Text size="tiny">{I18n.t('configs.back_end.help_return_lifetime')}</Text>
                                </FormField>
                              </Box>
                              <Box marginTop="SP2" width="100%" direction="vertical">
                                <Box>
                                  <Checkbox
                                      checked={config.send_return_notification === true}
                                      onChange={() => this.handleChangeRadio('send_return_notification', !config.send_return_notification)}
                                  >
                                    {I18n.t('configs.back_end.send_return_notification_label')}
                                  </Checkbox>
                                </Box>
                                <Box>
                                  <Text size="tiny">{I18n.t('configs.back_end.help_send_return_notification_label')}</Text>
                                </Box>
                              </Box>
                              <Box marginTop="SP2" width="100%" direction="vertical">
                                <Checkbox
                                    checked={config.enable_mailbox_return === true}
                                    onChange={() => this.handleChangeRadio('enable_mailbox_return', !config.enable_mailbox_return)}
                                >
                                  {I18n.t('configs.back_end.enable_mailbox_return')}
                                </Checkbox>
                                <br/>
                              </Box>
                            </Cell>
                          }
                        </Cell>
                      }
                    />
                    <br/>
                    <Radio
                      alignItems="top"
                      checked={config.enable_return === false}
                      onChange={() => this.handleChangeRadio('enable_return', false)}
                      label={
                        <Cell>
                          <Text size="small" secondary>
                            {I18n.t('configs.back_end.label_no')}
                          </Text>
                        </Cell>
                      }
                      />
                  </FormField>
                </Cell>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
      </div>
    );
  }
}

export default BackOffice