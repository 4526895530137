import React from 'react';
import { 
  Modal,
  CustomModalLayout,
  Cell,
  Layout,
  Card,
  Box,
  Text,
  Button
} from '@wix/design-system';

class Upgrade extends React.Component {

    render() {
      console.log(this.props.upgrade_url);
        return(
          <Modal
            isOpen={this.props.upgrade}
            screen="desktop"
          >
            <CustomModalLayout
              theme="premium"
              primaryButtonText={<Button as="a" skin="premium" size="small" href={this.props.upgrade_url} target="_blank">{I18n.t('upgrade.upgrade_app_button')}</Button>}
              title={I18n.t('upgrade.title')}
              content={
                <Layout cols={12}>
                  <Cell span={12}>
                    <Layout cols={12}>
                      <Cell span={12}>
                        <Card stretchVertically>
                          <Card.Content>
                            <Box marginBottom="12px">
                              <Text>{I18n.t('upgrade.text')}</Text>
                            </Box >
                          </Card.Content>
                        </Card>
                      </Cell>
                    </Layout>
                  </Cell>
                </Layout>
              }
            />
          </Modal>
        )
    }
}

export default Upgrade